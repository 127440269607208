import { ModuleBase, Media, Stack, Box, Link, Text, HeadingTag } from '@/components';
import classNames from 'classnames';

const RichTextAndOneMediaModule = ({ data }) => {
  const { headingTitle, description, preHeading, subHeading, primaryCTA, secondaryCTA } = data || {};
  return (
    <ModuleBase data={data}>
      <Stack
        className={classNames(
          'main-data container flex lg:justify-between',
          data.mediaAlignment === 'Left' ? 'flex-col-reverse lg:flex-row-reverse' : 'flex-col lg:flex-row',
        )}
      >
        <div className="text-wrapper color-from-bg md:w-[73%] lg:my-auto lg:w-[48%]">
          <div className="text-wrapper">
            <div className="title-wrapper" role="contentinfo" aria-label="contentinfo">
              {preHeading && (
                <Text className="text-subheading my-4 font-montserrat text-base font-semibold uppercase text-black">
                  {preHeading}
                </Text>
              )}
              {headingTitle && (
                <HeadingTag
                  animate
                  data={headingTitle}
                  className="title color-from-bg mt-7 font-montserrat text-heading2 leading-heading2"
                />
              )}
              {subHeading && (
                <Text className="text-subheading my-4 font-montserrat text-base font-semibold text-black">
                  {subHeading}
                </Text>
              )}
            </div>

            {(description || primaryCTA || secondaryCTA) && (
              <div className="color-from-bg mb-4 mt-5 text-paragraph">
                <Text dangerouslySetInnerHTML={{ __html: description }} />

                {(primaryCTA || secondaryCTA) && (
                  <Box className="mt-10 flex justify-start gap-4 sm:mt-16 lg:mt-10">
                    <Link className="btn primary self-end" link={primaryCTA} />

                    <Link className="btn secondary self-end" link={secondaryCTA} />
                  </Box>
                )}
              </div>
            )}
          </div>
        </div>

        {data.mediaOrientation === 'Portrait' || data.mediaOrientation === '' ? (
          <Box
            className={classNames(
              'mx-auto my-auto aspect-square h-fit md:mx-0 md:w-[75%] lg:w-[49%]',
              'relative max-w-[327px] md:max-w-[451px] lg:max-w-[496px]',
              data.mediaAlignment === 'Left'
                ? 'mb-14 md:mb-10 md:self-start lg:mb-auto'
                : 'mt-14 md:mt-10 md:self-end lg:mt-auto',
            )}
          >
            <Media
              media={data.media}
              widths={{ xs: 490, sm: 490, md: 451, lg: 496, xl: 496, xxl: 496 }}
              heights={{ xs: 652, sm: 652, md: 597, lg: 660, xl: 660, xxl: 660 }}
              className="overlay"
            />
          </Box>
        ) : (
          <Box
            className={classNames(
              'relative my-auto aspect-8/5 h-fit w-full max-w-[688px] md:mx-auto lg:w-[49%] lg:max-w-[600px]',
              data.mediaAlignment === 'Left' ? 'mb-10 lg:mb-auto lg:ml-0' : 'mt-14 md:mt-10 lg:mr-0 lg:mt-auto',
            )}
          >
            <Media
              media={data.media}
              widths={{ xs: 327, sm: 327, md: 688, lg: 600, xl: 600, xxl: 600 }}
              className="overlay"
            />
          </Box>
        )}
      </Stack>
    </ModuleBase>
  );
};

export default RichTextAndOneMediaModule;
