import React from 'react';
import PopoverMenu from '../general/PopoverMenu';
import classNames from 'classnames';

const DropdownFilter = ({ filter, value, onChange, ...props }) => {
  const options = filter.filters?.map((option) => ({ label: option.name, value: option.filterGuid }));

  // const getFirstOptionLabel = (value) => {
  //   return value
  //     .map((item) => {
  //       if (options.find((option) => option.value === item)) {
  //         return options.find((option) => option.value === item).label;
  //       }
  //     })
  //     .filter((item) => item)[0];
  // };

  const _onChange = (e, _value) => {
    let newValue = value ? [...value] : [];

    if (e.target.checked && value.indexOf(_value) < 0) {
      newValue.push(_value);
    } else if (value.indexOf(_value) >= 0) {
      newValue.splice(value.indexOf(_value), 1);
    }

    onChange({ [filter.filterKey]: newValue });
  };

  const countByValue = options.reduce((count, option) => {
    if (value.includes(option.value)) {
      count++;
    }
    return count;
  }, 0);

  let suffix = countByValue > 1 ? `(${countByValue}) ` : '';
  let title = value.length > 0 && countByValue > 0 ? `${filter.filterLabel} ${suffix}` : filter.filterLabel;

  return (
    <PopoverMenu title={title} titleClass={'whitespace-nowrap overflow-hidden'} bgClass={'bg-transparent'} {...props}>
      <ul className="text-gray-700 dark:text-gray-200 w-full py-3 text-sm">
        {options &&
          options.map((option) => (
            <li key={option.value} className="hover:bg-bone/40">
              <label
                className={classNames(
                  'checkbox flex justify-between px-4 py-3 text-black',
                  value.includes(option.value) ? 'bg-sandstone' : '',
                )}
              >
                <div className="mr-2 block cursor-pointer capitalize">{option.label}</div>
                <input
                  type="checkbox"
                  value={option.id}
                  defaultChecked={value.includes(option.value)}
                  onChange={(e) => _onChange(e, option.value)}
                />
              </label>
            </li>
          ))}
      </ul>
    </PopoverMenu>
  );
};
export default DropdownFilter;
