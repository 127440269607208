import React, { useEffect, useState } from 'react';
import { ModuleBase, Stack, Text, HeadingTag, Box } from '@/components';
import { isColorDark } from '@/utils';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { motion, AnimatePresence } from 'framer-motion';
import MediaAssetsLibraryLoggedInModule from './MediaAssetsLibraryLoggedInModule';

const MediaAssetsLibraryModule = ({ data }) => {
  const isDark = isColorDark(data.backgroundColour);
  const { t } = useTranslation('common');
  const [passwordInput, setPasswordInput] = useState('');

  const router = useRouter();

  const setCookie = (name, value) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 10000 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()}`;
  };

  const handleSubmit = () => {
    setCookie('membership', passwordInput);
    setTimeout(() => {
      router.reload();
    }, 300);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && passwordInput !== '') {
      event.preventDefault();
      setCookie('membership', passwordInput);
      setTimeout(() => {
        router.reload();
      }, 300);
    }
  };

  useEffect(() => {
    const deleteCookie = (name) => {
      document.cookie = `${name}=; Max-Age=-99999999;`;
    };

    if (data.passwordNotMatch) {
      deleteCookie('membership');
    }
  }, [data.passwordNotMatch]);

  const specialPaddingClass =
    data.assets.length > 0 ? 'pb-8 pt-10 md:pb-[35px] lg:pt-[88px] lg:pb-20' : 'py-10 md:pb-14 lg:pt-[88px] lg:pb-28';

  const titleSpecialMarginClass =
    data.assets.length > 0 ? 'mb-12 md:mb-16 lg:mb-[83px]' : 'mb-10 md:mb-[60px] lg:mb-[63px]';

  return (
    <ModuleBase data={data} className={classNames(isDark ? 'text-silk' : 'text-charcoal', specialPaddingClass)}>
      <Stack
        className={classNames('container flex h-full flex-col items-center justify-center', {
          'px-0': data.assets.length > 0,
        })}
      >
        <div className="flex flex-col gap-4 md:w-[470px] lg:w-[700px] lg:gap-5">
          <HeadingTag
            animate
            data={{ htag: 'h1', heading: 'Media Library' }}
            className={classNames('title-03 color-from-bg text-center', titleSpecialMarginClass)}
          ></HeadingTag>
        </div>

        {data.assets.length === 0 && (
          <>
            {data.text && (
              <Text
                dangerouslySetInnerHTML={{ __html: data.text }}
                className={classNames('body-03 mb-7 text-center', isDark ? 'text-silk/75' : '!text-charcoal/75')}
              ></Text>
            )}
            <Box>
              <form className="l mx-auto w-[258px]">
                <input
                  name="name"
                  placeholder={data.passwordLabel}
                  type="password"
                  id="name"
                  className={classNames(
                    'body-03 mb-[50px] mt-4 h-10 h-12 w-full rounded-[4px] px-4 font-medium focus:outline-none',
                    isDark ? 'bg-silk/5 placeholder:text-white' : 'bg-charcoal/5 placeholder:text-black',
                  )}
                  onChange={(event) => setPasswordInput(btoa(event.target.value))}
                  onKeyDown={handleKeyDown}
                />
              </form>
            </Box>

            <Box className="flex flex-col items-center justify-center">
              <button
                disabled={passwordInput === ''}
                onClick={handleSubmit}
                className={classNames('btn secondary', isDark ? 'dark' : 'light')}
              >
                {t('general.$submit')}
              </button>
              <AnimatePresence>
                {data.passwordNotMatch && (
                  <motion.div
                    key="errorMessage"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 50 }}
                    transition={{ type: 'tween', duration: 2 }}
                    className="body-03 mt-3 text-center text-messagingRed"
                  >
                    {data?.message}
                  </motion.div>
                )}
              </AnimatePresence>
            </Box>
          </>
        )}

        <AnimatePresence>
          {data.assets.length > 0 && (
            <motion.div
              key="assetLibrary"
              layout
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ type: 'tween', duration: 0.8 }}
              className="motion-wrapper w-full"
            >
              <MediaAssetsLibraryLoggedInModule data={data} />
            </motion.div>
          )}
        </AnimatePresence>
      </Stack>
    </ModuleBase>
  );
};

export default MediaAssetsLibraryModule;
