import React from 'react';
import { FormControl } from '@/components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const LanguageSelect = ({ metadata, onChange, ...props }) => {
  const router = useRouter();
  const { t } = useTranslation('common');

  const languageChange = (event) => {
    const lang = metadata.languages.find((item) => item.language === event.target.value);
    router.push(lang.languageUrl, lang.languageUrl, { locale: lang.language === 'en-us' ? 'en-US' : lang.language });
    document.documentElement.setAttribute('dir', ['ar', 'ar-sa'].includes(lang.language) ? 'rtl' : 'ltr');
    onChange && onChange(event);
  };

  return (
    <FormControl
      aria-label={t('general.$languageSwitcher')}
      type="select"
      required
      name="language"
      value={router.locale.toLowerCase()}
      options={metadata.languages.map((item) => ({
        label: t(`language.$${item.language.replace('-', '')}`),
        value: item.language,
      }))}
      onChange={languageChange}
      {...props}
    />
  );
};

export default LanguageSelect;
