import { createElement, forwardRef } from 'react';
import { motion } from 'framer-motion';
import { fadeInUp } from '@/animations';
import { selectIsRobot } from '@/store/index';
import { useSelector } from 'react-redux';

const Box = forwardRef(({ tag = 'div', motion = fadeInUp, animate = true, children, ...props }, ref) => {
  const isRobot = useSelector(selectIsRobot);
  const _animate = animate && motion && !isRobot;
  const _motion = _animate ? motion : null;

  return createElement(
    _animate ? getMotionTag(tag) : tag,
    {
      ..._motion,
      ...props,
      ref,
    },
    children,
  );
});

const getMotionTag = (tag) => {
  const tags = {
    div: motion.div,
    section: motion.section,
    footer: motion.footer,
    header: motion.header,
    picture: motion.picture,
    span: motion.span,
  };

  return tags[tag] || motion.div;
};

Box.displayName = 'Box';

export default Box;
