import React from 'react';
import PropTypes from 'prop-types';
import { ModuleBase, Stack, EntityCard, HeadingTag, Box, Link } from '@/components';
import classNames from 'classnames';
import { isColorDark } from '@/utils';
// import { listingGrid, listingGridItem } from '@/animations';
import ComponentAnimation from '@/animations/animationComponent/ComponentAnimation';
import AnimationStarter from '@/animations/animationComponent/AnimationStarter';

const CardListIemsModule = ({ data }) => {
  const { cards, numberOfColumns, headingTitle, description, primaryCTA, secondaryCTA, preHeading } = data || {};
  const isDark = isColorDark(data.backgroundColour);
  return (
    <ModuleBase data={data} className="overflow-hidden pb-10 pt-20 md:pt-20 lg:pb-20">
      <div className="container">
        <Stack>
          {(primaryCTA || secondaryCTA || preHeading || (headingTitle && headingTitle.heading) || description) && (
            <div
              className="description-data mb-8 w-full lg:mb-10 lg:flex lg:justify-between"
              role="contentinfo"
              aria-label="contentinfo"
            >
              <div className="title-wrapper lg:max-w-[456px]">
                <Box>{preHeading && <p className="label-01 color-from-bg mb-2 opacity-50">{preHeading}</p>}</Box>
                <Box>
                  {headingTitle && (
                    <HeadingTag data={headingTitle} className="main-title color-from-bg title-04 mb-4 lg:mb-5" />
                  )}
                </Box>
                <Box>
                  {description && (
                    <div
                      dangerouslySetInnerHTML={{ __html: description }}
                      className="color-from-bg body-03 font-montserrat"
                    />
                  )}
                </Box>
              </div>

              {(primaryCTA || secondaryCTA) && (
                <div className="color-from-bg mt-5 lg:mt-0 lg:self-end">
                  <div className="flex w-fit flex-col justify-start gap-2 md:flex-row">
                    <Link animate className={classNames('btn primary', isDark ? 'dark' : 'light')} link={primaryCTA} />

                    <Link
                      animate
                      className={classNames('btn secondary', isDark ? 'dark' : 'light')}
                      link={secondaryCTA}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </Stack>

        <AnimationStarter>
          {cards.map((cardItem) => (
            <div
              key={cardItem.moduleId}
              className={classNames(
                'grid grid-cols-1 gap-x-2 gap-y-4 md:gap-y-5',
                numberOfColumns === 'Two' && 'md:grid-cols-2 lg:grid-cols-2',
                numberOfColumns === 'Three' && 'md:grid-cols-2 lg:grid-cols-3',
                numberOfColumns === 'Four' && 'md:grid-cols-2 lg:grid-cols-4',
              )}
            >
              {cardItem.props.cards.map((card) => (
                <ComponentAnimation key={card.moduleId}>
                  <EntityCard data={card} key={card.moduleId} />
                </ComponentAnimation>
              ))}
            </div>
          ))}
        </AnimationStarter>
      </div>
    </ModuleBase>
  );
};

CardListIemsModule.propTypes = {
  data: PropTypes.any.isRequired,
  buttonWrapperClassName: PropTypes.string,
};

export default CardListIemsModule;
