import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames';
import NavColumn from './NavColumn';
import { ResponsiveImage, PopoverMenu, Link } from '..';
import { motion, AnimatePresence } from 'framer-motion';
import NavMobile from './NavMobile';
import { useTranslation } from 'next-i18next';

const Nav = forwardRef(function Nav(
  {
    open,
    onClose,
    navItems,
    mainNavImg,
    metadata,
    showLanguageSwitcher,
    mobileBottomNavItems,
    hotelVillaNavElements,
    isVilla,
  },
  ref,
) {
  const { t } = useTranslation('common');
  const [activeMenu, setActiveMenu] = useState({ l0: {} });
  const [isReady, setIsReady] = useState(false);
  const [openDropwdown, setOpenDropdown] = useState(false);
  const [title, setTitle] = useState(t('general.$overview'));

  const close = () => {
    _resetActiveMenu();
    onClose();
  };

  const _resetActiveMenu = () => {
    setActiveMenu({ l0: {} });
  };

  useImperativeHandle(ref, () => ({
    resetActiveMenu() {
      _resetActiveMenu();
    },
  }));

  const setMenuData = () => {};

  const navBgRef = useRef();
  let navFullBgHeight = useRef(0);
  let navFullBgWidth = useRef(0);

  useEffect(() => {
    if (navBgRef.current) {
      var computedStyle = getComputedStyle(navBgRef.current);
      let elementHeight = navBgRef.current.clientHeight;
      let elementWidth = window.innerWidth * 0.75;
      elementHeight -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
      elementWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
      navFullBgHeight.current = elementHeight;
      navFullBgWidth.current = elementWidth;
      setIsReady(true);
    }
    const savedTitle = localStorage.getItem('selectedTitle');
    if (savedTitle) {
      setTitle(savedTitle);
    }
  }, []);

  useEffect(() => {
    const allInactive = hotelVillaNavElements.every((item) => item.isActive === false);
    if (allInactive) {
      localStorage.removeItem('selectedTitle');
      setTitle(t('general.$overview'));
    }
  }, [hotelVillaNavElements, t]);

  const handleTitleChange = (newTitle) => {
    setTitle(newTitle);
    localStorage.setItem('selectedTitle', newTitle);
  };

  const popOverDeepClasses =
    '[&_button]:rounded-[4px] [&_button]:border [&_button]:border-charcoal [&_button]:bg-bone [&_.pop-over]:!-top-[4px] [&_.pop-over]:p-[4px] [&_.content]:border [&_.content]:bg-bone [&_.content]:border-charcoal [&_.pop-over]:bg-bone [&_.pop-over]:rounded-[4px] [&_.content]:py-[38px] [&_.content]:px-[14px]';

  return (
    <>
      <div
        className={classNames(
          'fixed left-0 top-[88px] z-30 hidden w-screen transition-opacity duration-500 lg:flex lg:bg-black',
          open ? 'disable-scroll h-dvh opacity-50 lg:h-[calc(100dvh-80px)]' : 'h-0 opacity-0',
        )}
        onClick={close}
      />

      <div
        className={classNames(
          'dark:bg-gray-800 fixed left-0 top-[88px] z-40 hidden h-dvh w-full overflow-y-auto transition-all duration-700 ease-out lg:block lg:h-screen',
          { '-translate-x-full opacity-0 rtl:translate-x-full': !open },
        )}
      >
        <NavColumn
          navItems={navItems}
          activeMenu={activeMenu}
          level={0}
          showLanguageSwitcher={showLanguageSwitcher}
          setActiveMenu={(sub) => setActiveMenu({ l0: sub })}
          close={close}
          metadata={metadata}
          open={open}
          setMenuData={setMenuData} // Pass setMenuData to NavColumn
        />

        <div
          ref={navBgRef}
          className={classNames(
            'hidden h-[calc(100%-80px)] w-[75%] items-center justify-end bg-silk pb-[56px] pl-[0] pr-[42px] pt-[52px] transition-all duration-700 lg:ml-auto lg:flex rtl:lg:ml-[unset] rtl:lg:mr-auto',
            open ? 'opacity-100' : 'opacity-0',
          )}
        >
          <AnimatePresence>
            {isReady && (
              <motion.div
                key="modal"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={classNames('main-nav-image h-full w-full text-right transition-all duration-500 lg:block')}
              >
                <ResponsiveImage
                  image={mainNavImg}
                  widths={{
                    xs: navFullBgWidth.current,
                    sm: navFullBgWidth.current,
                    md: navFullBgWidth.current,
                    lg: navFullBgWidth.current,
                    xl: navFullBgWidth.current,
                    xxl: navFullBgWidth.current,
                  }}
                  heights={{
                    xs: navFullBgHeight.current,
                    sm: navFullBgHeight.current,
                    md: navFullBgHeight.current,
                    lg: navFullBgHeight.current,
                    xl: navFullBgHeight.current,
                    xxl: navFullBgHeight.current,
                  }}
                  className="pointer-events-none h-full w-full object-cover transition-all duration-300"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>

      {/* <pre>{JSON.stringify(navItems.hotelVillaNavElements, null, 2)}</pre> */}

      <AnimatePresence>
        {open && (
          <motion.div
            key="mobilNav"
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ type: 'spring', stiffness: 100, damping: 20 }}
          >
            <NavMobile
              className={classNames(
                'h-[calc(100dvh-81px)] -translate-x-full overflow-auto transition-all duration-500 lg:hidden',
                open
                  ? 'disable-scroll translate-x-0 bg-primary opacity-100 lg:h-[calc(100dvh-80px)]'
                  : '-translate-x-full opacity-0',
              )}
              close={close}
              data={navItems}
              isVilla={isVilla}
              hotelVillaNavElements={hotelVillaNavElements}
              level={0}
              showLanguageSwitcher={showLanguageSwitcher}
              metadata={metadata}
              mobileBottomNavItems={mobileBottomNavItems}
            ></NavMobile>
          </motion.div>
        )}
      </AnimatePresence>

      {hotelVillaNavElements.length > 0 && isVilla && (
        <div className="villa-navigation mx-auto mt-1 w-[98%] rounded-[4px] bg-bone p-[2px] md:w-[318px] lg:hidden">
          <PopoverMenu
            open={openDropwdown}
            onOpen={() => setOpenDropdown(true)}
            onClose={() => setOpenDropdown(false)}
            title={title}
            titleClass={'text-charcoal font-montserrat text-[14px] font-medium leading-3 [&_+span_svg]:fill-charcoal'}
            className={classNames(
              'mx-auto p-[2px] text-charcoal transition-opacity duration-700',
              popOverDeepClasses,
              openDropwdown ? '[&_.pop-over]:opacity-100' : '[&_.pop-over]:opacity-0',
            )}
          >
            {hotelVillaNavElements.map((hotelItem, hotelIndex) => (
              <div key={hotelIndex} className={classNames('hotel-nav-items interactive-02 mb-12 last:mb-0')}>
                <Link
                  onClick={() => {
                    setOpenDropdown(false);
                    handleTitleChange(hotelItem.title);
                  }}
                  className="font-montserrat text-[14px] font-medium capitalize leading-3"
                  link={hotelItem.link}
                >
                  {hotelItem?.title || hotelItem.link.text}
                </Link>
              </div>
            ))}
          </PopoverMenu>
        </div>
      )}
    </>
  );
});

export default Nav;
