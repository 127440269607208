import { ModuleBase, Media, Stack, Text, HeadingTag, Link, Box } from '@/components';
import classNames from 'classnames';
import { isColorDark } from '@/utils';
import { fadeInUp } from '@/animations';

const SecondaryHeroModule = ({ data }) => {
  const isDark = isColorDark(data.backgroundColour);
  return (
    <ModuleBase data={data} className="mb:pb-10 relative pb-8 pt-[120px] lg:pt-[168px]">
      <div className="color-from-bg container relative h-full">
        <Stack className="flex h-full flex-col justify-end md:mx-auto md:w-[472px] lg:w-[700px]">
          <div className="title-wrapper text-center" role="contentinfo" aria-label="contentinfo">
            {data.headingTitle && (
              <HeadingTag
                animate
                motion={fadeInUp}
                data={data.headingTitle}
                className="color-from-bg title-03 mb-5 lg:mb-6"
              />
            )}
          </div>

          {(data.description || data.primaryCTA || data.secondaryCTA) && (
            <div className="color-from-bg text-center">
              <Box motion={fadeInUp}>
                <Text dangerouslySetInnerHTML={{ __html: data.description }} className="body-02 !opacity-75" />
              </Box>

              {(data.primaryCTA || data.secondaryCTA) && (
                <div className="mt-8 flex flex-col items-center justify-center gap-2 md:flex-row lg:mt-10 lg:items-center">
                  <Link
                    animate
                    className={classNames('btn primary', isDark ? 'dark' : 'light')}
                    link={data.primaryCTA}
                  />
                  <Link
                    animate
                    className={classNames('btn secondary', isDark ? 'dark' : 'light')}
                    link={data.secondaryCTA}
                  />
                </div>
              )}
            </div>
          )}
        </Stack>

        <Stack>
          {data.backgroundMedia && (
            <div className="relative mt-10 aspect-[343/145] w-full md:aspect-[720/327] lg:mt-22 lg:aspect-[1432/650]">
              <Media
                media={data.backgroundMedia}
                widths={{ xs: 425, sm: 640, md: 1024, lg: 1432, xl: 1520, xxl: 1520 }}
                heights={{ xs: 400, sm: 460, md: 650, lg: 650, xl: 690, xxl: 690 }}
                className="overlay"
                dataNotLazy
                animate
                motion={fadeInUp}
                cover
              />
            </div>
          )}
        </Stack>
      </div>
    </ModuleBase>
  );
};

export default SecondaryHeroModule;
