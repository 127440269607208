import React, { forwardRef } from 'react';
import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';
import { convertAbsoluteLinkToRelative, isFullLink, pushValuesToRouteQuery } from '@/utils';
import { Box } from '@/components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import queryString from 'query-string';
import External from '@/assets/externalLink.svg';

const Link = forwardRef(({ animate, motion, link, children, ariaLabel, ...props }, ref) => {
  const router = useRouter();
  const { t } = useTranslation('common');

  if (!link) return;

  const fixedLink = convertAbsoluteLinkToRelative(link);
  const addHref = isFullLink(link);
  const queryParams = addHref === false ? queryString.parse(link.href) : null;

  const linkObj = (
    <NextLink
      href={fixedLink?.href || ''}
      target={fixedLink?.target}
      aria-label={
        fixedLink?.target === '_blank' ? `${ariaLabel} (${t('general.$newWindowAriaLabel')})` || link?.text : null
      }
      {...props}
      ref={ref}
    >
      {children || link?.text}
      {fixedLink?.target === '_blank' ? <External role="presentation" /> : null}
    </NextLink>
  );

  const btnObj = (
    <button onClick={() => pushValuesToRouteQuery(router, queryParams)} {...props}>
      {t('general.$enquire')}
    </button>
  );

  return !animate && !motion ? addHref ? linkObj : btnObj : <Box motion={motion}>{addHref ? linkObj : btnObj}</Box>;
});

Link.displayName = 'Link';

Link.propTypes = {
  link: PropTypes.object,
};

export default Link;
