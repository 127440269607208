import { ModuleBase, ResponsiveImage, Stack, Text, Link, HeadingTag } from '@/components';

const HeroCenterAlignedModule = ({ data }) => {
  return (
    <ModuleBase data={data} className="relative md:py-4">
      <div className="w-ful left-0 right-0 top-0 h-[400px] sm:absolute sm:h-full">
        <ResponsiveImage
          image={data.backgroundMedia}
          widths={{ xs: 425, sm: 640, md: 768, lg: 1024, xl: 1280, xxl: 2560 }}
          heights={{ xs: 400, sm: 460, md: 585, lg: 780, xl: 975, xxl: 1950 }}
          className="overlay"
          dataNotLazy
        />
      </div>

      <Stack className="container relative flex h-full flex-col items-center justify-center lg:w-3/5 lg:pb-28 lg:pt-32">
        <div className="title-wrapper" role="contentinfo" aria-label="contentinfo">
          {data.preHeading && (
            <Text className="text-subheading my-4 font-montserrat text-base font-semibold uppercase text-white">
              {data.preHeading}
            </Text>
          )}
          {data.headingTitle && (
            <HeadingTag
              animate
              data={data.headingTitle}
              className="color-from-bg mt-7 justify-center font-montserrat text-5xl font-semibold not-italic leading-heading1m tracking-heading1m !text-white lg:text-7xl lg:leading-heading1 lg:tracking-heading1"
            />
          )}
          {data.subHeading && (
            <Text className="text-subheading my-4 font-montserrat text-base font-semibold text-white">
              {data.subHeading}
            </Text>
          )}
        </div>

        {(data.description || data.primaryCTA || data.secondaryCTA) && (
          <div className="color-from-bg mb-4 mt-5 text-heading6 font-light not-italic leading-8 tracking-heading6">
            <Text dangerouslySetInnerHTML={{ __html: data.description }} className="text-white" />

            {(data.primaryCTA || data.secondaryCTA) && (
              <div className="mt-15 flex justify-center gap-4 sm:mt-16 lg:mt-20">
                <Link animate className="btn primary" link={data.primaryCTA} />

                <Link animate className="btn secondary" link={data.secondaryCTA} />
              </div>
            )}
          </div>
        )}
      </Stack>
    </ModuleBase>
  );
};

export default HeroCenterAlignedModule;
