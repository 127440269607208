import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isColorDark, fixColor } from '@/utils';

const ModuleBase = ({ data, className, hTag, ...props }) => {
  let classes = ['module', className];

  const backgroundColor = fixColor(data?.backgroundColour);
  //padding based on margin-01, 02 and 03
  if (data?.paddingTop) {
    if (data.paddingTop.toLowerCase() === 'medium') {
      classes.push('pt-8 md:pt-10 lg:pt-22');
    } else if (data.paddingTop.toLowerCase() === 'large') {
      classes.push('pt-10 md:pt-14 lg:pt-28');
    } else if (data.paddingTop.toLowerCase() !== 'none') {
      classes.push('pt-6 md:pt-8 lg:pt-10');
    }
  }

  if (data?.paddingBottom) {
    if (data.paddingBottom.toLowerCase() === 'medium') {
      classes.push('pb-8 md:pb-10 lg:pb-22');
    } else if (data.paddingBottom.toLowerCase() === 'large') {
      classes.push('pb-10 md:pb-14 lg:pb-28');
    } else if (data.paddingBottom.toLowerCase() !== 'none') {
      classes.push('pb-6 md:pb-8 lg:pb-10');
    }
  }

  if (isColorDark(backgroundColor)) {
    classes.push('[&_.color-from-bg]:text-silk');
  } else {
    classes.push('[&_.color-from-bg]:text-charcoal');
  }

  classes.push(data?.moduleName || null);

  return React.createElement(hTag?.toLowerCase() || 'section', {
    id: data?.anchor || null,
    style: {
      backgroundColor,
    },
    className: classnames(classes),
    ...props,
  });
};

ModuleBase.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ModuleBase;
