import React, { useState, useEffect, useRef } from 'react';
import { pushValuesToRouteQuery, assignValuesFromRouteQuery } from '@/utils';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import Arrow2 from '@/assets/arrow2.svg';
import ArrowForward from '@/assets/arrowForward.svg';

const Pagination = ({
  pageSize,
  totalCount,
  usePagination,
  defaultPageSize,
  origTotalPages,
  onChange,
  queryMode,
  isDark,
  ...props
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [btns, setBtns] = useState([]);
  const totalPages = useRef(1);
  const router = useRouter();
  const { t } = useTranslation('common');
  const showSeeMore = !usePagination;

  useEffect(() => {
    const pageObj = { page: 1 };
    assignValuesFromRouteQuery(router, pageObj);
    setCurrentPage(Number(pageObj.page || 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  useEffect(() => {
    totalPages.current = Math.ceil(totalCount / pageSize);
    const newBtns = [];
    for (let page = 1; page <= totalPages.current; page++) {
      if (totalPages.current > 4) {
        if (
          page === 1 ||
          currentPage === page ||
          currentPage - 1 === page ||
          currentPage + 1 === page ||
          (page === 2 && currentPage === 4) ||
          (page === totalPages.current && currentPage === totalPages.current - 2) ||
          (currentPage < 4 && totalPages.current > 3 && page < 4) ||
          (currentPage === totalPages.current && page === currentPage - 2)
        ) {
          newBtns.push({ label: page, value: page });
        } else if (
          (page === 2 && currentPage > 3) ||
          (page !== 2 && page === totalPages.current && totalPages.current > 3)
        ) {
          newBtns.push({ label: '...', value: page });
        }
      } else {
        newBtns.push({ label: page, value: page });
      }
    }
    setBtns(newBtns);
  }, [pageSize, totalCount, currentPage]);

  const setPage = (newPage) => {
    if (queryMode) {
      let pageSizeParams = {};
      if (showSeeMore) {
        pageSizeParams = {
          pageSize: pageSize + defaultPageSize,
        };
      }
      pushValuesToRouteQuery(router, {
        page: newPage > 1 ? newPage : null,
        ...pageSizeParams,
      });
    }

    if (onChange) {
      onChange({
        page: newPage,
      });
    }
  };

  if (totalPages.current <= 1 || isNaN(totalPages.current)) return;

  return showSeeMore ? (
    currentPage < origTotalPages && (
      <div className="pt-8 text-center md:pt-6">
        <button className="btn tertiary color-from-bg" onClick={() => setPage(1)}>
          {t('general.$seeMore')}
          <ArrowForward className={classNames(isDark ? '[&_path]:fill-silk' : '[&_path]:fill-charcoal')} />
        </button>
      </div>
    )
  ) : (
    <div className="mt-6 flex items-center justify-center gap-4 lg:mt-20" {...props}>
      <button className="btn control dark" disabled={currentPage <= 1} onClick={() => setPage(currentPage - 1)}>
        <Arrow2 className="-rotate-180" />
      </button>
      {btns.map((btn) => (
        <button
          className={classNames(
            'body-03 hidden h-auto w-[20px] md:block',
            currentPage === btn.value && 'underline underline-offset-2',
          )}
          key={btn.value}
          disabled={btn.label === '...'}
          onClick={() => setPage(btn.value)}
        >
          {btn.label} {currentPage === btn.value}
        </button>
      ))}

      <div className="text-subheading mx-5 md:hidden">
        {t('general.$pagination', { currentPage, totalPages: totalPages.current })}
      </div>

      <button
        className="btn control dark"
        disabled={currentPage >= totalPages.current}
        onClick={() => setPage(currentPage + 1)}
      >
        <Arrow2 />
      </button>
    </div>
  );
};
export default Pagination;
