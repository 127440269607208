import PropTypes from 'prop-types';
import { ResponsiveImage, Link, MediaLightboxCarousel } from '..';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import tailwindConfig from '@/tailwind.config.js';
import CarouselIndicator from '@/assets/carouselIndicator.svg';
import { MotionContext } from 'framer-motion';

const GenericCardSmall = ({ data, className, isDragging, ...props }) => {
  const { t } = useTranslation('common');
  const [desktop, setDesktop] = useState(false);
  const [activeImage, setActiveImage] = useState(null);

  const images = [];
  data.mediaLightBox.map((image) => images.push(image));

  useEffect(() => {
    const resize = () => {
      setDesktop(window.innerWidth >= parseInt(tailwindConfig.theme.screens.xl, 10));
    };

    resize();

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  return (
    <div>
      {activeImage !== null && (
        <MotionContext.Provider value={{}}>
          <MediaLightboxCarousel
            key={data.moduleId}
            moduleId={data.moduleId}
            slides={images}
            activeImage={activeImage}
            onClose={() => setActiveImage(null)}
          />
        </MotionContext.Provider>
      )}
      <>
        {data.link && desktop ? (
          <Link
            className={classNames(className, 'main-wrapper group flex h-[375px] flex-col lg:h-[415px]')}
            {...props}
            link={data.link}
            onPointerDownCapture={(e) => e.preventDefault()}
            onClick={(e) => isDragging && e.preventDefault()}
          >
            <div className="relative h-[229px] w-full overflow-hidden lg:aspect-[unset] lg:h-[315px]">
              {data.image && (
                <ResponsiveImage
                  image={data.image}
                  widths={{ xs: 645, md: 534, lg: 752 }}
                  heights={{ xs: 644, md: 653, lg: 473 }}
                  imgProps={{ className: 'transition-transform duration-1000 group-hover:scale-110' }}
                />
              )}

              {data.mediaLightBox.length > 0 && (
                <div
                  className="absolute right-8 top-4 z-50 flex h-6 w-[57px] cursor-pointer items-center justify-center rounded-[1000px] border border-silk"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveImage(0);
                  }}
                >
                  <CarouselIndicator className={classNames('ltr:mr-2 rtl:ml-2')} />
                  <span className="font-montserrat text-[12px] font-[500] leading-3 text-silk">
                    {data.mediaLightBox.length}
                  </span>
                </div>
              )}
            </div>

            <div className="content-wrapper flex h-[105px] grow flex-col justify-between py-4 lg:h-[105px]">
              <div className={classNames('text-wrapper flex grow flex-col')}>
                <div>
                  {data.label && <p className="label-01 color-from-bg opacity-50">{data.label}</p>}
                  {data.title && (
                    <p
                      className="title-05 color-from-bg mb-[6px] mt-[6px] lg:mb-0.5 lg:mt-[14px] xl:text-[24px] xl:leading-8"
                      role="heading"
                      aria-level="7"
                    >
                      {data.title}
                    </p>
                  )}
                </div>

                {data.description && (
                  <div
                    className="body-04 lg:body-03 line-clamp-1 leading-5 tracking-[0.14px] text-charcoal/75 xl:text-[14px]"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                )}

                {data.link && (
                  <div className="xl:hidden">
                    <Link className="btn tertiary" link={data.link}>
                      {t('general.$viewMore')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </Link>
        ) : (
          <div className={classNames(className, 'main-wrapper flex h-[375px] flex-col lg:h-[415px]')}>
            <div className="relative h-[229px] w-full overflow-hidden lg:aspect-[unset] lg:h-[315px]">
              {data.image && (
                <ResponsiveImage
                  image={data.image}
                  widths={{ xs: 430, md: 768, lg: 501 }}
                  heights={{ xs: 229, md: 229, lg: 315 }}
                />
              )}

              {data.mediaLightBox.length > 0 && (
                <div
                  className="absolute right-8 top-4 z-50 flex h-6 w-[57px] cursor-pointer items-center justify-center rounded-[1000px] border border-silk"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveImage(0);
                  }}
                >
                  <CarouselIndicator className={classNames('ltr:mr-2 rtl:ml-2')} />
                  <span className="font-montserrat text-[12px] font-[500] leading-3 text-silk">
                    {data.mediaLightBox.length}
                  </span>
                </div>
              )}
            </div>

            <div className="content-wrapper flex h-[105px] grow flex-col justify-between py-4 lg:h-[105px]">
              <div className={classNames('text-wrapper flex grow flex-col')}>
                <div>
                  {data.label && <p className="label-01 color-from-bg opacity-50">{data.label}</p>}
                  {data.title && (
                    <p
                      className="title-05 color-from-bg mb-[6px] mt-[6px] lg:mb-0.5 lg:mt-[14px] xl:text-[24px] xl:leading-8"
                      role="heading"
                      aria-level="7"
                    >
                      {data.title}
                    </p>
                  )}
                </div>

                {data.description && (
                  <div
                    className="body-04 lg:body-03 line-clamp-1 leading-5 tracking-[0.14px] text-charcoal/75 xl:text-[14px]"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                )}

                {data.link && (
                  <div className="xl:hidden">
                    <Link className="btn tertiary" link={data.link}>
                      {t('general.$viewMore')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};
GenericCardSmall.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GenericCardSmall;
