import { createElement, forwardRef } from 'react';
import { motion } from 'framer-motion';
import { fadeInUp, splitWords as splitWordsAnimation } from '@/animations';
import { splitWords, stripHtml, isFunction } from '@/utils';
import classNames from 'classnames';
import { selectIsRobot } from '@/store/index';
import { useSelector } from 'react-redux';

const Text = forwardRef(
  ({ motion = fadeInUp, animate = true, tag = 'div', children, dangerouslySetInnerHTML, ...props }, ref) => {
    const isRobot = useSelector(selectIsRobot);
    const _animate = animate && motion && !isRobot;
    const _motion = _animate ? motion : null;

    if (_animate && isFunction(motion) && splitWordsAnimation().name === motion().name) {
      const words = splitWords(stripHtml(children || dangerouslySetInnerHTML.__html) || '');
      const renderText = words.map((val, i) => {
        return createElement(getMotionTag('span'), { ...motion().motion, key: i }, val);
      });
      return createElement(
        tag,
        {
          ...props,
          className: classNames(props.className, 'flex flex-wrap gap-[1rem]'),
          ref,
        },
        renderText,
      );
    }

    return createElement(
      _animate ? getMotionTag(tag) : tag || 'div',
      {
        ..._motion,
        ...props,
        dangerouslySetInnerHTML,
        ref,
      },
      children,
    );
  },
);

const getMotionTag = (tag) => {
  const tags = {
    p: motion.p,
    h1: motion.h1,
    h2: motion.h2,
    h3: motion.h3,
    h4: motion.h4,
    h5: motion.h5,
    h6: motion.h6,
    span: motion.span,
    div: motion.div,
  };

  return tags[tag] || motion.div;
};

Text.displayName = 'Text';

export default Text;
