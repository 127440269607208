import { ModuleBase, ResponsiveImage, Media, Link, Text, HeadingTag, Box } from '@/components';

import classNames from 'classnames';

const RichTextAndTwoPortraitStackedModule = ({ data }) => {
  const { headingTitle, description, preHeading, subHeading, primaryCTA, secondaryCTA } = data || {};
  return (
    <ModuleBase data={data}>
      <div
        className={classNames(
          'main-data container flex lg:justify-between lg:px-24',
          data.mediaAlignment === 'Left' ? 'flex-col-reverse lg:flex-row-reverse' : 'flex-col lg:flex-row',
        )}
      >
        <div className="text-wrapper w-full md:w-[65%] lg:my-auto lg:w-[45%]">
          <div className="title-wrapper" role="contentinfo" aria-label="contentinfo">
            {preHeading && (
              <Text className="text-subheading my-4 font-montserrat text-base font-semibold uppercase text-black">
                {preHeading}
              </Text>
            )}
            {headingTitle && (
              <HeadingTag
                data={headingTitle}
                className="title color-from-bg mt-7 font-montserrat text-heading2 leading-heading2"
              />
            )}
            {subHeading && (
              <Text className="text-subheading my-4 font-montserrat text-base font-semibold text-black">
                {subHeading}
              </Text>
            )}
          </div>

          {(description || primaryCTA || secondaryCTA) && (
            <div className="color-from-bg mb-4 mt-5 text-paragraph">
              <Text dangerouslySetInnerHTML={{ __html: description }} />

              {(primaryCTA || secondaryCTA) && (
                <Box className="mt-10 flex justify-start gap-4 sm:mt-16 lg:mt-10">
                  <Link className="btn primary self-end" link={primaryCTA} />

                  <Link className="btn secondary self-end" link={secondaryCTA} />
                </Box>
              )}
            </div>
          )}
        </div>

        <div
          className={classNames(
            'relative my-auto aspect-3/4 h-fit w-full lg:w-[45%]',
            data.mediaAlignment === 'Left'
              ? 'mb-14 self-start md:mb-10 lg:mb-0'
              : 'mt-10 self-end md:mt-10 lg:mb-0 lg:mt-0',
          )}
        >
          <ResponsiveImage
            image={data.backgroundImage}
            widths={{ xs: 327, md: 688, lg: 600 }}
            className="absolute h-full w-full object-cover"
          />

          <div className="absolute left-1/2 top-1/2 aspect-3/4 h-fit w-[70%] -translate-x-2/4 -translate-y-1/2">
            <Media
              media={data.foregroundMedia}
              widths={{ xs: 156, md: 332, lg: 392 }}
              className="absolute h-full w-full object-cover"
            />
          </div>
        </div>
      </div>
    </ModuleBase>
  );
};

export default RichTextAndTwoPortraitStackedModule;
