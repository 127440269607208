import { ModuleBase, Link, Text, HeadingTag, Stack, Box, ResponsiveImage } from '@/components';
import { isColorDark } from '@/utils';
import classNames from 'classnames';

const HeadingVerticalModule = ({ data }) => {
  const isDark = isColorDark(data.backgroundColour);
  const { headingTitle, description, preHeading, primaryCTA, secondaryCTA } = data || {};
  return (
    <ModuleBase data={data}>
      <Stack className="container text-center">
        {data.logo && (
          <Box className="logo relative mx-auto mb-8 h-[80px] w-[71px] md:h-[90px] md:w-[80px] lg:h-[112px] lg:w-[100px] xl:mb-14">
            <ResponsiveImage
              image={data.logo}
              widths={{ xs: 70, md: 80, lg: 100, xl: 100 }}
              imgProps={{ className: 'w-full h-full object-contain pointer-events-none' }}
            />
          </Box>
        )}

        <div className="text-wrapper md:mx-auto md:w-[472px] lg:w-[700px]">
          <Box className="title-wrapper" role="contentinfo" aria-label="contentinfo">
            {preHeading && <Text className="color-from-bg label-01 mb-4 font-montserrat">{preHeading}</Text>}
            {headingTitle && <HeadingTag data={headingTitle} className="title title-03 color-from-bg mb-6 lg:mb-10" />}
          </Box>

          {(description || primaryCTA || secondaryCTA) && (
            <Box className="color-from-bg body-02">
              <Text dangerouslySetInnerHTML={{ __html: description }} className="body-02 font-montserrat !opacity-75" />

              {(primaryCTA || secondaryCTA) && (
                <Box className="mt-9 flex flex-col items-center justify-center gap-2 md:flex-row lg:mt-0 lg:items-center xl:mt-14">
                  <Link
                    animate
                    className={classNames('btn primary', isDark ? 'dark' : 'light')}
                    link={data.primaryCTA}
                  />
                  <Link
                    animate
                    className={classNames('btn secondary', isDark ? 'dark' : 'light')}
                    link={data.secondaryCTA}
                  />
                </Box>
              )}
            </Box>
          )}
        </div>
      </Stack>
    </ModuleBase>
  );
};

export default HeadingVerticalModule;
