import React from 'react';
import { Link, ModuleBase } from '@/components';

const BreadcrumbsModel = ({ data }) => {
  return (
    <ModuleBase data={data} className="bg-bone">
      <div className="container py-6 md:py-8 lg:py-10">
        <div className="tracking-title-m flex items-center justify-start gap-4 overflow-auto rounded-[2px] bg-charcoal/5 px-4 py-4 scrollbar-hide md:justify-center lg:py-6">
          {data.crumbs?.map((link, index) =>
            link.href ? (
              <React.Fragment key={index}>
                <Link className="interactive-02 shrink-0 text-charcoal/50" link={link} />
                <span className="interactive-02 text-charcoal/50">/</span>
              </React.Fragment>
            ) : (
              <span key={index} className="interactive-02 shrink-0 text-charcoal">
                {link.text}
              </span>
            ),
          )}
        </div>
      </div>
    </ModuleBase>
  );
};
export default BreadcrumbsModel;
