import React, { useEffect, useState } from 'react';
import { Carousel, ResponsiveImage } from '@/components';

import Arrow from '@/assets/lightboxArrow.svg';
import Close from '@/assets/lightboxClose.svg';
import ModalPortal from './ModalPortal';
import classNames from 'classnames';
import { useDimensions } from '@/hooks';
import { getFormattedValue } from '@/utils';
import { AnimatePresence, motion } from 'framer-motion';

const largeBreakpoints = ['lg', 'xl', 'xxl'];

const MediaLightboxCarousel = ({ activeImage, onClose, closeOnBgClick = false, slides, moduleId }) => {
  const { breakpoint } = useDimensions();
  const [currentImage, setCurrentImage] = useState(activeImage || 0);
  const [hasAnyCaption, setHasAnyCaption] = useState(true);

  const close = () => {
    onClose && onClose();
  };

  useEffect(() => {
    setTimeout(() => {
      const captionTexts = document.querySelectorAll('.caption-text');
      const captionTextPlaceholders = document.querySelectorAll('.caption-text-placeholder');
      if (captionTexts.length > 0) {
        const captionTextHeight = Math.max(...Array.from(captionTexts).map((caption) => caption.offsetHeight));
        captionTexts.forEach((caption) => {
          caption.style.minHeight = `${captionTextHeight}px`;
        });
        captionTextPlaceholders.forEach((caption) => {
          caption.style.minHeight = `${captionTextHeight}px`;
        });
      } else {
        setHasAnyCaption(false);
      }
    }, 100);
  }, []);

  const items = slides.map((slide, index) => {
    return (
      <div key={`slide-${index}`} className="flex w-full flex-initial flex-col">
        <ResponsiveImage
          showSpinner={true}
          image={slide?.image ? slide.image : slide}
          heights={{ xs: 253, sm: 253, md: 472, lg: 664, xl: 668, xxl: 668 }}
          className="pointer-events-none h-[253px] md:h-[472px] lg:h-[664px] xl:h-[668px]"
        />
        {slide?.image?.imageAlt || slide?.image?.caption || slide?.imageAlt || slide?.caption ? (
          <p className="caption-text label-01 mt-[46px] text-center font-montserrat uppercase text-silk md:mt-[53px] lg:mt-10">
            {slide?.image?.imageAlt || slide?.image?.caption || slide?.imageAlt || slide?.caption}
          </p>
        ) : (
          hasAnyCaption && <p className="caption-text-placeholder mt-[46px] md:mt-[53px] lg:mt-10"></p>
        )}
      </div>
    );
  });

  return (
    <ModalPortal>
      <AnimatePresence>
        {activeImage !== null && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: 'spring', stiffness: 80, damping: 30 }}
            className={classNames(
              'disable-scroll fixed top-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-black/70 backdrop-blur-[6px] supports-[height:100dvh]:h-dvh',
            )}
            onClick={() => {
              if (closeOnBgClick) close();
            }}
          >
            <button className="absolute right-6 top-6 z-10 md:right-8 md:top-8" onClick={close}>
              <Close role="presentation" aria-label="Close" className="size-12" />
            </button>
            <div className="w-full max-w-screen-xxl px-4 md:py-6 lg:h-fit lg:pl-[162px] lg:pr-[11.188rem]">
              <div className="relative mb-04 flex items-center justify-center gap-01 text-center font-montserrat text-label-01 text-silk/50">
                <span className="text-silk">{getFormattedValue(currentImage, 'leadingZeroNumbers')}</span>/
                <span className="">{getFormattedValue(slides.length - 1, 'leadingZeroNumbers')}</span>
              </div>
              <Carousel
                items={items}
                moduleId={moduleId}
                className="mx-auto h-fit !flex-col justify-center gap-04 [&_.carousel-canvas]:h-min [&_.carousel-item]:h-full [&_.carousel-item_img]:object-contain"
                animationStyle="elegant"
                crop
                variant="lightbox"
                gap={1}
                showPagination={!largeBreakpoints.includes(breakpoint)}
                controls={{
                  show: true,
                  directionComponent: <Arrow />,
                  controlStyle: {
                    prev: 'control pointer-events-auto rotate-180 rtl:rotate-0',
                    next: 'control pointer-events-auto rtl:rotate-180',
                    hide: 'opacity-50 cursor-not-allowed',
                  },
                  wrapperStyle:
                    'absolute !w-[calc(100vw-17px)] lg:!w-screen left-1/2 -translate-x-[calc(50%+8.5px)] origin-center container justify-between pointer-events-none hidden lg:flex',
                }}
                paginationType="slider"
                current={currentImage}
                setCurrent={setCurrentImage}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </ModalPortal>
  );
};

export default MediaLightboxCarousel;
