import { Carousel, HeadingTag, Link, Stack, ModuleBase, ResponsiveImage, Text } from '@/components';
import tailwindConfig from '@/tailwind.config';
import { isColorDark } from '@/utils';
import classNames from 'classnames';
import { useEffect, useState, useRef } from 'react';
import { motion, useScroll, useSpring, useTransform } from 'framer-motion';
import ComponentAnimation from '@/animations/animationComponent/ComponentAnimation';
import { animControllerInView } from '@/animations';

const TwoPortraitMediaAndRte = ({ data }) => {
  const { headingTitle, description, primaryCTA, secondaryCTA } = data || {};
  const [windowWidth, setWindowWidth] = useState(0);
  const scrollContainerRef = useRef(null);
  const { screens } = tailwindConfig.theme;
  const isDark = isColorDark(data.backgroundColour);
  const { scrollYProgress } = useScroll({ target: scrollContainerRef });
  const scrollSpring = useSpring(scrollYProgress, { stiffness: 160, damping: 30 });
  const y = useTransform(scrollSpring, [0, 0.8], ['0%', '-50%']);
  const paginationTransform = useTransform(scrollSpring, [0, 1], ['0%', '100%']);

  useEffect(() => {
    const resize = () => {
      setWindowWidth(window.innerWidth);
    };

    resize();

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  const direction = windowWidth >= parseInt(screens.lg, 10) ? 'y' : 'x';

  const items = data.images.map((image, imageIndex) => (
    <div
      key={imageIndex}
      className="relative aspect-8/10 w-full md:w-[348px] lg:w-[500px] xl:aspect-[700/875] xl:w-[700px] shortDesktop:w-[450px]"
    >
      <ResponsiveImage
        image={image}
        widths={{ xs: 660, sm: 1152, md: 498, lg: 1050, xl: 1050, xxl: 1050 }}
        heights={{ xs: 653, sm: 1536, md: 663, lg: 1313, xl: 1313, xxl: 1313 }}
        className="overlay pointer-events-none"
      />
    </div>
  ));

  const delayText = data.mediaAlignment === 'Left' ? 0.8 : 0.2;
  const delayPicture = data.mediaAlignment === 'Left' ? 0.2 : 0.8;

  return (
    <ModuleBase data={data}>
      <Stack
        ref={scrollContainerRef}
        motion={animControllerInView({ amount: 0.3 })}
        className={classNames('relative w-full', data.images.length > 1 ? 'lg:h-[130vh] xl:h-[150vh]' : '')}
      >
        <div
          className={classNames(
            'main-data container flex flex-col-reverse gap-y-8 pb-0 md:justify-between',
            data.mediaAlignment === 'Left' ? 'md:flex-row-reverse' : 'md:flex-row',
            data.images.length > 1 ? 'lg:sticky lg:top-[15%] shortDesktop:top-[15%]' : '',
          )}
        >
          <ComponentAnimation
            delay={delayText}
            className={classNames('text-wrapper md:my-auto md:w-[348px] xl:w-[496px]')}
          >
            <div className="title-wrapper" role="contentinfo" aria-label="contentinfo">
              {headingTitle && (
                <HeadingTag animate data={headingTitle} className="title-05 color-from-bg font-kepler" />
              )}
            </div>
            {(description || primaryCTA || secondaryCTA) && (
              <div className="mt-4">
                <Text
                  animate
                  dangerouslySetInnerHTML={{ __html: description }}
                  className="color-from-bg body-02 !text-opacity-75"
                />
                {(primaryCTA || secondaryCTA) && (
                  <div className="mt-5 flex flex-col gap-2 md:flex-row lg:mt-6">
                    <Link
                      animate
                      className={classNames('btn primary', isDark ? 'dark' : 'light')}
                      link={data.primaryCTA}
                    />
                    <Link
                      animate
                      className={classNames('btn secondary', isDark ? 'dark' : 'light')}
                      link={data.secondaryCTA}
                    />
                  </div>
                )}
              </div>
            )}
          </ComponentAnimation>

          <ComponentAnimation delay={delayPicture}>
            <div className="relative my-auto overflow-hidden md:h-[435px] md:w-[348px] lg:h-[625px] lg:w-[500px] xl:h-[825px] xl:w-[700px] shortDesktop:h-[562px] shortDesktop:w-[450px]">
              {data.images.length > 1 ? (
                <>
                  {direction === 'y' && (
                    <motion.div style={{ y }} className="flex flex-col">
                      {items}
                    </motion.div>
                  )}

                  {direction === 'y' && (
                    <div className="desktop-pagination absolute right-6 top-1/2 h-20 w-1 -translate-y-1/2 rounded bg-charcoal/50">
                      <motion.div
                        style={{ y: paginationTransform }}
                        className="desktop-pagination-active h-10 w-full rounded bg-silk"
                      />
                    </div>
                  )}

                  {direction === 'x' && (
                    <Carousel
                      items={items}
                      moduleId={data.moduleId}
                      animationStyle="elegant"
                      showPagination
                      paginationType="slider"
                      className="[&_.carousel-pagination-slider-track]:w-[81px] [&_.carousel-pagination]:absolute [&_.carousel-pagination]:bottom-4 [&_.carousel-pagination]:left-1/2 [&_.carousel-pagination]:-translate-x-1/2"
                    />
                  )}
                </>
              ) : (
                <div className="relative aspect-8/10 w-full md:w-[348px] lg:w-[500px] xl:aspect-[700/875] xl:w-[700px] shortDesktop:w-[450px]">
                  <ResponsiveImage
                    image={data.images[0]}
                    widths={{ xs: 440, sm: 768, md: 332, lg: 700, xl: 700, xxl: 700 }}
                    heights={{ xs: 435, sm: 1024, md: 442, lg: 875, xl: 875, xxl: 875 }}
                    className="overlay pointer-events-none"
                  />
                </div>
              )}
            </div>
          </ComponentAnimation>
        </div>
      </Stack>
    </ModuleBase>
  );
};

export default TwoPortraitMediaAndRte;
