export const animController = (active = false, delay = 0, staggerChildren = 0.2) => ({
  initial: 'inactive',
  animate: active ? 'active' : 'inactive',
  variants: {
    inactive: {
      opacity: 0,
    },
    active: {
      opacity: 1,
      transition: {
        delay,
        staggerChildren,
      },
    },
  },
});

export const animControllerInView = (props) => ({
  initial: 'inactive',
  whileInView: 'active',
  viewport: { once: props?.once !== undefined ? props?.once : true, amount: props?.amount || 0.5 },
  variants: {
    inactive: {
      opacity: 0,
    },
    active: {
      opacity: 1,
      transition: {
        delay: props?.delay || 0,
        staggerChildren: props?.staggerChildren || 0.2,
      },
    },
  },
});

export const splitWords = (motion = fadeInUp) => ({
  name: 'splitWords',
  motion,
});

const easeIn = {
  type: 'spring',
  damping: 25,
};

const customAnimationTime = {
  type: 'tween',
  bounce: 0,
  duration: 1.5,
};

const bounceIn = {
  type: 'spring',
  stiffness: 500,
};

export const fadeIn = {
  variants: {
    inactive: {
      opacity: 0,
      transition: easeIn,
    },
    active: {
      opacity: 1,
      transition: easeIn,
    },
  },
};

export const specificFadeIn = {
  variants: {
    inactive: {
      opacity: 0,
      transition: customAnimationTime,
    },
    active: {
      opacity: 1,
      transition: customAnimationTime,
    },
  },
};

export const fadeInDown = {
  variants: {
    inactive: {
      y: -50,
      opacity: 0,
    },
    active: {
      y: 0,
      opacity: 1,
      transition: easeIn,
    },
  },
};

export const fadeInUp = {
  variants: {
    inactive: {
      y: 50,
      opacity: 0,
    },
    active: {
      y: 0,
      opacity: 1,
      transition: easeIn,
    },
  },
};

export const fadeInLeft = {
  variants: {
    inactive: {
      x: 50,
      opacity: 0,
    },
    active: {
      x: 0,
      opacity: 1,
      transition: easeIn,
    },
  },
};

export const fadeInRight = {
  variants: {
    inactive: {
      x: -50,
      opacity: 0,
    },
    active: {
      x: 0,
      opacity: 1,
      transition: easeIn,
    },
  },
};

export const scaleIn = {
  variants: {
    inactive: {
      scale: 0,
      opacity: 0,
    },
    active: {
      scale: 1,
      opacity: 1,
      transition: easeIn,
    },
  },
};

export const springIn = {
  variants: {
    inactive: {
      scale: 0,
      opacity: 0,
    },
    active: {
      scale: 1,
      opacity: 1,
      transition: bounceIn,
    },
  },
};
