import React, { useState, useEffect } from 'react';
import { ModuleBase, UmbracoForm } from '@/components';
import { useSelector } from 'react-redux';
import { selectIsRobot } from '@/store/index';
import { useRouter } from 'next/router';

const FormModule = ({ data }) => {
  const [formDefinition, setFormDefinition] = useState();
  const isRobot = useSelector(selectIsRobot);
  const router = useRouter();

  useEffect(() => {
    async function fetchData() {
      const isPreview = !!router.query.isPreview;
      const dataRes = await fetch(`${!isPreview ? '/api' : ''}/umbraco/forms/api/v1/definitions/${data.formId}`).catch(
        console.error,
      );
      if (dataRes && dataRes.ok) {
        const formData = await dataRes.json();
        setFormDefinition(formData);
      }
    }

    if (!isRobot) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.formId, isRobot]);

  return (
    <ModuleBase data={data} className="font-montserrat text-black">
      <UmbracoForm className="container" successClass="text-center py-10 text-h3" formDefinition={formDefinition} />
    </ModuleBase>
  );
};

export default FormModule;
