import React, { useEffect } from 'react';
import { ModuleBase, HeadingTag, Text, Link, Stack, Box } from '@/components';
import GoogleMapReact from 'google-map-react';
import MapPin from '@/assets/map-pin.svg';
import External from '@/assets/externalLink.svg';
import mapStyles from '@/public/mapStyles.json';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { fixColor, isColorDark } from '@/utils';

const MapSimpleModule = ({ data }) => {
  const isDark = isColorDark(fixColor(data.backgroundColour));
  const { t } = useTranslation('common');

  useEffect(() => {
    if (typeof window !== 'undefined' && data?.text) {
      const pElements = document.querySelectorAll('.map-text p');
      const additionalPElement = document.querySelector('.additional-map-text p:first-child');
      const anchorTags = document.querySelectorAll('.MapSimpleModule .gmnoprint a[target="_blank"]');

      pElements?.forEach((p) => {
        p.setAttribute('role', 'heading');
        p.setAttribute('aria-level', 7);
      });

      anchorTags.forEach((a) => {
        a.setAttribute('aria-label', `${t('general.$newWindowAriaLabel')}`);
      });

      additionalPElement?.setAttribute('role', 'heading');
      additionalPElement?.setAttribute('aria-level', 7);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.text]);

  return (
    <ModuleBase data={data}>
      <Stack
        id="map"
        className="container flex flex-col justify-between font-montserrat md:items-start lg:flex-row lg:items-center"
      >
        <div className="lg:max-w-1/2 w-full lg:max-w-[456px] lg:pr-03">
          {data.label && (
            <Text tag="p" className="color-from-bg label-01 mb-1.5 !text-opacity-50">
              {data.label}
            </Text>
          )}
          {data.title && (
            <HeadingTag
              animate
              data={data.title}
              className="title-04 color-from-bg mb-1.5 font-kepler md:mb-2 lg:mb-3"
            />
          )}
          {data.text && (
            <Text
              dangerouslySetInnerHTML={{ __html: data.text }}
              className="map-text color-from-bg body-02 xxl:body-03 [&_p:first-child]:mb-6"
            />
          )}
          {data.mapsLink && (
            <Link
              animate
              link={data.mapsLink}
              className={classNames(
                'btn secondary mb-8 mt-04 md:mt-3 lg:mb-[72px] lg:mt-3.5 [&:has(svg:nth-of-type(2))_svg:nth-of-type(2)]:hidden',
                isDark ? 'dark' : 'light',
              )}
            >
              <span>{t('general.$googleMaps')}</span>
              <External role="presentation" />
            </Link>
          )}
          {data.additionalText && (
            <Text
              dangerouslySetInnerHTML={{ __html: data.additionalText }}
              className="additional-map-text color-from-bg [&_p:first-child]:title-05 [&_p:not(:first-child)]:body-03 [&_p:not(:first-child)]:xxl:body-04 [&_p:first-child]:mb-[9px] md:[&_p:first-child]:mb-[13px] [&_p:not(:first-child)]:!opacity-75"
            />
          )}
          {(data.primaryCTA || data.secondaryCTA) && (
            <Stack className="mt-6 md:mt-[19px] lg:mt-8">
              {data.primaryCTA && (
                <Link
                  animate
                  link={data.primaryCTA}
                  className={classNames('btn primary mb-02', isDark ? 'dark' : 'light')}
                />
              )}
              {data.secondaryCTA && (
                <Link
                  animate
                  link={data.secondaryCTA}
                  className={classNames('btn secondary', isDark ? 'dark' : 'light')}
                />
              )}
            </Stack>
          )}
        </div>
        <Box className="mt-06 aspect-[343/267] w-full md:mt-05 md:aspect-[720/561] lg:mt-0 lg:h-[640px] lg:w-[822px]">
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_KEY }}
            defaultCenter={{
              lat: Number(data.latitude),
              lng: Number(data.longitude),
            }}
            defaultZoom={data.zoom}
            options={{
              styles: mapStyles,
            }}
          >
            <MapPin
              role="presentation"
              lat={Number(data.latitude)}
              lng={Number(data.longitude)}
              className="absolute -translate-x-1/2 -translate-y-full"
            />
          </GoogleMapReact>
        </Box>
      </Stack>
    </ModuleBase>
  );
};

export default MapSimpleModule;
