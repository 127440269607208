import { useDimensions } from '@/hooks';
import { getValueAtBreakpoint } from '@/utils';
import classNames from 'classnames';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { CarouselContext, CarouselControls, CarouselPagination, CarouselWrapper } from './chunks';

const carouselVariants = {
  variant: {
    primary: 'primary',
    focus: 'focus',
    bookcase: 'bookcase',
    fadeInAndScale: 'fade-in-and-scale',
    jagged: 'jagged',
    lightbox: 'lightbox',
  },
  size: {
    sm: 'sm',
    md: 'md',
    lg: 'lg',
  },
};

const Carousel = forwardRef(
  (
    {
      className,
      variant = 'primary',
      size = 'sm',
      items = [],
      loop = false,
      animationStyle = 'default',
      gap = 0,
      width: propsWidth = '100%',
      height: propsHeight,
      controls,
      showPagination = false,
      paginationType = 'dots',
      crop = false,
      columns = 1,
      isClickable = false,
      itemAnimationVariant = 'default',
      paginationStyle,
      inactiveWidth = '100%',
      inactiveHeight = '100%',
      current,
      setCurrent,
      moduleId,
      ...props
    },
    ref,
  ) => {
    // eslint-disable-next-line
    const [currItem, setCurrItem] = variant === 'lightbox' ? [current, setCurrent] : useState(0);
    const [htmlDir, setHtmlDir] = useState('ltr');
    // eslint-disable-next-line
    const carouselWrapperRef = ref || useRef();
    const { width, height, breakpoint } = useDimensions(carouselWrapperRef);

    const carouselWidth = getValueAtBreakpoint(propsWidth, breakpoint);
    const carouselHeight = propsHeight ? getValueAtBreakpoint(propsHeight, breakpoint) : null;
    const columnNum = getValueAtBreakpoint(columns, breakpoint);

    useEffect(() => {
      setHtmlDir(document.querySelector('html').getAttribute('dir'));

      window.addEventListener('languagechange', () => {
        setHtmlDir(document.querySelector('html').getAttribute('dir'));
      });

      return () => {
        window.removeEventListener('languagechange', () => {
          setHtmlDir(document.querySelector('html').getAttribute('dir'));
        });
      };
    }, []);

    if (items.length === 0) return null;

    // const allProps = {
    // //   ...carouselVars(variant, size, carouselWidth, carouselHeight, className),
    //   ...props,
    // };

    const carouselPages = Math.ceil(items.length / columnNum);

    return (
      <div
        ref={carouselWrapperRef}
        {...props}
        className={classNames(
          'carousel flex flex-row items-start',
          carouselVariants.variant[variant],
          carouselVariants.size[size],
          className,
          crop && 'overflow-hidden',
        )}
        style={{ width: carouselWidth, height: carouselHeight ? carouselHeight : {} }}
      >
        <CarouselContext.Provider
          value={{
            currItem,
            setCurrItem,
            isClickable,
            itemAnimationVariant,
            inactiveWidth,
            inactiveHeight,
            carouselWrapper: carouselWrapperRef.current,
            breakpoint,
            carouselWidth,
            width,
            htmlDir,
            moduleId,
          }}
        >
          <CarouselWrapper
            items={items}
            gap={gap}
            dragWidth={width}
            dragHeight={height}
            columnNum={columnNum}
            animationStyle={animationStyle}
            crop={crop}
            loop={loop}
            variant={variant}
          />
          {carouselPages > 1 && (
            <CarouselControls controls={controls} length={carouselPages} width={width} loop={loop} />
          )}
          {showPagination && carouselPages > 1 && (
            <CarouselPagination
              length={carouselPages}
              paginationType={paginationType}
              paginationStyle={paginationStyle}
              loop={loop}
            />
          )}
        </CarouselContext.Provider>
      </div>
    );
  },
);

Carousel.displayName = 'Carousel';

export default Carousel;
