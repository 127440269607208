import { ModuleBase, Link, ResponsiveImage, Text } from '@/components';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { isColorDark } from '@/utils';
import classNames from 'classnames';
import { setFooterProgress, wrapper } from '@/store';
import { useMotionValueEvent, useScroll } from 'framer-motion';

const FooterVertical = ({ data, ...rest }) => {
  const containerRef = useRef(null);
  const footerRef = useRef(null);
  const [logoClass, setLogoClass] = useState();
  const [logoWrapperSize, setLogoWrapperSize] = useState();
  const isDark = isColorDark(data.footerColour);
  const { store } = wrapper.useWrappedStore(rest);
  const { scrollYProgress } = useScroll({ target: footerRef, offset: ['start end', 'end end'] });

  useMotionValueEvent(scrollYProgress, 'change', (latest) => {
    store.dispatch(setFooterProgress(latest));
  });

  useEffect(() => {
    if (isIOS) {
      setLogoClass('!object-contain');
      setLogoWrapperSize('w-[130px] xl:w-[unset] xl:max-w-[130px]');
    } else {
      setLogoClass('!object-contain');
      setLogoWrapperSize('max-w-[130px]');
    }
  }, []);

  useEffect(() => {
    if (containerRef.current && data?.contactDetails?.description) {
      const tempContainer = document.createElement('div');
      tempContainer.innerHTML = data?.contactDetails?.description;
      const pElements = tempContainer.querySelectorAll('p');
      const anchorTags = tempContainer.querySelectorAll('a');

      pElements.forEach((p) => {
        if (p.innerHTML.trim() === '&nbsp;') {
          p.classList.add('nb-space-target');
        }
      });

      anchorTags.forEach((a) => {
        a.setAttribute('role', 'button');
      });

      containerRef.current.innerHTML = tempContainer.innerHTML;
    }
  }, [data?.contactDetails?.description]);
  return (
    <ModuleBase
      data={data}
      style={{ backgroundColor: data.footerColour }}
      className="font-montserrat"
      role="contentinfo"
      aria-label="footer"
      hTag="footer"
    >
      <div
        ref={footerRef}
        className={classNames('container pb-6 pt-12 md:pt-14 lg:pt-16', isDark ? 'text-silk' : 'text-charcoal')}
      >
        <div className="main-logo-wrapper md:flex md:items-center md:justify-between">
          <div className={`logo mx-auto h-14 ${logoWrapperSize} md:mx-0 md:flex md:items-center`}>
            <ResponsiveImage
              image={data.footerLogo}
              widths={{ xs: 120, md: 130, lg: 130, xl: 130, xxl: 130 }}
              imgProps={{ className: `!object-contain ${isDark ? '' : 'invert'}` }}
            />
          </div>

          <div className="secondary-logo-wrapper hidden md:flex md:items-center md:gap-x-10">
            {data?.secondaryLogos?.map((secLogo, secIndex) => (
              <div className={`logo mx-auto h-14 ${logoWrapperSize}`} key={secIndex}>
                {secLogo?.link && (
                  <Link link={secLogo?.link}>
                    <ResponsiveImage
                      image={secLogo?.logo}
                      widths={{ xs: 180, md: 130, lg: 130, xl: 130, xxl: 130 }}
                      imgProps={{ className: `${logoClass} ${isDark ? '' : 'invert'}` }}
                    />
                  </Link>
                )}
                {secLogo?.link === null && (
                  <ResponsiveImage
                    image={secLogo?.logo}
                    widths={{ xs: 180, md: 130, lg: 130, xl: 130, xxl: 130 }}
                    imgProps={{ className: `${logoClass} ${isDark ? '' : 'invert'}` }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="secondary-logo-wrapper mt-07 flex items-center justify-center gap-x-10 md:hidden">
          {data?.secondaryLogos?.map((secLogo, secIndex) => (
            <div className={`logo h-14 ${logoWrapperSize} overflow-hidden`} key={secIndex}>
              {secLogo?.link && (
                <Link link={secLogo?.link}>
                  <ResponsiveImage
                    image={secLogo?.logo}
                    widths={{ xs: 130, md: 130, lg: 130, xl: 130, xxl: 130 }}
                    imgProps={{ className: `${logoClass} ${isDark ? '' : 'invert'}` }}
                  />
                </Link>
              )}
              {secLogo?.link === null && (
                <ResponsiveImage
                  image={secLogo?.logo}
                  widths={{ xs: 130, md: 130, lg: 130, xl: 130, xxl: 130 }}
                  imgProps={{ className: `${logoClass} ${isDark ? '' : 'invert'}` }}
                />
              )}
            </div>
          ))}
        </div>

        <div className={classNames('my-8 h-px opacity-25 lg:my-10', isDark ? 'bg-silk' : 'bg-charcoal')}></div>

        <div className="main-data-wrapper">
          <div className="flex flex-col gap-y-8 md:flex-row md:flex-wrap md:justify-between lg:flex-nowrap">
            {data?.linkColumns?.map((columnItem, columnIndex) => (
              <div
                className="columns w-full text-left last:mb-0 md:mb-0 md:w-[49%] lg:w-[30%] rtl:text-right"
                key={columnIndex}
              >
                <Text tag="p" className="body-03 mb-3 font-semibold">
                  {columnItem?.columnHeading}
                </Text>

                {columnItem?.links?.map((innerColumntItem, innerColumntIndex) => (
                  <div className="inner-column-links mb-2 last:mb-0" key={innerColumntIndex}>
                    <Link link={innerColumntItem} className="body-03 font-normal opacity-75">
                      {innerColumntItem?.text}
                    </Link>
                  </div>
                ))}
              </div>
            ))}

            <div
              className={classNames(
                'columns w-full text-left last:mb-0 md:mb-0 md:w-[49%] rtl:text-right',
                isDark ? 'text-silk' : 'text-charcoal',
              )}
            >
              <Text tag="p" className="body-03 mb-3 font-semibold">
                {data?.contactUsLabel}
              </Text>

              {data?.contactDetails && (
                <div className="inner-column-links last:mb-0">
                  {data?.contactDetails && (
                    <>
                      <Text tag="p" className="interactive-01 mb-3 opacity-75">
                        {data?.contactDetails?.headingTitle}
                      </Text>
                      <Text
                        ref={containerRef}
                        tag=""
                        className="contact-details body-03 font-normal opacity-75 [&_.nb-space-target]:h-2"
                        dangerouslySetInnerHTML={{
                          __html: data?.contactDetails?.description,
                        }}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className={classNames(
            'bottom-data mt-14 md:flex md:items-center md:justify-between',
            isDark ? 'text-silk' : 'text-charcoal',
          )}
        >
          <div className="mb-2 text-center opacity-50 md:mb-0 md:text-left">
            <p className="font-montserrat text-xs font-light">{data.copyrightText}</p>
          </div>

          <div className="bottom-links flex justify-center gap-x-4">
            {data.bottomLinks.map((bottomLinkItem, bottomLinkIndex) => (
              <div className="bottom-link-item opacity-75" key={bottomLinkIndex}>
                <Link link={bottomLinkItem} className="font-montserrat text-xs font-light" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </ModuleBase>
  );
};

export default FooterVertical;

FooterVertical.propTypes = {
  data: PropTypes.any.isRequired,
};
