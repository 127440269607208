import { ModuleBase, Link, HeadingTag } from '@/components';
import { isColorDark } from '@/utils';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useTranslation } from 'next-i18next';

const RichTextModule = ({ data }) => {
  const backgroundColour = '#fff2cc';
  const isDark = isColorDark(backgroundColour);
  const { headingTitle, preHeading, primaryCTA, secondaryCTA } = data || {};
  const { t } = useTranslation('common');

  data.content = data.content.replace(/<p>(<img.*?>)<\/p>/g, '<p class="has-image">$1</p>');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const aTags = document.querySelector('.richtext-content').querySelectorAll('a[target="_blank"]');

      aTags.forEach((a) => {
        const currentAriaLabel = a.getAttribute('aria-label');

        if (currentAriaLabel) {
          a.setAttribute('aria-label', `${currentAriaLabel} (${t('general.$newWindowAriaLabel')})`);
        } else {
          a.setAttribute('aria-label', `${t('general.$newWindowAriaLabel')}`);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inView = {
    inactive: { opacity: 0 },
    active: {
      opacity: 1,
      transition: {
        duration: 0.1,
        type: 'tween',
        bounce: 0,
        when: 'beforeChildren',
        staggerChildren: 0.2,
      },
    },
  };

  const moveUpVariants = {
    inactive: { opacity: 0, y: 50 },
    active: { opacity: 1, y: 0, transition: { duration: 0.8, type: 'spring', damping: 30, stiffness: 120 } },
  };

  return (
    <ModuleBase data={data} className={'py-10 md:py-14 lg:p-28'}>
      <motion.div
        variants={inView}
        initial="inactive"
        whileInView="active"
        viewport={{ once: true }}
        className="container max-w-[994px]"
      >
        {preHeading && (
          <motion.div variants={moveUpVariants} className="label-01 my-4 font-montserrat !opacity-50">
            {preHeading}
          </motion.div>
        )}
        {headingTitle && (
          <motion.div variants={moveUpVariants}>
            <HeadingTag data={headingTitle} className="title color-from-bg title-04 my-7 font-montserrat" />
          </motion.div>
        )}

        <motion.div
          variants={moveUpVariants}
          dangerouslySetInnerHTML={{ __html: data.content }}
          className="richtext-content color-from-bg"
        />

        {(primaryCTA || secondaryCTA) && (
          <motion.div variants={moveUpVariants} className="mt-8 flex gap-2 md:flex-row lg:mt-16 lg:items-center">
            <Link className={classNames('btn px-0 py-2', isDark ? 'dark' : 'light')} link={data.primaryCTA} />
            <Link className={classNames('btn px-0 py-2', isDark ? 'dark' : 'light')} link={data.secondaryCTA} />
          </motion.div>
        )}
      </motion.div>
    </ModuleBase>
  );
};
export default RichTextModule;
