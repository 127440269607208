import Arrow from '@/assets/porpertyArrow.svg';
import { isColorDark } from '@/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link, ResponsiveImage } from '..';

const PropertyCard = ({ data, className, backgroundColour, ...props }) => {
  const isDark = isColorDark(backgroundColour);
  data.description = data.description.replace(/<pre/g, '<p').replace(/<\/pre>/g, '</p>');

  return (
    <div
      {...props}
      className={classNames(
        className,
        'main-wrapper flex h-[416px] flex-col px-4 sm:px-6 md:h-[192px] md:flex-row md:gap-x-8 lg:h-[222px] lg:gap-x-10 lg:px-10',
      )}
    >
      <div className="absolute ml-[10px] mt-[10px] hidden items-center justify-center gap-[6px] rounded-sm bg-coastal px-[12px] py-[5px] text-silk lg:flex">
        {data.tag && (
          <>
            <div className="flex w-4 items-center justify-center">
              <ResponsiveImage
                image={data.tag.logo}
                className="h-full w-full object-cover"
                widths={{ xs: 16, md: 16, lg: 16 }}
                heights={{ xs: 16, md: 16, lg: 16 }}
              />
            </div>
            <p className={classNames('mt-[3px] font-montserrat text-[10px] font-[500] capitalize leading-3')}>
              {data.tag.displayName}
            </p>
          </>
        )}
      </div>
      <div className="aspect-[192/192] h-[213px] w-full md:h-[unset] md:w-[unset] lg:aspect-[334/222] lg:h-[222px] lg:w-[334px]">
        {data.image && (
          <ResponsiveImage
            image={data.image}
            widths={{ xs: 645, md: 288, lg: 1089 }}
            heights={{ xs: 320, md: 288, lg: 597 }}
          />
        )}
      </div>

      <div className="content-wrapper mt-5 flex h-[180px] grow flex-col justify-between md:mt-0 md:h-full md:w-min">
        <div className={classNames('text-wrapper flex grow flex-col gap-5 md:gap-7 lg:gap-9')}>
          <div className="title-wrapepr flex items-center justify-between">
            <div>
              {data.label && <p className={classNames('label-01 color-from-bg mb-1 opacity-50')}>{data.label}</p>}
              {data.title && (
                <p
                  className={classNames('title-05 color-from-bg xxl:text-[24px] xxl:leading-8')}
                  role="heading"
                  aria-level="7"
                >
                  {data.title}
                </p>
              )}
            </div>

            {data.link && (
              <div className="mt-auto !h-[35px] w-[60px] lg:hidden">
                <Link
                  className={classNames('btn control !h-[35px] !w-[60px] p-0', isDark ? 'light' : 'dark')}
                  link={data.link}
                >
                  <Arrow className="" />
                </Link>
              </div>
            )}
          </div>

          {data.details && data.details.length > 0 && (
            <div
              className={classNames(
                'property-details flex border-y py-2 md:w-[262px] md:gap-x-10 md:pr-4 xl:flex',
                isDark ? 'border-y-silk/25' : 'border-y-charcoal/25',
              )}
            >
              {data?.details?.map((detail, index) => (
                <div className="flex w-full items-center gap-4" key={index}>
                  <p className={classNames('label-01', isDark ? 'text-silk/50' : 'text-charcoal/50')}>
                    {detail.detailElementLabel}
                  </p>
                  <p className={classNames('body-03 text-charcoal', isDark ? 'text-silk' : 'text-charcoal')}>
                    {detail.detailElementValue}
                  </p>
                </div>
              ))}
            </div>
          )}
          <div className="lg:flex lg:justify-between lg:gap-4">
            {data.description && (
              <div
                className={classNames(
                  'body-04 lg:body-03 line-clamp-4 lg:line-clamp-2 lg:w-[90%] lg:max-w-[590px]',
                  isDark ? 'text-silk/75' : 'text-charcoal/75',
                )}
                dangerouslySetInnerHTML={{ __html: data.description }}
              />
            )}
            {data.link && (
              <Link
                className={classNames(
                  'btn control mt-auto hidden !h-8 !w-16 px-5 lg:flex lg:!h-[44px] lg:!w-[76px]',
                  isDark ? 'light' : 'dark',
                )}
                link={data.link}
              >
                <Arrow className="" />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PropertyCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PropertyCard;
