import React, { useEffect } from 'react';
import { HeadingTag, ModuleBase, Stack, Text, Box, UmbracoForm } from '@/components';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

const NewsletterModule = ({ data }) => {
  const { title, text } = data || {};
  const { t } = useTranslation('common');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const aTags = document.querySelector('.consent-label').querySelectorAll('a[target="_blank"]');

      aTags.forEach((a) => {
        const currentAriaLabel = a.getAttribute('aria-label');

        if (currentAriaLabel) {
          a.setAttribute('aria-label', `${currentAriaLabel} (${t('general.$newWindowAriaLabel')})`);
        } else {
          a.setAttribute('aria-label', `${t('general.$newWindowAriaLabel')}`);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputBackgroundClass =
    data.backgroundColour === '#f4f2ef' ? '[&_.input]:!bg-sandstone/60' : '[&_.input]:!bg-silk/60';

  return (
    <ModuleBase
      data={{ ...data, paddingTop: 'none', paddingBottom: 'none' }}
      className="relative overflow-hidden py-8 sm:py-10 lg:py-22"
    >
      <Stack className="px-s container flex flex-col">
        <div className="mb-8 flex flex-col gap-2 md:w-[450px] lg:mb-16 lg:gap-4">
          {title && <HeadingTag animate data={title} className="color-from-bg title-04" />}
          {text && (
            <Text
              dangerouslySetInnerHTML={{ __html: text }}
              className={classNames('color-from-bg body-03 !opacity-75')}
            ></Text>
          )}
        </div>
        <Box>
          <UmbracoForm
            formDefinition={data?.formFields}
            thankYouHeading={data?.thankYouHeading}
            thankYouMessage={data?.thankYouMessage}
            backgroundColour={data?.backgroundColour}
            submitBtnClass="btn secondary"
            className={classNames(
              '[&_.fieldset-inner]:flex-col md:[&_.fieldset-inner]:flex-row md:[&_.fieldset-inner]:flex-wrap md:[&_.fieldset-inner]:justify-between',
              '[&_.column]:mb-4 last:[&_.column]:mb-0',
              'md:[&_.column]:w-[49%] md:last:[&_.column]:w-full',
              'lg:[&_.column]:!w-[32.5%] lg:[&_.fieldset-inner]:w-full lg:[&_.form-inner]:w-full lg:[&_form]:flex lg:[&_form]:w-full',
              'lg:[&_.btn]:h-12 [&_.button-wrapper]:justify-start lg:[&_.button-wrapper]:mt-0 lg:[&_.button-wrapper]:h-12 lg:[&_.button-wrapper]:items-end lg:[&_.button-wrapper]:justify-start lg:ltr:[&_.button-wrapper]:ml-4 lg:rtl:[&_.button-wrapper]:mr-4',
              'lg:[&_input]:h-12',
              '[&_.label]:hidden',
              inputBackgroundClass,
            )}
            successClass={
              'h-full text-center px-4 w-full absolute top-0 left-0 bg-white flex flex-col justify-center items-center'
            }
          />
        </Box>

        <Text
          className="consent-label color-from-bg mt-9 font-montserrat text-[12px] leading-4 tracking-[0.12px] lg:mt-[22px] [&_a]:font-semibold"
          dangerouslySetInnerHTML={{ __html: t('forms.newsletterform.$newsletterformdataConsentLabel') }}
        ></Text>
      </Stack>
    </ModuleBase>
  );
};
export default NewsletterModule;
