import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Dropdown from './Dropdown';
import Datepicker from './Datepicker';
import NumberInput from './NumberInput';
import RadioGroupInput from './RadioGroupInput';
import MultiCheckbox from './MultiCheckbox';
import FormTitleAndDescription from './FormTitleAndDescription';
import PhoneInput from './PhoneInput';
import ChildInput from './ChildInput';
import TextArea from './TextArea';

import { Box } from '..';

const FormControl = ({
  id,
  type,
  label,
  name,
  error,
  options,
  selectPrompt,
  popoverProps,
  required,
  settings,
  motion,
  variant = '',
  animate = false,
  className,
  formDefinitionName,
  fixedName,
  ...props
}) => {
  let realType = null;
  if (name === 'noOfChildren') {
    realType = 'childinput';
  } else if (['text', 'Short answer'].includes(type) && settings?.fieldType === 'number') {
    realType = 'number';
  } else if (['text', 'Short answer'].includes(type) && settings?.fieldType === 'date') {
    realType = 'date';
  } else if (['text', 'Short answer'].includes(type) && settings?.fieldType === 'tel') {
    realType = 'phoneInput';
  } else if (['text', 'Short answer'].includes(type)) {
    realType = 'text';
  } else if (['checkbox', 'Data Consent'].includes(type)) {
    realType = 'checkbox';
  } else if (['select', 'Dropdown'].includes(type)) {
    realType = 'dropdown';
  } else if (['textarea', 'Long answer'].includes(type)) {
    realType = 'textarea';
  } else if (['datepicker', 'Date'].includes(type)) {
    realType = 'datepicker';
  } else if (['number'].includes(type)) {
    realType = 'number';
  } else if (['Hidden'].includes(type)) {
    realType = 'hidden';
  } else if (['radio', 'Single choice'].includes(type)) {
    realType = 'radio';
  } else if (['multicheckbox', 'Multiple choice'].includes(type)) {
    realType = 'multicheckbox';
  } else if (['Title and description'].includes(type)) {
    realType = 'titleAndDescription';
  } else if (['reCAPTCHA v3 with score'].includes(type)) {
    realType = 'recaptcha';
  }

  let input = null;
  if (['text', 'checkbox'].includes(realType)) {
    input = (
      <input
        id={id}
        type={realType}
        className="input"
        name={name}
        aria-label={props.placeholder}
        aria-required={required}
        {...props}
      />
    );
  } else if (realType === 'dropdown') {
    input = (
      <Dropdown
        id={name}
        className="input dropdown"
        name={name}
        options={options}
        displayEmpty={!required}
        required={required}
        {...props}
        placeholder={selectPrompt || props.placeholder}
        popoverProps={popoverProps}
      />
    );
  } else if (realType === 'textarea') {
    input = (
      <TextArea
        id={name}
        type={realType}
        settings={settings}
        className="input"
        name={name}
        rows={settings?.numberOfRows || 3}
        {...props}
      />
    );
  } else if (realType === 'datepicker') {
    input = <Datepicker id={name} className="input" name={name} {...props} popoverProps={popoverProps} />;
  } else if (realType === 'number') {
    input = (
      <NumberInput
        id={name}
        className="input"
        type={realType}
        name={name}
        defaultValue={settings?.defaultValue !== '' ? settings?.defaultValue : 0}
        {...props}
      />
    );
  } else if (realType === 'date') {
    input = <Datepicker id={name} className="input" name={name} minDate={new Date()} {...props} />;
  } else if (realType === 'hidden') {
    input = <input id={name} type={realType} className="input" name={name} {...props} />;
  } else if (realType === 'radio') {
    input = <RadioGroupInput options={options} required={required} settings={settings} name={name} {...props} />;
  } else if (realType === 'multicheckbox') {
    input = (
      <MultiCheckbox
        options={options}
        required={required}
        settings={settings}
        name={name}
        formDefinitionName={formDefinitionName}
        fixedName={fixedName}
        {...props}
      />
    );
  } else if (realType === 'titleAndDescription') {
    input = <FormTitleAndDescription settings={settings} name={name} {...props} />;
  } else if (realType === 'recaptcha') {
    input = <div name={name} className="g-recaptcha" settings={settings} {...props} data-size="invisible"></div>;
  } else if (realType === 'phoneInput') {
    input = <PhoneInput id={name} className="input" name={name} {...props} />;
  } else if (realType === 'childinput') {
    input = (
      <ChildInput
        id={name}
        name={name}
        required={required}
        {...props}
        placeholder={selectPrompt || props.placeholder}
        popoverProps={popoverProps}
      />
    );
  }

  const labelElement = label && realType !== 'hidden' && (
    <label htmlFor={realType === 'checkbox' ? id : name} className="label">
      {label}
    </label>
  );

  let inputElement;

  if (realType) {
    if (realType === 'checkbox') {
      inputElement = (
        <div className="checkbox">
          {input} {labelElement}
        </div>
      );
    } else {
      inputElement = (
        <>
          {labelElement} {input}
        </>
      );
    }
  }
  return (
    <Box
      motion={motion}
      animate={animate}
      className={classnames(
        'input-group',
        `field-${name}`,
        className,
        { error },
        (realType !== 'textarea' || realType !== 'checkbox') && variant,
      )}
    >
      {inputElement || (
        <div>
          Input type <b>{type}</b> not implemented
        </div>
      )}
      {error && <div className="error-message">{error}</div>}
    </Box>
  );
};

FormControl.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'checkbox',
    'textarea',
    'select',
    'datepicker',
    'number',
    'radio',
    'multicheckbox',
    'Short answer',
    'Data Consent',
    'Single choice',
    'Long answer',
    'Date',
    'Dropdown',
    'Multiple choice',
  ]).isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  settings: PropTypes.object,
  motion: PropTypes.object,
  animate: PropTypes.bool,
  onChange: PropTypes.func,
};

export default FormControl;
