/** @type {import('tailwindcss').Config} */

module.exports = {
  content: ['./pages/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}', './modules/**/*.{js,ts,jsx,tsx}'],
  theme: {
    language: ['en', 'fr', 'ru', 'ar'],
    container: {
      center: true,
      screens: {
        xxl: '1600px',
      },
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      xxl: '1536px',
      shortDesktop: { raw: '(max-height: 800px) and (min-width: 1024px)' },
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      black: '#000000',
      white: '#ffffff',
      charcoal: '#373635',
      silk: '#F4F2EF',
      sandstone: '#E9E2DE',
      cashmere: '#ADA298',
      mountain: '#423E32',
      bone: '#ECE3DE',
      primary: '#F4F2EF',
      coastal: '#383E47',
      urban: '#645D5C',

      grey1: '#323232',
      grey2: '#505050',
      grey3: '#828282',
      grey4: '#BEBEBE',
      grey5: '#DCDCDC',
      grey6: '#F0F0F0',
      messagingRed: '#ff5a5a',
    },
    extend: {
      animation: {
        grow: 'grow 0.7s forwards',
        shrink: 'shrink 0.5s forwards',
      },

      backgroundImage: {
        overlay: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0.2018382352941176) 0%)',
        overlayLeftToRight: 'linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.5074404761904762) 100%)',
      },
      boxShadow: {
        input: '0px 11px 24px 0px rgba(0, 0, 0, 0.10)',
      },
      keyframes: {
        grow: {
          '0%': { transform: 'scale(0)' },
          '50%': { transform: 'scale(1.2)' },
          '100%': { transform: 'scale(1)' },
        },
        shrink: {
          '0%': { transform: 'scale(1)' },
          '100%': { transform: 'scale(0)' },
        },
      },
      content: {
        externalLink: 'url("../assets/externalLink.svg")',
        phone: 'url("../assets/phone.svg")',
        mail: 'url("../assets/mail.svg")',
        guest: 'url("../assets/guest.svg")',
        calendar: 'url("../assets/calendar.svg")',
        checkbox: 'url("../assets/checkbox.svg")',
        checkboxChecked: 'url("../assets/checkbox-checked.svg")',
        search: 'url("../assets/searchBlack.svg")',
      },
      spacing: {
        '01': 'var(--spacing-01)',
        '02': 'var(--spacing-02)',
        '03': 'var(--spacing-03)',
        '04': 'var(--spacing-04)',
        '05': 'var(--spacing-05)',
        '06': 'var(--spacing-06)',
        '07': 'var(--spacing-07)',
        '08': 'var(--spacing-08)',
        '09': 'var(--spacing-09)',
        22: '88px',
      },
      letterSpacing: {
        'body-01': 'var(--letter-spacing-body-01)',
        'body-02': 'var(--letter-spacing-body-02)',
        'body-03': 'var(--letter-spacing-body-03)',
        'body-04': 'var(--letter-spacing-body-04)',

        'interactive-01': 'var(--letter-spacing-interactive-01)',

        'label-01': 'var(--letter-spacing-label-01)',

        tc: '0.12px',
        heading1: '0.72px',
        heading1m: '0.48px',
        heading6: '0.22px',
      },
      transitionProperty: {
        width: 'width',
        top: 'top',
      },
      aspectRatio: {
        '9/16': '9 / 16',
        '8/10': '8 / 10',
        '1/1': '1 / 1',
        '6/5': '6 / 5',
        '4/3': '4 / 3',
        '3/2': '3 / 2',
        '16/9': '16 / 9',
        '11/5': '11 / 5',
        '13/5': '13 / 5',

        '3/4': '3 / 4',
        '8/5': '8 / 5',
        '7/6': '7 / 6',
        '6/7': '6 / 7',
      },
      fontFamily: {
        kepler: ['"Kepler Std"', 'Times', 'serif', 'system-ui', '-apple-system'],
        'kepler-display': ['"Kepler Std Display"', 'Times', 'serif', 'system-ui', '-apple-system'],
        montserrat: ['Montserrat', 'Arial', 'Helvetica', 'ui-sans-serif', 'system-ui', '-apple-system'],
      },
      fontSize: {
        'title-01': 'var(--font-size-title-title-01)',
        'title-02': 'var(--font-size-title-title-02)',
        'title-03': 'var(--font-size-title-title-03)',
        'title-04': 'var(--font-size-title-title-04)',
        'title-05': 'var(--font-size-title-title-05)',

        'body-01': 'var(--font-size-body-body-01)',
        'body-02': 'var(--font-size-body-body-02)',
        'body-03': 'var(--font-size-body-body-03)',
        'body-04': 'var(--font-size-body-body-04)',

        'interactive-01': 'var(--font-size-interactive-interactive-01)',
        'interactive-02': 'var(--font-size-interactive-interactive-02)',

        'label-01': 'var(--font-size-label-label-01)',

        btn: ['12px', '20px'],
        heading2: '48px',
        heading4: '28px',
        heading5: '26px',
        heading6: '22px',
        'xl-paragraph': '18px',
        'large-paragraph': '16px',
        paragraph: '14px',
        'small-paragraph': '12px',
        'small-caption': '10px',
      },
      lineHeight: {
        'title-01': 'var(--font-line-height-title-title-01)',
        'title-02': 'var(--font-line-height-title-title-02)',
        'title-03': 'var(--font-line-height-title-title-03)',
        'title-04': 'var(--font-line-height-title-title-04)',
        'title-05': 'var(--font-line-height-title-title-05)',

        'body-01': 'var(--font-line-height-body-body-01)',
        'body-02': 'var(--font-line-height-body-body-02)',
        'body-03': 'var(--font-line-height-body-body-03)',
        'body-04': 'var(--font-line-height-body-body-04)',

        'interactive-01': 'var(--font-line-height-interactive-01)',
        'interactive-02': 'var(--font-line-height-interactive-02)',

        'label-01': 'var(--font-line-height-label-label-01)',

        heading1: '96px',
        heading1m: '64px',
        heading2: '52px',
        heading3: '40px',
        heading5: '32px',
        heading6: '30px',
        'xl-paragraph': '24px',
        paragraph: '20px',
      },
    },
  },
  plugins: [
    require('tailwind-scrollbar-hide'),
    require('tailwind-scrollbar'),
    require('@ganmahmud/tailwindcss-language-variant'),
  ],
};
