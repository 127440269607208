import PropTypes from 'prop-types';
import { ResponsiveImage, Link } from '..';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

const ArticleFeatureCard = ({ data, className, ...props }) => {
  const { t } = useTranslation('common');

  return (
    <div
      {...props}
      className={classNames(
        className,
        'main-wrapper flex h-[387px] flex-col bg-silk md:h-[397px] lg:h-[528px] lg:flex-row',
      )}
    >
      <div className="aspect-[16/9] max-h-[192px] w-full overflow-hidden md:max-h-[196px] lg:max-h-[528px] lg:max-w-[970px]">
        {data.image && (
          <ResponsiveImage
            image={data.image}
            widths={{ xs: 645, md: 534, lg: 1455 }}
            heights={{ xs: 288, md: 288, lg: 792 }}
          />
        )}
      </div>

      <div className="content-wrapper flex h-[190px] grow flex-col justify-between p-4 lg:h-full lg:w-[414px] xl:px-4 xl:py-6">
        <div className={classNames('text-wrapper flex grow flex-col xl:justify-between')}>
          <div>
            {data.label && <p className="label-01 text-charcoal/50">{data.label}</p>}
            {data.title && (
              <div
                className="title-05 mb-3 mt-1 text-charcoal md:mb-5 xl:my-1 xl:text-[24px] xl:leading-8"
                dangerouslySetInnerHTML={{ __html: data.title }}
                role="heading"
                aria-level="7"
              ></div>
            )}
          </div>

          {data.link && (
            <div className="lg:mt-auto">
              {data.description && (
                <div
                  className="body-04 lg:body-03 line-clamp-3 opacity-75 lg:mb-8"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                />
              )}
              <Link className="btn secondary hidden w-fit lg:flex" link={data.link}>
                {t('general.$viewMore')}
              </Link>
              <Link className="btn tertiary lg:hidden" link={data.link}>
                {t('general.$viewMore')}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ArticleFeatureCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ArticleFeatureCard;
