import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const VideoPlayer = forwardRef(function VideoPlayer(
  // eslint-disable-next-line no-unused-vars
  { media, cover, onAutoPlayStarted, muted, ...props },
  ref,
) {
  let player = useRef();

  useImperativeHandle(ref, () => {
    return {
      openFullscreen() {
        if ((media.allowFullScreen === undefined || media.allowFullScreen === true) && player.current) {
          if (player.current.requestFullscreen) {
            player.current.requestFullscreen();
          } else if (player.current.webkitRequestFullscreen) {
            /* Safari */
            player.current.webkitRequestFullscreen();
          } else if (player.current.msRequestFullscreen) {
            /* IE11 */
            player.current.msRequestFullscreen();
          }
          player.current.play();
        }
      },
      play() {
        player.current?.play();
      },
      pause() {
        player.current?.pause();
      },
    };
  }, [media]);

  const onPlay = () => {
    if (media.autoPlay && onAutoPlayStarted) {
      onAutoPlayStarted();
    }
  };

  if (!media) {
    return null;
  }

  return (
    <video
      className={classNames('video absolute left-0 top-0 h-full w-full', cover && 'object-cover')}
      ref={player}
      autoPlay={media.autoPlay}
      muted={media.autoPlay ? true : muted}
      loop={media.loop}
      onPlay={onPlay}
      {...props}
    >
      <source src={media.videoFromGallery} type="video/mp4" />
      Your browser does not support HTML5 video.
    </video>
  );
});

VideoPlayer.propTypes = {
  media: PropTypes.object.isRequired,
  cover: PropTypes.bool,
  controls: PropTypes.bool,
};

export default VideoPlayer;
