import { ModuleBase, Link, HeadingTag, ResponsiveImage } from '@/components';
import classNames from 'classnames';

const CTabreakWithLogoModule = ({ data }) => {
  return (
    <ModuleBase data={data} className="font-montserrat">
      <div className="main-wrapper container flex flex-col items-center gap-6 md:flex-row md:justify-between lg:gap-10">
        <div className="logo h-[99px] w-full max-w-[99px] md:h-[120px] md:w-[20%] md:max-w-[120px]">
          <ResponsiveImage image={data.image} widths={{ xs: 99, sm: 99, md: 120, lg: 120, xl: 120, xxl: 120 }} />
        </div>

        <div className="flex w-full flex-col md:w-full lg:flex-row lg:justify-between">
          <div
            className={classNames(
              'flex flex-col gap-4 lg:w-[65%]',
              data.primaryCTA || data.secondaryCTA ? 'mb-10 lg:mb-0' : 'mb-0',
            )}
          >
            {data.preHeading && (
              <p className="text-subheading uppercase text-grey3 md:text-paragraph">{data.preHeading}</p>
            )}

            {data.headingTitle && <HeadingTag data={data.headingTitle} className="text-h2"></HeadingTag>}

            {data.text && <div dangerouslySetInnerHTML={{ __html: data.text }} className="text-paragraph"></div>}
          </div>

          {(data.primaryCTA || data.secondaryCTA) && (
            <div className="flex flex-row justify-end gap-4 lg:items-center">
              <Link className="btn primary" link={data.primaryCTA} />
              <Link className="btn secondary" link={data.secondaryCTA} />
            </div>
          )}
        </div>
      </div>
    </ModuleBase>
  );
};

export default CTabreakWithLogoModule;
