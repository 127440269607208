import { motion } from 'framer-motion';
import { transition } from './transition';

const AnimationStarter = ({ children, delayChildren, staggerChildren, ...props }) => {
  return (
    <motion.div
      variants={{
        inactive: { opacity: 0 },
        active: {
          opacity: 1,
          transition: {
            ...transition,
            delayChildren: delayChildren || 0.4,
            staggerChildren: staggerChildren || 0.3,
          },
        },
      }}
      initial="inactive"
      whileInView="active"
      viewport={{ once: true }}
      {...props}
    >
      {children}
      {staggerChildren}
      {delayChildren}
    </motion.div>
  );
};

export default AnimationStarter;
