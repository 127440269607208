export const modalWrapper = {
  initial: 'inactive',
  animate: 'active',
  exit: 'inactive',
  variants: {
    inactive: {
      opacity: 0,
    },
    active: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  },
};

export const modalBackdrop = {
  variants: {
    inactive: {
      opacity: 0,
    },
    active: {
      opacity: 1,

      transition: {
        type: 'spring',
        stiffness: 200,
        damping: 50,
      },
    },
  },
  exit: {
    opacity: 0,

    transition: {
      damping: 40,
      stiffness: 200,
      type: 'spring',
    },
  },
};

export const modalController = {
  variants: {
    inactive: {
      opacity: 0,
      scale: 0.9,
    },
    active: {
      opacity: 1,
      scale: 1,
      transition: {
        type: 'spring',
        stiffness: 200,
        damping: 50,
      },
    },
  },
  exit: {
    opacity: 0,
    scale: 0.9,
    transition: {
      damping: 40,
      stiffness: 200,
      type: 'spring',
    },
  },
};
