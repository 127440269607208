import { ModuleBase, ResponsiveImage, Link, Text, HeadingTag, Box } from '@/components';
import classNames from 'classnames';

const IconListWithRichTextModule = ({ data }) => {
  const { desktopLayoutAlignment, mobileLayoutAlignment, iconListElements } = data;
  const { headingTitle, description, preHeading, subHeading, primaryCTA, secondaryCTA } = data || {};
  return (
    <ModuleBase data={data} className="container font-montserrat text-black">
      <div
        className={classNames(
          'flex lg:items-center',
          mobileLayoutAlignment === 'Top'
            ? 'flex-col gap-14 md:gap-20 lg:gap-32'
            : 'flex-col-reverse gap-1 md:gap-8 lg:gap-32',
          desktopLayoutAlignment === 'Left' ? 'lg:flex-row' : 'lg:flex-row-reverse',
        )}
      >
        <div className="text-wrapper lg:w-[70%]">
          <div className="title-wrapper" role="contentinfo" aria-label="contentinfo">
            {preHeading && (
              <Text className="text-subheading my-4 font-montserrat text-base font-semibold uppercase text-black">
                {preHeading}
              </Text>
            )}
            {headingTitle && (
              <HeadingTag
                data={headingTitle}
                className="title color-from-bg mt-7 font-montserrat text-heading2 leading-heading2"
              />
            )}
            {subHeading && (
              <Text className="text-subheading my-4 font-montserrat text-base font-semibold text-black">
                {subHeading}
              </Text>
            )}
          </div>

          {(description || primaryCTA || secondaryCTA) && (
            <div className="color-from-bg mb-4 mt-5 text-paragraph">
              <Text dangerouslySetInnerHTML={{ __html: description }} />

              {(primaryCTA || secondaryCTA) && (
                <Box className="mt-10 flex justify-start gap-4 sm:mt-16 lg:mt-10">
                  <Link className="btn primary self-end" link={primaryCTA} />

                  <Link className="btn secondary self-end" link={secondaryCTA} />
                </Box>
              )}
            </div>
          )}
        </div>

        <div className="flex h-fit w-full flex-wrap lg:w-[46%]">
          {iconListElements.map((iconItem, iconIndex) => (
            <div key={iconIndex} className="icon-item mb-6 flex h-fit w-1/2 items-center last:mb-0">
              <div className="icon mr-[15px] h-[18px] w-[18px]">
                <ResponsiveImage
                  image={iconItem.logo}
                  widths={{ xs: 18, sm: 18, md: 18, lg: 18, xl: 18, xxl: 18 }}
                  heights={{ xs: 18, sm: 18, md: 18, lg: 18, xl: 18, xxl: 18 }}
                />
              </div>

              <div className="text">
                <p className="font-montserrat text-paragraph font-normal">{iconItem.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </ModuleBase>
  );
};

export default IconListWithRichTextModule;
