import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Plus from '../../assets/plus.svg';
import Minus from '../../assets/minus.svg';
import { useTranslation } from 'next-i18next';

const NumberInput = ({ name, value, onChange, className, minValue = 0, maxValue = Infinity, ...props }) => {
  const [_value, set_value] = useState(value);
  const { t } = useTranslation('common');

  const _onChange = (add) => {
    const newValue = _value + +add;
    set_value(newValue);
    const event = { target: { name, value: newValue } };
    if (onChange) {
      onChange(event);
    }
  };

  useEffect(() => {
    if (value !== null && value !== undefined) {
      set_value(value);
    }
  }, [value]);

  if (!value) {
    value = minValue;
  }

  return (
    <div className={classNames('number-input items-center justify-between px-4 py-2', className)} {...props}>
      <button
        className={classNames(
          'flex aspect-square h-6 items-center justify-center rounded-sm border border-charcoal/50 p-1 active:bg-sandstone',
          _value <= minValue && 'opacity-25',
        )}
        disabled={_value <= minValue || props.disabled}
        aria-label={t('general.$decrease')}
        onClick={(e) => {
          e.preventDefault();
          _onChange(-1);
        }}
      >
        <Minus className="h-4 w-4" />
      </button>
      <input
        type="number"
        name={name}
        min={minValue}
        value={_value}
        autoComplete="off"
        disabled={props.disabled}
        className={classNames(
          'mx-6 font-montserrat text-xs font-normal not-italic leading-5 text-charcoal',
          _value > minValue && 'is-value',
        )}
        onChange={(e) => {
          e.preventDefault();
          e.target.value = +e.target.value;
          onChange(e);
        }}
      />

      <button
        className={classNames(
          'flex aspect-square h-6 items-center justify-center rounded-sm border border-charcoal/50 p-1 active:bg-sandstone',
          _value >= maxValue && 'opacity-25',
        )}
        disabled={_value >= maxValue || props.disabled}
        aria-label={t('general.$increase')}
        onClick={(e) => {
          e.preventDefault();
          _onChange(1);
        }}
      >
        <Plus className="h-3 w-3" />
      </button>
    </div>
  );
};

export default NumberInput;
