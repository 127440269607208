import React from 'react';
import { ModuleBase, Link, Text, HeadingTag } from '@/components';
import { stripHtml } from '@/utils';
import { fadeInRight } from '@/animations';
import classNames from 'classnames';

const QuoteModule = ({ data }) => {
  const { quoteTitle, quote, preHeading, primaryCTA, secondaryCTA, author, source } = data || {};
  return (
    <ModuleBase data={data} className="relative">
      <div className="m-auto max-w-[1304px] px-6 md:px-10">
        <div className="title-wrapper">
          {preHeading && (
            <Text
              mottion={fadeInRight}
              className="text-subheading font-montserrat font-semibold uppercase text-grey3 sm:text-base"
            >
              {preHeading}
            </Text>
          )}
          {quoteTitle.heading && (
            <HeadingTag
              animate
              data={quoteTitle}
              className="text-h2 color-from-bg mb-6 mt-2 font-montserrat font-light md:mt-4 lg:mb-10"
            />
          )}
        </div>

        <div className={classNames('quote-wrapper')}>
          {quote && (
            <Text
              dangerouslySetInnerHTML={{ __html: '"' + stripHtml(quote) + '"' }}
              className="mb-6 font-montserrat text-heading6 leading-heading6 md:text-heading5 md:leading-heading3 lg:text-heading4 xl:mb-0 xl:max-w-[912px]"
            />
          )}
          <div className={classNames('author-source-wrapper')}>
            {author && (
              <Text
                dangerouslySetInnerHTML={{ __html: author }}
                className="mb-2 font-montserrat text-base font-semibold text-grey1"
              />
            )}
            {source && (
              <Text dangerouslySetInnerHTML={{ __html: source }} className="font-montserrat text-base text-grey1" />
            )}

            {/* {showBottomSpacing && <div className="mt-20"></div>} */}
            {(primaryCTA || secondaryCTA) && (
              <div className="flex justify-end">
                {primaryCTA && (
                  <Link animate className="btn primary mt-2 self-end px-2 sm:mt-0 md:px-2.5" link={primaryCTA} />
                )}

                {secondaryCTA && (
                  <Link
                    animate
                    className="btn secondary ml-4 mt-2 self-end px-2 sm:mt-0 md:px-2.5"
                    link={secondaryCTA}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </ModuleBase>
  );
};
export default QuoteModule;
