import PropTypes from 'prop-types';
import { ResponsiveImage, Link } from '..';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import tailwindConfig from '@/tailwind.config.js';

const AccommodationCard = ({ data, className, ...props }) => {
  const { t } = useTranslation('common');
  const [desktop, setDesktop] = useState(false);

  useEffect(() => {
    const resize = () => {
      setDesktop(window.innerWidth >= parseInt(tailwindConfig.theme.screens.xl, 10));
    };

    resize();

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  return data.link && desktop ? (
    <Link
      className={classNames(className, 'main-wrapper group flex h-auto flex-col bg-silk')}
      {...props}
      link={data.link}
    >
      <div className="aspect-16/9 overflow-hidden">
        {data.image && (
          <ResponsiveImage
            image={data.image}
            widths={{ xs: 645, md: 1062, lg: 1089 }}
            heights={{ xs: 270, md: 599, lg: 597 }}
            imgProps={{ className: 'transition-transform duration-1000 group-hover:scale-110' }}
          />
        )}
      </div>

      <div className="content-wrapper flex grow flex-col justify-between px-4 py-6">
        <div className="text-wrapper flex grow flex-col justify-between">
          <div>
            {data.title && (
              <p
                className="title-05 mb-[14px] text-charcoal xl:mb-[22px] xxl:text-[24px] xxl:leading-8"
                role="heading"
                aria-level="7"
              >
                {data.title}
              </p>
            )}
            <div className="accomodation-details xl:flex xl:justify-between">
              <div className="accomodation-first-column flex border-t border-t-charcoal/25 py-2 xl:flex xl:w-full xl:border-y xl:border-y-charcoal/25">
                <div className="flex w-full items-center gap-4">
                  <p className="label-01 text-charcoal/50">{t('cards.$guests')}</p>
                  <p className="body-03 text-charcoal">{data.guests}</p>
                </div>

                <div className="flex w-full items-center gap-4 xl:justify-center">
                  <p className="label-01 text-charcoal/50">{t('cards.$bedrooms')}</p>
                  <p className="body-03 text-charcoal">{data.bedrooms}</p>
                </div>
              </div>

              <div className="accomodation-second-column flex border-y border-y-charcoal/25 py-2 xl:w-full">
                <div className="flex w-full items-center gap-4 xl:justify-center">
                  <p className="label-01 text-charcoal/50">{t('cards.$area')}</p>
                  <p className="body-03 text-charcoal">
                    {data.area}
                    <span class="ml-1">{t('cards.$areaSqm')}</span>
                  </p>
                </div>

                <div className="flex w-full items-center gap-4 xl:justify-end">
                  <p className="label-01 text-charcoal/50">{t('cards.$bathrooms')}</p>
                  <p className="body-03 text-charcoal">{data.bathRooms}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  ) : (
    <div className={classNames(className, 'main-wrapper flex h-auto flex-col bg-silk')}>
      <div className="aspect-16/9 overflow-hidden">
        {data.image && (
          <ResponsiveImage
            image={data.image}
            widths={{ xs: 645, md: 1062, lg: 1089 }}
            heights={{ xs: 270, md: 599, lg: 597 }}
          />
        )}
      </div>

      <div className="content-wrapper flex grow flex-col justify-between px-4 py-6">
        <div className={classNames('text-wrapper flex grow flex-col justify-between')}>
          <div>
            {data.title && (
              <p
                className="title-05 mb-[14px] text-charcoal xl:mb-[22px] xxl:text-[24px] xxl:leading-8"
                role="heading"
                aria-level="7"
              >
                {data.title}
              </p>
            )}
            <div className="accomodation-details xl:flex xl:justify-between">
              <div className="accomodation-first-column flex border-t border-t-charcoal/25 py-2 xl:flex xl:w-full xl:border-y xl:border-y-charcoal/25">
                <div className="flex w-full items-center gap-4">
                  <p className="label-01 text-charcoal/50">{t('cards.$guests')}</p>
                  <p className="body-03 text-charcoal">{data.guests}</p>
                </div>

                <div className="flex w-full items-center gap-4 xl:justify-center">
                  <p className="label-01 text-charcoal/50">{t('cards.$bedrooms')}</p>
                  <p className="body-03 text-charcoal">{data.bedrooms}</p>
                </div>
              </div>

              <div className="accomodation-second-column flex border-y border-y-charcoal/25 py-2 xl:w-full">
                <div className="flex w-full items-center gap-4 xl:justify-center">
                  <p className="label-01 text-charcoal/50">{t('cards.$area')}</p>
                  <p className="body-03 text-charcoal">{data.area}</p>
                </div>

                <div className="flex w-full items-center gap-4 xl:justify-end">
                  <p className="label-01 text-charcoal/50">{t('cards.$bathrooms')}</p>
                  <p className="body-03 text-charcoal">{data.bathRooms}</p>
                </div>
              </div>
            </div>
          </div>

          {data.link && (
            <div className="mt-auto pt-3 xl:hidden">
              <Link className="btn tertiary" link={data.link}>
                {t('general.$viewMore')}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

AccommodationCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AccommodationCard;
