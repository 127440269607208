import { getFormattedValue } from '@/utils';
import classNames from 'classnames';
import { motion, wrap } from 'framer-motion';
import { useContext } from 'react';
import { CarouselContext } from '.';
import { useTranslation } from 'next-i18next';

const CarouselPaginationSlider = ({ length, loop }) => {
  const { currItem, width, moduleId } = useContext(CarouselContext);

  return (
    <div className="carousel-pagination-slider-track flex h-[3px] w-[300px]">
      {Array.from({ length }, (_, i) => i).map((_, index) => {
        const isActive = loop ? wrap(0, length, currItem) : index === currItem;
        return (
          <div
            key={`box-${index}`}
            className="relative size-full bg-silk/25 first:rounded-l-full last:rounded-r-full"
            style={{ width: Math.ceil(width / length) }}
          >
            {isActive ? (
              <motion.div
                transition={{ type: 'spring', stiffness: 100, damping: 20 }}
                layoutId={`carousel-pagination-slider-thumb-${moduleId}`}
                className={classNames('absolute size-full rounded-full bg-silk')}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

const CarouselPagination = ({ length, loop, paginationType = 'dots', paginationStyle, ...props }) => {
  const { currItem, setCurrItem } = useContext(CarouselContext);
  const { t } = useTranslation('common');

  const handleClick = (index) => {
    setCurrItem(index);
  };

  const indexer = Array.from({ length }, (v, i) => i);
  const renderDots = indexer.map((i) => {
    const isActive = loop ? i === wrap(0, length, currItem) : currItem === i;
    const number = getFormattedValue(i, paginationType);

    let paginationItem = (
      <div
        className={classNames(
          [
            `carousel-pagination-${paginationType === 'dots' || paginationType === 'slider' ? 'step' : 'number'} h-1 w-4 rounded-full transition-colors`,
          ],
          paginationStyle,
          { active: isActive },
        )}
      />
    );

    if (paginationType !== 'dots') {
      paginationItem = (
        <p
          className={classNames(
            [`carousel-pagination-${paginationType === 'dots' || paginationType === 'slider' ? 'step' : 'number'}`],
            { active: isActive },
            paginationStyle,
          )}
        >
          {number}
        </p>
      );
    }

    return (
      <div
        key={`pagination${i}`}
        className="carousel-pagination-step-click-area cursor-pointer py-1"
        onClick={() => handleClick(i)}
        role="button"
        aria-label={`${t('general.$goToSlide')} ${i + 1}`}
        tabIndex={0}
      >
        {paginationItem}
      </div>
    );
  });

  return (
    <div {...props} className="carousel-pagination mx-auto flex flex-row items-start">
      {paginationType !== 'slider' ? renderDots : <CarouselPaginationSlider length={length} loop={loop} />}
    </div>
  );
};

export default CarouselPagination;
