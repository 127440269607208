import { Link, ResponsiveImage, ModuleBase, HeadingTag, Media } from '@/components';

const RichTextAndMediaOnFullBleedModule = ({ data }) => {
  return (
    <ModuleBase data={data}>
      <div className="main-data relative flex w-full flex-col lg:flex-row lg:justify-between">
        <div className="relative my-auto aspect-3/4 h-fit w-full md:h-[511px] lg:mb-0 lg:h-[800px] lg:max-h-screen">
          <ResponsiveImage
            image={data.backgroundImage}
            widths={{ xs: 1024, sm: 1024, md: 1024, lg: 1440, xl: 1900 }}
            className="absolute h-full w-full object-cover"
          />

          <div className="relative left-1/2 top-1/2 aspect-3/4 max-w-[156px] -translate-x-2/4 -translate-y-1/2 md:h-[285px] md:max-w-[214px] lg:h-[521px] lg:max-w-[392px]">
            <Media
              media={data.smallMedia}
              widths={{ xs: 234, md: 332, lg: 392 }}
              className="h-full w-full object-cover"
            />
          </div>
        </div>

        <div className="text-wrapper color-from-bg container ml-0 h-full w-full pb-[21px] pt-14 md:w-[65%] md:px-10 md:pb-4 md:pt-10 lg:absolute lg:left-1/2 lg:top-0 lg:flex lg:h-[800px] lg:max-h-screen lg:w-full lg:-translate-x-2/4 lg:items-center lg:justify-between lg:px-[20px]">
          <div className="title-wrapper lg:w-[35%]">
            <p className="text-subheading font-montserrat font-semibold uppercase">{data.preHeading}</p>

            <HeadingTag data={data.headingTitle} className="text-h2 my-4 font-montserrat font-light lg:pr-6" />
          </div>

          <div className="lg:w-[30%]">
            <div
              dangerouslySetInnerHTML={{ __html: data.description }}
              className="font-montserrat text-small-paragraph md:max-w-lg md:text-paragraph"
            ></div>

            {data.primaryCTA && <Link className="btn primary mr-4 mt-10" link={data.primaryCTA} />}

            {data.secondaryCTA && <Link className="btn secondary mr-2 mt-10" link={data.secondaryCTA} />}
          </div>
        </div>
      </div>
    </ModuleBase>
  );
};

export default RichTextAndMediaOnFullBleedModule;
