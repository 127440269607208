import React, { useRef, useEffect } from 'react';
import { ModuleBase, ResponsiveImage, Stack, Text, Link, HeadingTag } from '@/components';
import Flicking, { FlickingError, ViewportSlot } from '@egjs/react-flicking';
import { Pagination, AutoPlay } from '@egjs/flicking-plugins';
import '@egjs/flicking-plugins/dist/pagination.css';
import '@egjs/react-flicking/dist/flicking.css';
import Arrow from '@/assets/arrow.svg';

const HeroCarouselLeftAlignedModule = ({ data }) => {
  const carousel = useRef();

  useEffect(() => {
    carousel.current.on('ready', (e) => {
      e.currentTarget.addPlugins(
        new Pagination({
          type: 'bullet',
          renderBullet: (className) => `<span class="${className}" role="button" aria-label="Go to slide"></span>`,
        }),
      );
      if (data?.autoPlay) {
        e.currentTarget.addPlugins(new AutoPlay({ duration: 2000, direction: 'NEXT', stopOnHover: false }));
      }
    });
  }, [data?.autoPlay]);

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };

  return (
    <ModuleBase data={data} className="relative h-screen">
      <Flicking ref={carousel} align="center" useResizeObserver={true} horizontal={true} circular={true} className="">
        {data.mediaItems?.map((slide, index) => (
          <div key={index} className="h-screen w-full">
            <ResponsiveImage
              image={slide}
              widths={{ xs: 1024, sm: 1024, md: 1024, lg: 1024, xl: 1280, xxl: 1536 }}
              dataNotLazy
              className="overlay-left-to-right"
            />
          </div>
        ))}
        <ViewportSlot>
          <div className="flicking-pagination relative !z-40 !w-auto pb-[56px] pl-[24px] md:pl-[40px] lg:pl-[108px] [&_.flicking-pagination-bullet.flicking-pagination-bullet-active]:bg-white [&_.flicking-pagination-bullet]:bg-grey4"></div>
        </ViewportSlot>
      </Flicking>

      <div className="pointer-events-none absolute top-0 z-30 flex h-full w-full items-end justify-start px-6 pb-[104px] md:pb-[118px] md:pl-[40px] lg:pb-[120px] lg:pl-[108px]">
        <Stack className="pointer-events-auto flex h-full flex-col justify-end lg:w-3/5">
          <div className="title-wrapper" role="contentinfo" aria-label="contentinfo">
            {data.preHeading && (
              <Text className="text-subheading my-4 font-montserrat text-base font-semibold uppercase text-white">
                {data.preHeading}
              </Text>
            )}
            {data.headingTitle && (
              <HeadingTag
                animate
                data={data.headingTitle}
                className="color-from-bg mt-7 font-montserrat text-5xl font-semibold not-italic leading-heading1m tracking-heading1m !text-white lg:text-7xl lg:leading-heading1 lg:tracking-heading1"
              />
            )}
            {data.subHeading && (
              <Text className="text-subheading my-4 font-montserrat text-base font-semibold text-white">
                {data.subHeading}
              </Text>
            )}
          </div>

          {(data.description || data.primaryCTA || data.secondaryCTA) && (
            <div className="color-from-bg mb-4 mt-5 text-heading6 font-light not-italic leading-8 tracking-heading6">
              <Text dangerouslySetInnerHTML={{ __html: data.description }} className="text-white" />

              {(data.primaryCTA || data.secondaryCTA) && (
                <div className="mt-15 flex justify-start gap-4 sm:mt-16 lg:mt-20">
                  <Link animate className="btn primary" link={data.primaryCTA} />

                  <Link animate className="btn secondary" link={data.secondaryCTA} />
                </div>
              )}
            </div>
          )}
        </Stack>
      </div>
      <div className="absolute bottom-[50px] right-[24px] z-30 flex w-[100px] justify-between sm:w-[115px] md:right-[40px] lg:bottom-[56px] lg:right-[108px]">
        <button
          className="btn text circle z-10 h-10 w-10 border p-0 sm:h-12 sm:w-12"
          onClick={move}
          aria-label="Prevues"
        >
          <Arrow className="rotate-180" />
        </button>

        <button
          className="btn text circle z-10 h-10 w-10 border p-0 sm:h-12 sm:w-12"
          onClick={() => move(1)}
          aria-label="Next"
        >
          <Arrow />
        </button>
      </div>
    </ModuleBase>
  );
};
export default HeroCarouselLeftAlignedModule;
