import ArrowForward from '@/assets/arrowForward.svg';
import { Link, ModuleBase, Stack, Text } from '@/components';
import { isColorDark } from '@/utils';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import tailwindConfig from '@/tailwind.config';

const ListColumnsModule = ({ data, ...props }) => {
  const [active, setActive] = useState([]);
  const [seeMore, setSeeMore] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const isDark = isColorDark(data.backgroundColour);
  const { t } = useTranslation('common');
  const { screens } = tailwindConfig.theme;

  useEffect(() => {
    const resize = () => {
      setWindowWidth(window.innerWidth);
    };

    resize();

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  const accordionColMotion = (index) => {
    return {
      initial: 'inactive',
      animate: active.includes(index) ? 'active' : 'inactive',
      variants: {
        inactive: {
          height: 0,
        },
        active: {
          height: 'auto',
        },
      },
      transition: {
        type: 'spring',
        stiffness: 120,
        damping: 30,
      },
    };
  };

  const seeMoreMotion = {
    variants: {
      initial: { opacity: 0, height: 0 },
      enter: { opacity: 1, height: 'auto' },
      exit: { opacity: 0, height: 0 },
    },
    initial: 'initial',
    animate: 'enter',
    exit: 'exit',
  };

  const toggleActive = (index) => {
    if (window.innerWidth >= 1024) {
      return;
    }

    let newArray = [...active];
    if (newArray.includes(index)) {
      newArray = newArray.filter((i) => i !== index);
    } else {
      newArray = [index];
    }
    setActive(newArray);
  };

  return (
    <ModuleBase data={data} {...props}>
      <div
        className={classNames(
          'container px-4 md:px-6 lg:flex lg:px-10',
          data.columns.length > 2 ? 'flex-col' : 'flex-row items-start justify-between',
        )}
      >
        <Stack
          className={classNames(
            'flex flex-col gap-05 lg:w-[456px] lg:gap-10',
            data.columns.length > 2 ? 'lg:w-full lg:flex-row lg:items-end lg:justify-between' : '',
          )}
        >
          <Stack className={classNames(data.columns.length > 2 ? 'lg:w-[456px]' : '')}>
            {data.label && (
              <Text className="color-from-bg label-01 mb-1.5 font-montserrat uppercase !opacity-50">{data.label}</Text>
            )}
            {data.title && <Text className="color-from-bg title-04 font-kepler">{data.title}</Text>}
            {data.description && (
              <Text
                className="color-from-bg body-03 mt-[15px] font-montserrat !text-opacity-75 lg:mt-[22px]"
                dangerouslySetInnerHTML={{ __html: data.description }}
              />
            )}
          </Stack>
          {(data.primaryCTA || data.secondaryCTA) && (
            <Stack className="flex flex-col gap-02 md:flex-row">
              {data.primaryCTA && (
                <Link link={data.primaryCTA} className={classNames('btn primary w-fit', isDark ? 'dark' : '')} />
              )}
              {data.secondaryCTA && (
                <Link link={data.secondaryCTA} className={classNames('btn secondary w-fit', isDark ? 'dark' : '')} />
              )}
            </Stack>
          )}
        </Stack>
        <Stack
          className={classNames(
            'mt-06 lg:flex lg:gap-05 lg:gap-y-07 xl:gap-y-16',
            data.columns.length > 2 ? 'lg:mt-07 lg:grid lg:grid-cols-4 xl:mt-16' : 'lg:mt-0',
            data.columns.length > 1
              ? 'divide-y divide-charcoal/25 border-y border-y-charcoal/25 lg:divide-y-0 lg:border-0'
              : '',
          )}
        >
          <AnimatePresence>
            {data.columns.map((column, index) => {
              if (data.columns.length > 1) {
                if (index > 3 && !seeMore && windowWidth >= parseInt(screens.lg, 10)) {
                  return;
                } else {
                  return (
                    <Stack
                      layout
                      key={`col-${index}`}
                      motion={seeMoreMotion}
                      onClick={() => toggleActive(index)}
                      className={classNames(
                        'lg:!border-y lg:border-y-charcoal/25',
                        data.columns.length > 2 ? 'lg:w-full lg:max-w-[334px]' : 'lg:w-[334px]',
                      )}
                    >
                      <Stack
                        className={classNames(
                          'flex items-center justify-between border-b py-2.5 transition-colors delay-100 lg:py-03',
                          active.includes(index)
                            ? 'border-b-charcoal/25'
                            : 'border-b-charcoal/0 lg:border-b-charcoal/25',
                        )}
                      >
                        <Text className="color-from-bg body-02 font-montserrat font-semibold">
                          {column.columnTitle}
                        </Text>
                        <ArrowForward
                          role="presentation"
                          className={classNames(
                            'transition-transform duration-200 lg:hidden',
                            isDark ? 'fill-silk' : 'fill-charcoal',
                            active.includes(index) ? '-rotate-180' : '',
                          )}
                        />
                      </Stack>
                      <motion.div {...accordionColMotion(index)} className="overflow-hidden lg:hidden">
                        {column.textRows.map((text, i) => (
                          <Text key={`text-${i}`} className="color-from-bg body-03 py-03 !text-opacity-75">
                            {text}
                          </Text>
                        ))}
                      </motion.div>
                      <Stack className="hidden lg:block">
                        {column.textRows.map((text, i) => (
                          <Text key={`text-${i}`} className="color-from-bg body-03 py-03 !text-opacity-75">
                            {text}
                          </Text>
                        ))}
                      </Stack>
                    </Stack>
                  );
                }
              }

              return (
                <Stack
                  key={`col-${index}`}
                  className={classNames(
                    'border-y border-y-charcoal/25',
                    data.columns.length > 2 ? 'lg:w-full lg:max-w-[334px]' : 'lg:w-[334px]',
                  )}
                >
                  <Text className="color-from-bg body-02 border-b border-b-charcoal/25 py-2.5 font-montserrat font-semibold lg:py-03">
                    {column.columnTitle}
                  </Text>
                  <Stack>
                    {column.textRows.map((text, i) => (
                      <Text
                        key={`text-${i}`}
                        className="color-from-bg body-03 py-4 font-montserrat !text-opacity-75 md:py-03"
                      >
                        {text}
                      </Text>
                    ))}
                  </Stack>
                </Stack>
              );
            })}
          </AnimatePresence>
        </Stack>
        <button
          className={classNames(
            'btn mx-auto mt-07 w-fit xl:mt-16',
            data.columns.length > 4 ? 'hidden lg:inline-flex' : 'hidden',
            isDark ? 'text-silk' : 'text-charcoal',
          )}
          onClick={() => setSeeMore(!seeMore)}
        >
          <span>{seeMore ? t('general.$seeLess') : t('general.$seeMore')}</span>
          <ArrowForward
            role="presentation"
            className={classNames(
              'transition-transform duration-200',
              isDark ? 'fill-silk' : 'fill-charcoal',
              seeMore ? '-rotate-180' : '',
            )}
          />
        </button>
      </div>
    </ModuleBase>
  );
};

export default ListColumnsModule;
