import { ModuleBase, ResponsiveImage, Text, Stack, Link, HeadingTag, Box } from '@/components';
import { isColorDark } from '@/utils';
import classNames from 'classnames';

const IconListModule = ({ data }) => {
  const isDark = isColorDark(data.backgroundColour);
  const { headingTitle, description, preHeading, subHeading, iconsSubtitle, primaryCTA, secondaryCTA } = data || {};

  return (
    <ModuleBase data={data} className="font-montserrat">
      <Stack className="container lg:flex lg:justify-between">
        <div className="text-wrapper flex flex-col">
          <div className="title-wrapper lg:w-[456px]" role="contentinfo" aria-label="contentinfo">
            {preHeading && <Text className="color-from-bg label-01 mb-3">{preHeading}</Text>}
            {headingTitle && <HeadingTag animate data={headingTitle} className="title-04 color-from-bg mb-05" />}
            {subHeading && <Text className="color-from-bg body-03">{subHeading}</Text>}

            {description && (
              <Text dangerouslySetInnerHTML={{ __html: description }} className="color-from-bg body-03" />
            )}
          </div>

          {(primaryCTA || secondaryCTA) && (
            <Box className="mt-05 flex flex-col gap-2 md:flex-row lg:mt-10 lg:items-center">
              <Link animate className={classNames('btn primary', isDark ? 'dark' : 'light')} link={primaryCTA} />
              <Link animate className={classNames('btn secondary', isDark ? 'dark' : 'light')} link={secondaryCTA} />
            </Box>
          )}
        </div>
        <div className="list flex flex-col lg:w-[334px]">
          {/* {groups.map((group, index) => (
            <div
              className={classNames(
                'list-column mb-10 w-1/2 md:mb-20 lg:mb-0 lg:flex lg:flex-col lg:items-start',
                columns,
              )}
              key={index}
            >
              {group.map((item, index) => (
                <div className="mb-6 flex items-center last:mb-0" key={index}>
                  <div className="logo mr-[15px] aspect-square h-[18px] w-[18px]">
                    <ResponsiveImage image={item.logo} widths={{ xs: 36 }} heights={{ xs: 36 }} />
                  </div>
                  <div className="list-item-text">
                    <p className="">{item.text}</p>
                  </div>
                </div>
              ))}
            </div>
          ))} */}
          <Box className={classNames('mt-8 border-t py-4 lg:mt-0', isDark ? 'border-silk/25' : 'border-charcoal/25')}>
            {iconsSubtitle && (
              <Text animate className="color-from-bg body-03">
                {iconsSubtitle}
              </Text>
            )}
          </Box>
          <Box className={classNames('border-y', isDark ? 'border-y-silk/25' : 'border-y-charcoal/25')}>
            {data.iconListElements.map((icon, index) => (
              // <div className={classNames('list-column w-full')} key={index}>
              <Box className="flex items-center py-4" key={index}>
                <div className="logo mr-[15px] aspect-square h-[18px] w-[18px]">
                  <ResponsiveImage image={icon.logo} widths={{ xs: 36 }} heights={{ xs: 36 }} />
                </div>
                <div className="list-item-text">
                  <p className="color-from-bg">{icon.text}</p>
                </div>
              </Box>

              // </div>
            ))}
          </Box>
        </div>
      </Stack>
    </ModuleBase>
  );
};

export default IconListModule;
