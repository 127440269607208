import { ModuleBase, ResponsiveImage, Link, Text, HeadingTag, Box } from '@/components';
import classNames from 'classnames';

const LogoListModule = ({ data }) => {
  const { headingTitle, description, preHeading, subHeading, primaryCTA, secondaryCTA } = data || {};

  return (
    <ModuleBase data={data} className="font-montserrat text-black">
      <div className="main-wrapper container lg:px-[108px]">
        <div className="text-wrapper mb-10 md:mb-16 md:w-[75%] lg:w-[45%]">
          <div className="title-wrapper" role="contentinfo" aria-label="contentinfo">
            {preHeading && (
              <Text className="text-subheading my-4 font-montserrat text-base font-semibold uppercase text-black">
                {preHeading}
              </Text>
            )}
            {headingTitle && (
              <HeadingTag
                data={headingTitle}
                className="title color-from-bg mt-7 font-montserrat text-heading2 leading-heading2"
              />
            )}
            {subHeading && (
              <Text className="text-subheading my-4 font-montserrat text-base font-semibold text-black">
                {subHeading}
              </Text>
            )}
          </div>

          {(description || primaryCTA || secondaryCTA) && (
            <div className="color-from-bg mb-4 mt-5 text-paragraph">
              <Text dangerouslySetInnerHTML={{ __html: description }} />

              {(primaryCTA || secondaryCTA) && (
                <Box className="mt-10 flex justify-start gap-4 sm:mt-16 lg:mt-10">
                  <Link className="btn primary self-end" link={primaryCTA} />

                  <Link className="btn secondary self-end" link={secondaryCTA} />
                </Box>
              )}
            </div>
          )}
        </div>

        <div
          className={classNames(
            'icon-wrapper gap-x-10 gap-y-4 md:justify-start md:gap-x-16 md:gap-y-6 lg:justify-center lg:gap-x-20 lg:px-[208px]',
            data.logoListingSection.length === 3
              ? 'flex justify-between'
              : 'flex flex-wrap justify-center px-12 md:px-0',
          )}
        >
          {data.logoListingSection.map((iconItem, iconIndex) => (
            <div
              className="icon pointer-events-none aspect-square max-w-[86px] select-none md:max-w-[124px] lg:max-w-[142px]"
              key={iconIndex}
            >
              <ResponsiveImage
                image={iconItem}
                widths={{ xs: 129, md: 186, lg: 213 }}
                className="h-full w-full object-cover"
              />
            </div>
          ))}
        </div>
        {(data.primaryCTA || data.secondaryCTA) && (
          <div className="flex justify-center gap-4">
            <Link className="btn primary mt-10 self-end md:mt-16" link={data.primaryCTA} />
            <Link className="btn secondary mt-10 self-end md:mt-16" link={data.secondaryCTA} />
          </div>
        )}
      </div>
    </ModuleBase>
  );
};

export default LogoListModule;
