import CloseIcon from '@/assets/lightboxClose.svg';
import { Link, ModalPortal, ResponsiveImage, Stack, Text } from '@/components';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';

const OverlayGenericModule = ({ data, ...props }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { label, title, text, image, primaryCTA, secondaryCTA } = data || {};
  const { t } = useTranslation('common');

  return (
    <ModalPortal>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            variants={{
              inactive: { opacity: 0 },
              active: { opacity: 1 },
              exit: { opacity: 0 },
            }}
            initial="inactive"
            animate="active"
            exit="exit"
            transition={{ type: 'spring', stiffness: 80, damping: 20 }}
            {...props}
            className="disable-scroll fixed inset-0 z-50 grid h-screen w-full place-content-center bg-black/70 px-4 backdrop-blur-[6px] supports-[height:100dvh]:h-dvh md:px-6 lg:px-10"
          >
            <button
              className="absolute right-6 top-6 md:right-8 md:top-8"
              onClick={() => {
                setIsOpen(false);
              }}
              aria-label={t('general.$close')}
            >
              <CloseIcon role="presentation" className="size-12" />
            </button>
            <div className="relative hidden aspect-[343/533] w-[343px] items-center justify-center p-4 md:flex md:aspect-[720/400] md:w-[calc(100vw-48px)] md:justify-start md:p-6 lg:aspect-[1188/560] lg:w-[calc(100vw-80px)] lg:max-w-[1188px]">
              <ResponsiveImage
                image={image}
                className="overlay absolute inset-0 -z-10"
                widths={{ xs: 375, sm: 640, md: 768, lg: 1024, xl: 1280, xxl: 1536 }}
              />
              {(label || title || text || primaryCTA || secondaryCTA) && (
                <Stack className="flex w-full flex-col justify-between bg-silk p-4 pt-6 font-montserrat text-charcoal md:h-full md:w-[348px]">
                  <div>
                    {label && <Text className="label-01 mb-2 text-charcoal/50">{label}</Text>}
                    {title && <Text className="title-05 mb-6 font-kepler md:mb-0">{title}</Text>}
                  </div>
                  <div className="flex flex-col">
                    {text && <Text className="body-03 has-[+.btn]:mb-7">{text}</Text>}
                    {primaryCTA && <Link link={primaryCTA} className="btn primary has-[+.btn.secondary]:mb-2" />}
                    {secondaryCTA && <Link link={secondaryCTA} className="btn secondary" />}
                  </div>
                </Stack>
              )}
            </div>
            <div className="main-wrapper relative h-[80dvh] w-full min-w-[343px] p-4 md:hidden">
              <div className="img-wrapper h-full w-full bg-charcoal">
                <ResponsiveImage
                  image={image}
                  className=""
                  widths={{ xs: 375, sm: 640, md: 768, lg: 1024, xl: 1280, xxl: 1536 }}
                  heights={{ xs: 501, sm: 501, md: 768, lg: 1024, xl: 1280, xxl: 1536 }}
                />
              </div>
              {(label || title || text || primaryCTA || secondaryCTA) && (
                <div className="absolute left-1/2 top-1/2 flex w-[calc(100%-64px)] -translate-x-1/2 -translate-y-1/2 flex-col justify-between bg-silk p-4 pt-6 font-montserrat text-charcoal">
                  <div>
                    {label && <Text className="label-01 mb-2 text-charcoal/50">{label}</Text>}
                    {title && <Text className="title-05 mb-6 font-kepler md:mb-0">{title}</Text>}
                  </div>
                  <div className="flex flex-col">
                    {text && <Text className="body-03 has-[+.btn]:mb-7">{text}</Text>}
                    {primaryCTA && <Link link={primaryCTA} className="btn primary has-[+.btn.secondary]:mb-2" />}
                    {secondaryCTA && <Link link={secondaryCTA} className="btn secondary" />}
                  </div>
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </ModalPortal>
  );
};

export default OverlayGenericModule;
