import React from 'react';
import { ModuleBase, ResponsiveImage, Stack, Link, Text, HeadingTag } from '@/components';
import { stripHtml } from '@/utils';
import { fadeInRight } from '@/animations';
import classNames from 'classnames';
import { animControllerInView } from '@/animations';

const QuoteWithMediaModule = ({ data }) => {
  const textIsLeft = data.desktopLayoutAlignment === 'Left';
  const orderLast = data.mobileLayoutAlignment === 'Bottom' ? 'order-last' : '';
  const classNamesByPosition = textIsLeft ? `lg:order-last` : 'justify-end ';

  const { quoteTitle, quote, preHeading, primaryCTA, secondaryCTA, author, source } = data || {};

  return (
    <ModuleBase data={data} className="relative">
      <Stack
        motion={animControllerInView({ delay: 0.2, staggerChildren: 0.3 })}
        className="container grid max-w-[1272px] grid-cols-1 content-center md:px-10 lg:grid-cols-2 xl:px-0"
      >
        <div
          className={classNames('flex h-full w-full items-center lg:justify-center', classNamesByPosition, orderLast)}
        >
          <div className="my-10 h-[434px] w-[327px] md:my-16 md:h-[441px] md:w-[332px] lg:h-[533px] lg:w-[400px] xl:h-[659px] xl:w-[496px]">
            <ResponsiveImage
              animate
              image={data.media}
              widths={{ xs: 327, sm: 327, md: 332, lg: 400, xl: 496, xxl: 496 }}
              heights={{ xs: 434, sm: 434, md: 441, lg: 533, xl: 659, xxl: 659 }}
            />
          </div>
        </div>

        <div className="quote-wrapper">
          <div className="title-wrapper">
            {preHeading && (
              <Text
                mottion={fadeInRight}
                className="text-subheading font-montserrat font-semibold uppercase text-grey3 sm:text-base"
              >
                {preHeading}
              </Text>
            )}
            {quoteTitle.heading && (
              <HeadingTag
                animate
                data={quoteTitle}
                className="text-h2 color-from-bg mb-6 mt-2 font-montserrat font-light md:mt-4 lg:mb-10"
              />
            )}
          </div>

          <div className={classNames('quote-wrapper')}>
            {quote && (
              <Text
                dangerouslySetInnerHTML={{ __html: '"' + stripHtml(quote) + '"' }}
                className="mb-6 font-montserrat text-heading6 leading-heading6 md:text-heading5 md:leading-heading3 lg:text-heading4 xl:mb-0 xl:max-w-[912px]"
              />
            )}
            <div className={classNames('author-source-wrapper')}>
              {author && (
                <Text
                  dangerouslySetInnerHTML={{ __html: author }}
                  className="mb-2 font-montserrat text-base font-semibold text-grey1"
                />
              )}
              {source && (
                <Text dangerouslySetInnerHTML={{ __html: source }} className="font-montserrat text-base text-grey1" />
              )}

              {/* {showBottomSpacing && <div className="mt-20"></div>} */}
              {(primaryCTA || secondaryCTA) && (
                <div className="flex justify-end">
                  {primaryCTA && (
                    <Link animate className="btn primary mt-2 self-end px-2 sm:mt-0 md:px-2.5" link={primaryCTA} />
                  )}

                  {secondaryCTA && (
                    <Link
                      animate
                      className="btn secondary ml-4 mt-2 self-end px-2 sm:mt-0 md:px-2.5"
                      link={secondaryCTA}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Stack>
    </ModuleBase>
  );
};
export default QuoteWithMediaModule;
