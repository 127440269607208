import { Link, ResponsiveImage } from '@/components';
import NavColumn from './NavColumn';
import classNames from 'classnames';

import { useEffect, useRef, useState } from 'react';

const NavItem = ({ navItem, level, activeMenu, setActiveMenu, close, parentMenuName, setMenuData, className }) => {
  const [isReady, setIsReady] = useState(false);
  const _setActiveMenu = (sub) => {
    let newValue = {};
    newValue[`l${level + 1}`] = sub;
    setActiveMenu(newValue);
  };

  let count = 0;
  navItem.flyout?.map((f1) => {
    count += f1.flyout?.length;
  });

  const linkClass = '';

  const handleMouseEnter = (item) => {
    if (level === 1) {
      setMenuData(level, { imgItem: item?.promoImage });
    }
  };

  let itemBgWidth = useRef(0);
  let itemBgHeight = useRef(0);

  useEffect(() => {
    itemBgWidth.current = window.innerWidth * 0.5 - 42; // half of the screen - paddingRight
    itemBgHeight.current = window.innerHeight - 80 - 52 - 56; // nav - paddigTop - paddingBottom

    setIsReady(true);
  }, []);

  return (
    <>
      <div className={classNames(className, '')}>
        {navItem.flyout?.length > 0 && level === 0 ? (
          <div
            className={classNames(linkClass, 'interactive-02 cursor-pointer font-[400] hover:font-[500]')}
            onMouseEnter={() => handleMouseEnter(navItem)}
            onClick={() => _setActiveMenu({})}
          >
            {navItem.navItemText || navItem.navigationalTitle}({count > 0 ? count : navItem.flyout?.length})
          </div>
        ) : (
          <>
            <Link
              className={` ${linkClass} link single-link interactive-01 font-[400] hover:font-[500] [&:hover+.image-block]:opacity-100 ${level === 1 && navItem.ctaLink ? 'level-1 interactive-01 mb-3 inline-block text-charcoal/50' : 'level-0'} ${level === 1 && navItem.ctaLink && navItem.flyout.length === 0 && !navItem.promoImage ? '!interactive-01 !hover:font-[500] !font-[400] !text-charcoal' : ''}`}
              link={
                navItem.navItemLink ||
                (navItem.ctaLink && { ...navItem.ctaLink, text: navItem.navigationalTitle || navItem.ctaLink.text })
              }
              onMouseEnter={() => {
                handleMouseEnter(navItem);
              }}
              onClick={close}
            >
              {navItem.navItemLink?.text || navItem.navigationalTitle || navItem.ctaLink?.text}
            </Link>

            {isReady && navItem.promoImage && (
              <div className="image-block absolute left-[25%] top-0 h-[calc(100vh-80px)] w-[50vw] bg-silk pb-[56px] pr-[42px] pt-[52px] opacity-0 transition-opacity duration-500">
                <ResponsiveImage
                  dataNotLazy
                  image={navItem.promoImage}
                  widths={{
                    xs: itemBgWidth.current,
                    sm: itemBgWidth.current,
                    md: itemBgWidth.current,
                    lg: itemBgWidth.current,
                    xl: itemBgWidth.current,
                    xxl: itemBgWidth.current,
                  }}
                  heights={{
                    xs: itemBgHeight.current,
                    sm: itemBgHeight.current,
                    md: itemBgHeight.current,
                    lg: itemBgHeight.current,
                    xl: itemBgHeight.current,
                    xxl: itemBgHeight.current,
                  }}
                  className="pointer-events-none h-full w-full object-cover transition-all duration-300"
                />
              </div>
            )}
          </>
        )}

        {level === 1 && navItem.flyout.length > 0 && (
          <div className="">
            {!navItem.ctaLink && <p className="interactive-01 mb-3 text-charcoal/50">{navItem.navigationalTitle}</p>}
            {navItem.flyout.map((item, index) => (
              <div key={index} className="last:mb-2">
                <Link
                  className={`${linkClass} interactive-02 !mb-4 inline-block font-[400] hover:font-[500] [&:hover+.image-block]:opacity-100`}
                  link={item.ctaLink}
                  onMouseEnter={() => handleMouseEnter(item)}
                  onClick={close}
                ></Link>

                {isReady && item.promoImage && (
                  <div className="image-block absolute left-[25%] top-0 h-[calc(100vh-80px)] w-[50vw] bg-silk pb-[56px] pr-[42px] pt-[52px] opacity-0 transition-opacity duration-500">
                    <ResponsiveImage
                      dataNotLazy
                      image={item.promoImage}
                      widths={{
                        xs: itemBgWidth.current,
                        sm: itemBgWidth.current,
                        md: itemBgWidth.current,
                        lg: itemBgWidth.current,
                        xl: itemBgWidth.current,
                        xxl: itemBgWidth.current,
                      }}
                      heights={{
                        xs: itemBgHeight.current,
                        sm: itemBgHeight.current,
                        md: itemBgHeight.current,
                        lg: itemBgHeight.current,
                        xl: itemBgHeight.current,
                        xxl: itemBgHeight.current,
                      }}
                      className="pointer-events-none h-full w-full object-cover transition-all duration-300"
                    />
                  </div>
                )}
              </div>
            ))}
            {/* <pre>{JSON.stringify(navItem, null, 2)}</pre> */}
          </div>
        )}

        {/* {(!navItem.flyout || navItem.flyout.length === 0) && !(navItem.navItemLink || navItem.ctaLink) && (
          <div className={linkClass} onClick={() => _setActiveMenu({})}>
            {navItem.navItemText || navItem.navigationalTitle}
          </div>
        )} */}
      </div>
      {navItem.flyout?.length > 0 && level === 0 && (
        <NavColumn
          navItems={navItem.flyout}
          level={level + 1}
          activeMenu={activeMenu}
          setActiveMenu={(sub) => _setActiveMenu(sub)}
          close={close}
          cta={navItem.flyout && navItem.flyout.length !== 0 ? navItem.navItemLink || navItem.ctaLink : null}
          ctaClass={linkClass}
          parentMenuName={level === 0 ? navItem.navItemText || navItem.navigationalTitle : parentMenuName}
          setMenuData={setMenuData}
        />
      )}
      {/* <pre>{JSON.stringify(navItem.flyout, null, 2)}</pre> */}
    </>
  );
};

export default NavItem;
