import { ModuleBase, ResponsiveImage, Media } from '@/components';
import classNames from 'classnames';
const IconListWithMediaModule = ({ data }) => {
  return (
    <ModuleBase data={data} className="container">
      <div
        className={classNames(
          'main-wrapper flex lg:hidden lg:items-center lg:gap-[9%]',
          data.mobileLayoutAlignment === 'Top' ? 'flex-col lg:flex-row' : 'flex-col-reverse lg:flex-row-reverse',
        )}
      >
        <div
          className={classNames(
            'my-auto aspect-3/4 h-fit w-full md:max-w-[450px] lg:max-w-[496px]',
            data.mobileLayoutAlignment === 'Top' ? 'mb-10 md:mb-20 lg:mb-0' : 'mt-4 md:mt-14 lg:mt-0',
          )}
        >
          {data.media && (
            <Media
              media={data.media}
              widths={{ xs: 327, sm: 327, md: 450, lg: 496, xl: 496, xxl: 496 }}
              heights={{ xs: 435, sm: 435, md: 597, lg: 660, xl: 660, xxl: 660 }}
            />
          )}
        </div>

        <div className="icon-wrapper flex h-fit w-full flex-wrap gap-x-[19px] md:gap-x-[24px] lg:w-[46%]">
          {data.iconListElements.map((iconItem, iconIndex) => (
            <div key={iconIndex} className="icon-item mb-6 flex h-fit w-[45%] items-center">
              <div className="icon mr-[15px] h-[18px] w-[18px]">
                <ResponsiveImage
                  image={iconItem.logo}
                  widths={{ xs: 18, sm: 18, md: 18, lg: 18, xl: 18, xxl: 18 }}
                  heights={{ xs: 18, sm: 18, md: 18, lg: 18, xl: 18, xxl: 18 }}
                />
              </div>

              <div className="text w-[75%]">
                <p className="font-montserrat text-paragraph font-normal">{iconItem.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className={classNames(
          'main-wrapper hidden lg:flex lg:items-center lg:gap-[9%]',
          data.desktopLayoutAlignment === 'Left' ? 'lg:flex-row' : 'lg:flex-row-reverse',
        )}
      >
        <div className="my-auto aspect-3/4 h-fit w-full md:w-[70%] lg:w-[45%] lg:max-w-[496px]">
          {data.media && (
            <Media
              media={data.media}
              widths={{ xs: 327, sm: 327, md: 450, lg: 496, xl: 496, xxl: 496 }}
              heights={{ xs: 435, sm: 435, md: 597, lg: 660, xl: 660, xxl: 660 }}
            />
          )}
        </div>

        <div className="icon-wrapper flex h-fit w-full flex-wrap gap-x-[24px] lg:w-[46%]">
          {data.iconListElements.map((iconItem, iconIndex) => (
            <div key={iconIndex} className="icon-item mb-6 flex h-fit w-[45%] items-center">
              <div className="icon mr-[15px] h-[18px] w-[18px]">
                <ResponsiveImage
                  image={iconItem.logo}
                  widths={{ xs: 18, sm: 18, md: 18, lg: 18, xl: 18, xxl: 18 }}
                  heights={{ xs: 18, sm: 18, md: 18, lg: 18, xl: 18, xxl: 18 }}
                />
              </div>

              <div className="text w-[75%]">
                <p className="font-montserrat text-paragraph font-normal">{iconItem.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </ModuleBase>
  );
};

export default IconListWithMediaModule;
