import React, { useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Player from '@vimeo/player';
import { useLayoutEffect } from '@/utils';
import classNames from 'classnames';

const VimeoPlayer = forwardRef(function VimeoPlayer(
  { media, cover, controls, onPayerReady, onAutoPlayStarted, onFullscreenExit, unShowCoverImage, ...props },
  ref,
) {
  const root = useRef();
  const container = useRef();

  let player = useRef();
  let fullscreen = useRef();
  const [show, setShow] = useState(!cover);
  const [vimeoReady, setVimeoReady] = useState(false);

  const [videobgWidth, setVideobgWidth] = useState('100%');
  const [videobgHeight, setVideobgHeight] = useState('100%');

  useEffect(() => {
    if (!isNaN(media.vimeoId)) {
      player.current = new Player(container.current, {
        id: media.vimeoId,
        autoplay: media.autoPlay,
        background: media.autoPlay,
        loop: media.loop,
        controls: controls,
        dnt: true,
      });

      if (media.autoPlay && onAutoPlayStarted) {
        player.current.play().then(() => {
          onAutoPlayStarted();
        });
      }

      player.current.ready().then(() => {
        setVimeoReady(true);
        if (onPayerReady) {
          onPayerReady();
        }
      });
    } else {
      console.error(`'${media.vimeoId}' is not a valid vimeo ID`);
    }
  }, [onPayerReady, onAutoPlayStarted, media, controls]);

  useImperativeHandle(ref, () => {
    const monitorFullScreen = () => {
      if (document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen) {
        setTimeout(() => {
          monitorFullScreen();
        }, 100);
      } else {
        if (fullscreen.current) {
          fullscreen.current = false;
        }
        onFullscreenExit && onFullscreenExit();
      }
    };

    return {
      openFullscreen() {
        if (
          (media.allowFullScreen === undefined || media.allowFullScreen === true) &&
          !fullscreen.current &&
          player.current
        ) {
          fullscreen.current = true;
          player.current.requestFullscreen();
          player.current.play();
          setTimeout(() => {
            monitorFullScreen();
          }, 1000);
        }
      },
      play() {
        player.current?.play();
      },
      pause() {
        player.current?.pause();
      },
      toggleMute() {
        player.current?.getMuted().then((muted) => {
          if (muted) {
            player.current?.setMuted(false);
          } else {
            player.current?.setMuted(true);
          }
        });
      },
      unMute() {
        player.current?.setMuted(false);
      },
      mute() {
        player.current?.setMuted(true);
      },
    };
  }, [media, onFullscreenExit]);

  useLayoutEffect(() => {
    if (vimeoReady && cover) {
      videobgEnlarge();
      window.addEventListener('resize', videobgEnlarge);
      return () => window.removeEventListener('resize', videobgEnlarge);
    }
  }, [vimeoReady, cover]);

  const videobgEnlarge = async () => {
    const w = await player.current.getVideoWidth();
    const h = await player.current.getVideoHeight();
    const videoAspect = h / w;
    const parentAspect = root.current.parentElement.offsetHeight / root.current.parentElement.offsetWidth;

    if (parentAspect > videoAspect) {
      setVideobgWidth((parentAspect / videoAspect) * 100 + '%');
      setVideobgHeight('100%');
    } else {
      setVideobgWidth('100%');
      setVideobgHeight((videoAspect / parentAspect) * 100 + '%');
    }
    setShow(true);
  };

  if (!media) {
    return null;
  }

  return (
    <div
      ref={root}
      className={classNames(
        'absolute left-1/2 top-1/2 h-full w-full -translate-x-2/4 -translate-y-2/4 transition-opacity duration-[2s]',
        !show && 'hidden',
        !unShowCoverImage && 'opacity-0',
      )}
      style={{ width: videobgWidth, height: videobgHeight }}
    >
      <div className="vimeo h-full w-full [&_iframe]:h-full [&_iframe]:w-full" ref={container} {...props} />
    </div>
  );
});

VimeoPlayer.propTypes = {
  media: PropTypes.object.isRequired,
  cover: PropTypes.bool,
  controls: PropTypes.bool,
};

export default VimeoPlayer;
