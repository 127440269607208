import { motion } from 'framer-motion';

const ComponentAnimation = ({ children, delay, ...props }) => {
  const transition = {
    duration: 0.8,
    type: 'tween',
    bounce: 0,
    ...(delay && { delay }),
  };

  return (
    <motion.div
      variants={{
        inactive: { opacity: 0, y: 20 },
        active: {
          opacity: 1,
          y: 0,
          transition,
        },
      }}
      {...props}
    >
      {children}
    </motion.div>
  );
};

export default ComponentAnimation;
