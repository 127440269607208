import { useContext } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import { CarouselContext } from '.';
import { useTranslation } from 'next-i18next';

const CarouselControls = ({ controls, width, length, loop, ...props }) => {
  const { currItem, setCurrItem } = useContext(CarouselContext);
  const { t } = useTranslation('common');

  const handleSwitch = (dir) => {
    let switchVal = (dir === 'next' ? 1 : -1) + currItem;

    const croppedVal = loop ? switchVal : Math.max(0, Math.min(length - 1, switchVal));

    setCurrItem(croppedVal);
  };

  const renderControls = ['prev', 'next'].map((val, i) => {
    const hide = (val === 'prev' ? currItem === 0 : currItem === length - 1) && !loop;

    return (
      <button
        key={`control${i}`}
        onClick={() => handleSwitch(val)}
        className={classNames(
          'carousel-control btn',
          [val],
          hide && controls?.controlStyle?.hide && controls?.controlStyle.hide,
          controls?.controlStyle && controls?.controlStyle[val],
        )}
        aria-label={val === 'prev' ? t('general.previous') : t('general.next')}
      >
        {controls?.directionComponent}
      </button>
    );
  });

  return (
    <motion.div
      {...props}
      className={classNames('carousel-control-wrapper flex flex-row items-start', controls?.wrapperStyle)}
      style={{ width: `${width}px` }}
    >
      {renderControls}
    </motion.div>
  );
};

export default CarouselControls;
