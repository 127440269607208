/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import Dropdown from './Dropdown';

const ChildInput = ({ name, placeholder, required, onChange, popoverProps }) => {
  const { t } = useTranslation('common');
  const [numberOfChildren, setNumberOfChildren] = useState('');
  const [childAges, setChildAges] = useState([]);

  const _setNumberOfChildren = (value) => {
    setNumberOfChildren(value);
    let newChildAges = [];
    for (let i = 0; i < value; i++) {
      newChildAges.push(childAges[i] || '');
    }
    setChildAges(newChildAges);

    _onChange(newChildAges, value);
  };

  const _setChildAges = (event) => {
    let newChildAges = [...childAges];
    newChildAges[event.target.name] = event.target.value;
    setChildAges(newChildAges);
    _onChange(newChildAges);
  };

  const _onChange = (newChildAges, mainDropdownValue) => {
    if (onChange) {
      const event = { target: { name, value: mainDropdownValue === '0' ? '0' : newChildAges.join(',') } };
      onChange(event);
    }
  };

  const options = [];
  for (let i = 0; i <= 10; i++) {
    options.push({ value: i ? i : i + '', label: i + '' });
  }

  const childAgedropdowns = [];
  for (let i = 1; i <= numberOfChildren; i++) {
    childAgedropdowns.push(i);
  }

  const childAgeOptions = [];
  for (let i = 0; i <= 11; i++) {
    childAgeOptions.push({
      value: i === 0 ? '<1' : i,
      label: t('forms.enquiryform.$xYearsOld', { x: i === 0 ? '<1' : i }),
    });
  }
  return (
    <div className="child-input flex flex-col gap-03">
      <Dropdown
        className="input dropdown"
        name={name}
        placeholder={placeholder}
        options={options}
        displayEmpty={!required}
        required={required}
        value={numberOfChildren}
        onChange={(e) => _setNumberOfChildren(e.target.value)}
        popoverProps={popoverProps}
      />
      {numberOfChildren > 0 && (
        <div className="flex flex-col gap-03">
          {childAges.map((value, index) => (
            <div className="flex flex-col gap-03 md:flex-row" key={index}>
              <div className="body-04 min-w-24">{t('forms.enquiryform.$childX', { x: index + 1 })}</div>
              <Dropdown
                className="input dropdown"
                name={index}
                placeholder={t('forms.enquiryform.$selectage')}
                options={childAgeOptions}
                required={required}
                value={value}
                onChange={(e) => _setChildAges(e)}
                popoverProps={popoverProps}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChildInput;
