import { useContext } from 'react';
import {
  carouselFocusAnimation,
  carouselBookcaseAnimation,
  carouselFadeAndScaleAnimation,
  carouselJaggedAnimation,
} from '@/animations/carousel';
import { getValueAtBreakpoint } from '@/utils';
import { CarouselContext } from '.';
import { motion } from 'framer-motion';
import classNames from 'classnames';

let offset = 0;

const itemHolder = (width, offset, style, loop, isActive) => {
  const addOffset = loop ? { transform: `translateX(${offset}px)` } : '';

  return {
    className: classNames('carousel-item flex flex-initial shrink-0', { active: isActive }),
    style: {
      ...style,
      width: `${width}px`,
      ...addOffset,
    },
  };
};

const CarouselItem = ({
  index,
  item,
  width,
  height,
  length,
  columnNum,
  loop = false,
  animationStyle,
  mappedLength,
  slideWidth,
  variant,
  isDragging,
  ...props
}) => {
  const { currItem, inactiveWidth, inactiveHeight, breakpoint, htmlDir, carouselWrapper } = useContext(CarouselContext);
  let isActive = currItem === index;
  const newInactiveWidth = getValueAtBreakpoint(inactiveWidth, breakpoint, width);
  const newInactiveHeight = getValueAtBreakpoint(inactiveHeight, breakpoint, height);

  if (loop) {
    const itemsLength = length + 1;
    const fullLength = itemsLength * 3;
    const groupOffset = Math.floor((currItem - index - 1) / fullLength);

    // reorders stack to faux infinite scroll
    if (slideWidth * mappedLength < carouselWrapper?.offsetParent?.clientWidth) {
      offset = 0;
    } else {
      offset =
        htmlDir === 'ltr'
          ? slideWidth * (fullLength * groupOffset + itemsLength * 2)
          : slideWidth * (fullLength * groupOffset + itemsLength * 2) * -1;
      isActive = currItem - fullLength * groupOffset - itemsLength * 2 === index;
    }
  }

  const itemAnimation = {
    primary: { initial: 'inactive', animate: isActive ? 'active' : 'inactive' },
    focus: carouselFocusAnimation(animationStyle, isActive, offset, loop),
    bookcase: carouselBookcaseAnimation(animationStyle, isActive, offset, loop),
    fadeInAndScale: carouselFadeAndScaleAnimation(animationStyle, isActive, offset, loop),
    jagged: carouselJaggedAnimation(
      animationStyle,
      index <= currItem,
      width,
      height,
      newInactiveWidth,
      newInactiveHeight,
    ),
    none: {},
  };

  const allProps = {
    ...itemHolder(width / columnNum, offset, props.style, loop, isActive),
    ...props,
    ...itemAnimation[variant],
  };

  return (
    <motion.div {...allProps} className={classNames('[&>div]:w-full', allProps.className)}>
      {item.type ? { ...item, props: { ...item.props, isDragging } } : null}
    </motion.div>
  );
};

export default CarouselItem;
