import twConfig from '/tailwind.config.js';

const backUpBreakpoints = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  xxl: '1536px',
};

const { screens: breakpoints } = twConfig.theme.extend;
const availBreakpoints = breakpoints ? Object.keys(breakpoints) : ['base', 'sm', 'md', 'lg', 'xl', 'xxl'];

const breakpointVals = breakpoints || backUpBreakpoints;
delete breakpointVals.full;

export const stripHtml = (html) => {
  return html?.replace(/(<([^>]+)>)/gi, '');
};

export const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const scrollIntoView = (element, offset = 0) => {
  if (element) {
    element.scrollIntoView();
    window.scrollTo({
      top: element.offsetTop - 110 + offset,
      behavior: 'smooth',
    });
  }
};

export const loadLazyImage = (element) => {
  if (element) {
    element.querySelectorAll('*').forEach((item) => {
      if (item.getAttribute('data-srcset')) {
        item.setAttribute('srcset', item.getAttribute('data-srcset'));
        item.removeAttribute('data-srcset');
      }
      if (item.getAttribute('data-src')) {
        item.setAttribute('src', item.getAttribute('data-src'));
        item.removeAttribute('data-src');
      }
    });
    element.classList.add('lazy-loaded');
  }
};

export const splitWords = (str) => {
  return str.split(' ');
};

export const isFunction = (value) =>
  value
    ? Object.prototype.toString.call(value) === '[object Function]' ||
      'function' === typeof value ||
      value instanceof Function
    : false;

const findLastNotGreater = (arr, value) => {
  let lastNotGreater = -1;

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] > value) {
      break;
    }
    lastNotGreater = arr[i];
  }

  return lastNotGreater;
};

export const getCurrentBreakpoint = (width) => {
  let currentBreakpoint = 'base';

  const breakpointNums = Object.values(breakpointVals).map((breakpoint) => {
    if (!breakpoint.includes('%')) {
      return parseInt(breakpoint);
    }
  });

  const breakpointValue = findLastNotGreater(breakpointNums, width);

  if (breakpointValue > -1) {
    currentBreakpoint = Object.keys(breakpointVals).filter((key) => breakpointVals[key] === `${breakpointValue}px`)[0];
  }

  return currentBreakpoint;
};

export const getValueAtBreakpoint = (values, breakpoint, percentageOf) => {
  let value = values;
  if (typeof values === 'object') {
    value = Object.values(values)[0];
    for (let i = 0; i < availBreakpoints.length; i++) {
      const val = availBreakpoints[i];
      if (values[val] && breakpoint !== 'base') value = values[val];
      if (val === breakpoint) {
        break;
      }
    }
  }

  if (percentageOf) {
    let percentOfVal = value;
    if (typeof value === 'number') {
      percentOfVal = value / percentageOf;
    }
    if (value.includes('%')) {
      const decimalise = parseInt(value.replace('%', '')) / 100;
      percentOfVal = decimalise * percentageOf;
    }
    return percentOfVal;
  }

  return value;
};

export const getFormattedValue = (value, type) => {
  if (type === 'leadingZeroNumbers') {
    return value < 9 ? `0${value + 1}` : value + 1;
  }
  return value + 1;
};

export const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};

export const setScrollbarPlaceholder = (size) => {
  const selectors = ['.page-container', 'header .content'];

  selectors.forEach((selector) => {
    document.querySelectorAll(selector).forEach((el) => {
      el.style.paddingRight = size ? `${size}px` : null;
    });
  });
};
