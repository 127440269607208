import { useEffect, useState } from 'react';
import { default as ReactPhoneInput } from 'react-phone-input-2';

const PhoneInput = ({ value, name, onChange, ...props }) => {
  const [country, setCountry] = useState(null);

  const _onChange = (value, country) => {
    const event = { target: { name, value: value } };
    if (onChange) {
      onChange(event);
      onChange({ target: { name: 'phoneCountryCode', value: country.dialCode } });
    }
  };

  useEffect(() => {
    async function fetchData() {
      const ip2c = await fetch('https://ip2c.org/s').catch((e) => {
        console.error(e);
      });
      if (ip2c.ok) {
        const ip2cData = await ip2c.text();
        const dataArray = ip2cData.split(';');
        if (dataArray && dataArray[1]) {
          setCountry(ip2cData.split(';')[1].toLowerCase());
        }
      }
    }
    fetchData();
  }, []);

  return (
    <ReactPhoneInput
      country={country}
      specialLabel={null}
      value={value}
      onChange={_onChange}
      inputProps={{ name, ...props, autoComplete: 'off' }}
      preferredCountries={['ch', 'fr', 'de', 'it']}
    />
  );
};

export default PhoneInput;
