import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage, Link, Stack, Box } from '..';
import classNames from 'classnames';
import { animControllerInView } from '@/animations';
import tailwindConfig from '@/tailwind.config.js';
import { useTranslation } from 'next-i18next';

const ExperienceCardLarge = ({ data, className, ...props }) => {
  const [desktop, setDesktop] = useState(true);
  const { t } = useTranslation('common');

  useEffect(() => {
    const resize = () => {
      setDesktop(window.innerWidth >= parseInt(tailwindConfig.theme.screens.lg, 10));
    };

    resize();

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  return (
    <div className={classNames('main-wrapper relative flex w-full flex-col', className)} {...props}>
      <div className="image-wrapper aspect-video w-full sm:aspect-[720/326] lg:absolute lg:h-full">
        {data.image && (
          <ResponsiveImage
            image={data.image}
            widths={{ xs: 590, sm: 900, md: 1080, lg: 1278, xl: 1500, xxl: 3125 }}
            heights={{ xs: 317, sm: 405, md: 489, lg: 740, xl: 875, xxl: 1280 }}
          />
        )}
      </div>
      <Stack
        animate={desktop}
        motion={animControllerInView({ once: false, amount: 0.3 })}
        className="flex h-full lg:-mb-px lg:-ml-px lg:!w-[calc(100%+1px)] lg:items-end"
      >
        <Box className="content-wrapper relative flex w-full flex-col justify-between gap-4 bg-silk px-04 py-04 sm:flex-row sm:gap-06 sm:pb-8 sm:pt-6 lg:hidden">
          <div className="text-wrapper flex flex-col gap-2 lg:w-1/2 lg:gap-1.5">
            {data.label && <p className="label-01 text-charcoal/50">{data.label}</p>}
            {data.title && (
              <p className="title-05 color-from-bg" role="heading" aria-level="7">
                {data.title}
              </p>
            )}
            <Link link={data.link} className="btn tertiary mt-02 hidden w-fit sm:block lg:hidden">
              {t('general.$explore')}
            </Link>
          </div>

          {data.description && (
            <div
              className="body-04 lg:body-03 text-charcoal/75 sm:w-[409px] md:mt-[55px] lg:w-1/2"
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          )}
          <Link link={data.link} className="btn tertiary w-fit py-02 sm:hidden">
            {t('general.$explore')}
          </Link>
        </Box>

        <Box className="content-wrapper relative hidden w-full flex-col justify-between gap-4 bg-silk px-04 py-04 sm:flex-row sm:gap-06 sm:pb-8 sm:pt-6 lg:flex">
          {data.link ? (
            <Link link={data.link} className="flex w-full">
              <div className="text-wrapper flex flex-col gap-2 lg:w-1/2 lg:gap-1.5">
                {data.label && <p className="label-01 text-charcoal/50">{data.label}</p>}
                {data.title && (
                  <p className="title-05 color-from-bg" role="heading" aria-level="7">
                    {data.title}
                  </p>
                )}
              </div>

              {data.description && (
                <div
                  className="body-04 lg:body-03 text-charcoal/75 sm:w-[409px] md:mt-[55px] lg:w-1/2"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                />
              )}
            </Link>
          ) : (
            <div className="flex w-full">
              <div className="text-wrapper flex flex-col gap-2 lg:w-1/2 lg:gap-1.5">
                {data.label && <p className="label-01 text-charcoal/50">{data.label}</p>}
                {data.title && (
                  <p className="title-05 color-from-bg" role="heading" aria-level="7">
                    {data.title}
                  </p>
                )}
              </div>

              {data.description && (
                <div
                  className="body-04 lg:body-03 text-charcoal/75 sm:w-[409px] md:mt-[55px] lg:w-1/2"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                />
              )}
            </div>
          )}
        </Box>
      </Stack>
    </div>
  );
};

ExperienceCardLarge.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ExperienceCardLarge;
