import { Carousel, ModuleBase, ResponsiveImage } from '@/components';
import Arrow from '../assets/carouselArrow.svg';
import AnimationStarter from '@/animations/animationComponent/AnimationStarter';

const HeroCarouselCentreAlignedModule = ({ data }) => {
  const items = data.mediaSlides.map((slide, index) => (
    <div key={index} className="relative w-full">
      <div className="h-[233px] md:aspect-11/5 md:h-[unset]">
        <ResponsiveImage
          image={slide.image}
          widths={{ xs: 375, sm: 640, md: 768, lg: 1024, xl: 1440, xxl: 1920 }}
          heights={{ xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto', xl: 'auto', xxl: 'auto' }}
          className="pointer-events-none"
        />
      </div>

      <div className="label-01 absolute bottom-0 left-0 !z-30 flex h-[62px] w-full items-center justify-center bg-bone px-4 text-center text-charcoal/50 md:h-22 lg:h-[67px]">
        <p className="line-clamp-1">{slide.caption}</p>
      </div>
    </div>
  ));

  return (
    <ModuleBase data={data} className="relative overflow-hidden">
      <AnimationStarter>
        <Carousel
          moduleId={data.moduleId}
          items={items}
          variant="bookcase"
          animationStyle="elegant"
          showPagination
          loop
          paginationType="dots"
          controls={{
            show: true,
            directionComponent: <Arrow />,
            controlStyle: {
              prev: 'pagination silk left pointer-events-auto',
              next: 'pagination silk right pointer-events-auto [&_svg]:rotate-180',
            },
            wrapperStyle: 'absolute justify-between top-[50%] -translate-y-[50%] pointer-events-none hidden lg:flex',
          }}
          className="[&_.carousel-pagination-step.active]:bg-silk [&_.carousel-pagination-step]:bg-silk/50 [&_.carousel-pagination]:absolute [&_.carousel-pagination]:bottom-[72px] [&_.carousel-pagination]:left-1/2 [&_.carousel-pagination]:-translate-x-1/2 [&_.carousel-pagination]:gap-4 md:[&_.carousel-pagination]:bottom-[105px] lg:[&_.carousel-pagination]:bottom-[98px]"
        />
      </AnimationStarter>
    </ModuleBase>
  );
};
export default HeroCarouselCentreAlignedModule;
