import { Link, ModalPortal } from '@/components';
import CloseIcon from '@/assets/lightboxClose.svg';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useDimensions } from '@/hooks';
import { useSelector } from 'react-redux';
import { selectNavVisible } from '@/store';
import classNames from 'classnames';

const NotificationBar = ({ data }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { title, text, primaryCTA, secondaryCTA } = data || {};
  const { breakpoint } = useDimensions();
  const navVisible = useSelector(selectNavVisible);

  const notificationBarVariants = !['base', 'sm', 'md'].includes(breakpoint)
    ? {
        inactive: { y: 50, opacity: 0, transition: { type: 'spring', stiffness: 70, damping: 30 } },
        active: { y: 0, opacity: 1, transition: { type: 'spring', stiffness: 70, damping: 30, delay: 1.5 } },
      }
    : {
        inactive: { opacity: 0, y: 50 },
        active: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 70, damping: 30, delay: 0.5 } },
      };

  return (
    <ModalPortal>
      <div
        className={classNames(
          'fixed w-full px-4 transition-all duration-700 md:px-6 lg:px-10',
          navVisible ? 'top-24 lg:top-[168px]' : 'top-4 lg:top-[88px]',
        )}
      >
        <AnimatePresence>
          {isOpen && (
            <motion.div
              variants={notificationBarVariants}
              initial="inactive"
              animate="active"
              exit="inactive"
              transition={{ type: 'spring', stiffness: 90, damping: 30 }}
              className="origin-center"
            >
              <div className="mx-auto flex w-full max-w-[1432px] flex-col bg-black/50 p-4 pb-6 font-montserrat text-silk backdrop-blur-[6px] md:p-6 md:pt-4 xl:flex-row xl:items-center xl:justify-between xl:py-2 xl:pl-10 xl:pr-4">
                <div className="mb-04 flex w-full items-center justify-between lg:mb-5 xl:mb-0">
                  {title && (
                    <p className="text-base font-semibold leading-5 tracking-[0.01em] ltr:xl:mr-8 rtl:xl:ml-8">
                      {title}
                    </p>
                  )}
                  <button className="xl:hidden" onClick={() => setIsOpen(false)}>
                    <CloseIcon role="presentation" className="size-8" />
                  </button>
                  {text && <p className="body-03 hidden text-silk/75 xl:block xl:max-w-[40%]">{text}</p>}
                  {(primaryCTA || secondaryCTA) && (
                    <div className="hidden xl:flex ltr:ml-auto rtl:mr-auto">
                      {primaryCTA && (
                        <Link
                          link={primaryCTA}
                          className="btn primary dark ltr:has-[+.btn.secondary.dark]:mr-2 rtl:has-[+.btn.secondary.dark]:ml-2"
                        />
                      )}
                      {secondaryCTA && <Link link={secondaryCTA} className="btn secondary dark" />}
                    </div>
                  )}
                </div>

                {text && <p className="body-03 text-silk/75 xl:hidden">{text}</p>}

                {(primaryCTA || secondaryCTA) && (
                  <div className="mt-8 flex md:mt-5 xl:hidden">
                    {primaryCTA && (
                      <Link
                        link={primaryCTA}
                        className="btn primary dark ltr:has-[+.btn.secondary.dark]:mr-2 rtl:has-[+.btn.secondary.dark]:ml-2"
                      />
                    )}
                    {secondaryCTA && <Link link={secondaryCTA} className="btn secondary dark" />}
                  </div>
                )}
                <button className="hidden size-12 p-2 xl:block ltr:ml-10 rtl:mr-10" onClick={() => setIsOpen(false)}>
                  <CloseIcon role="presentation" className="size-8" />
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </ModalPortal>
  );
};

export default NotificationBar;
