import React from 'react';
import { HeadingTag, ModuleBase, Link, Stack, Text } from '@/components';
import { isColorDark } from '@/utils';
import classNames from 'classnames';

const CTabreakModule = ({ data }) => {
  const isDark = isColorDark(data.backgroundColour);
  return (
    <ModuleBase
      data={data}
      className={classNames('!p-2 text-center md:!p-4 lg:!p-6', isDark ? 'text-silk' : 'text-charcoal')}
    >
      <Stack
        className={classNames(
          'container flex flex-col items-center justify-center gap-5 border px-4 py-8 md:pb-14 md:pt-[50px] lg:gap-7',
          isDark ? 'border-silk/50' : 'border-charcoal/50',
        )}
      >
        <div className="flex flex-col gap-4 md:w-[470px] lg:w-[700px] lg:gap-5">
          {data.headingTitle && (
            <HeadingTag animate data={data.headingTitle} className="title-05 md:title-04"></HeadingTag>
          )}
          {data.text && (
            <Text
              dangerouslySetInnerHTML={{ __html: data.text }}
              className={classNames(
                'body-04 md:body-03',
                isDark ? 'text-silk/75' : '!text-charcoal/75 lg:!text-charcoal',
              )}
            ></Text>
          )}
        </div>
        {(data.primaryCTA || data.secondaryCTA) && (
          <div className="flex flex-col items-center gap-2 md:flex-row lg:items-center">
            <Link animate className={classNames('btn primary', isDark ? 'dark' : 'light')} link={data.primaryCTA} />
            <Link animate className={classNames('btn secondary', isDark ? 'dark' : 'light')} link={data.secondaryCTA} />
          </div>
        )}
      </Stack>
    </ModuleBase>
  );
};

export default CTabreakModule;
