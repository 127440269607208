import React, { useRef, useEffect } from 'react';
import { ModuleBase, Link, Text, HeadingTag } from '@/components';
import Flicking, { FlickingError, ViewportSlot } from '@egjs/react-flicking';
import { Pagination, AutoPlay } from '@egjs/flicking-plugins';
import '@egjs/flicking-plugins/dist/pagination.css';
import '@egjs/react-flicking/dist/flicking.css';
import Arrow from '@/assets/arrow.svg';
import { stripHtml } from '@/utils';
import { fadeInRight } from '@/animations';
import classNames from 'classnames';

const QuoteCarouselModule = ({ data }) => {
  const carousel = useRef();

  useEffect(() => {
    carousel.current.on('ready', (e) => {
      e.currentTarget.addPlugins(
        new Pagination({
          type: 'bullet',
          renderBullet: (className) => `<span class="${className}" aria-label="Go to slide" role="button"></span>`,
        }),
      );
      if (data?.autoPlay) {
        e.currentTarget.addPlugins(new AutoPlay({ duration: 2000, direction: 'NEXT', stopOnHover: false }));
      }
    });
  }, [data?.autoPlay]);

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };

  return (
    <ModuleBase data={data} className="relative">
      <div className="m-auto max-w-[1304px] px-6 md:px-10">
        <Flicking ref={carousel} align="center" useResizeObserver={true} horizontal={true} circular={true}>
          {data.quotes.map((slide, index) => (
            <div key={index} className="mx-10 w-full">
              <div className="title-wrapper">
                {slide.preHeading && (
                  <Text
                    mottion={fadeInRight}
                    className="text-subheading font-montserrat font-semibold uppercase text-grey3 sm:text-base"
                  >
                    {slide.preHeading}
                  </Text>
                )}
                {slide?.quoteTitle?.heading && (
                  <HeadingTag
                    animate
                    data={slide.quoteTitle}
                    className="text-h2 color-from-bg mb-6 mt-2 font-montserrat font-light md:mt-4 lg:mb-10"
                  />
                )}
              </div>

              <div className={classNames('quote-wrapper')}>
                {slide.quote && (
                  <Text
                    dangerouslySetInnerHTML={{ __html: '"' + stripHtml(slide.quote) + '"' }}
                    className="mb-6 font-montserrat text-heading6 leading-heading6 md:text-heading5 md:leading-heading3 lg:text-heading4 xl:mb-0 xl:max-w-[912px]"
                  />
                )}
                <div className={classNames('author-source-wrapper')}>
                  {slide.author && (
                    <Text
                      dangerouslySetInnerHTML={{ __html: slide.author }}
                      className="mb-2 ml-auto w-fit font-montserrat text-base font-semibold text-grey1"
                    />
                  )}
                  {slide.source && (
                    <Text
                      dangerouslySetInnerHTML={{ __html: slide.source }}
                      className="ml-auto w-fit font-montserrat text-base text-grey1"
                    />
                  )}
                  <div className="mt-20"></div>
                </div>
              </div>
            </div>
          ))}
          <ViewportSlot>
            <div className="flicking-pagination relative !w-fit pb-[5px] md:pb-0 [&_.flicking-pagination-bullet.flicking-pagination-bullet-active]:bg-grey1 [&_.flicking-pagination-bullet]:bg-grey4"></div>
          </ViewportSlot>
        </Flicking>
        <div className="-mt-10 flex justify-end gap-3">
          <button
            className="btn text circle z-10 h-10 w-10 border p-0 sm:h-12 sm:w-12"
            onClick={move}
            aria-label="Prevues"
          >
            <Arrow className="rotate-180" />
          </button>

          <button
            className="btn text circle z-10 h-10 w-10 border p-0 sm:h-12 sm:w-12"
            onClick={() => move(1)}
            aria-label="Next"
          >
            <Arrow />
          </button>
        </div>
        <div className="mt-10 text-right">
          {data.primaryCTA && (
            <Link
              className="btn primary h-10 self-end px-2 text-sm first:mr-4 md:h-12 md:px-2.5 md:text-base"
              link={data.primaryCTA}
            />
          )}

          {data.secondaryCTA && (
            <Link
              className="btn secondary h-10 self-end px-2 text-sm md:h-12 md:px-2.5 md:text-base"
              link={data.secondaryCTA}
            />
          )}
        </div>
      </div>
    </ModuleBase>
  );
};
export default QuoteCarouselModule;
