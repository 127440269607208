import { ModuleBase, ResponsiveImage, Link, Text, HeadingTag, Box } from '@/components';

import classNames from 'classnames';

const BannerSlimModule = ({ data }) => {
  const { headingTitle, description, preHeading, subHeading, primaryCTA, secondaryCTA } = data || {};

  return (
    <ModuleBase data={data} className="pb-0 pt-0 font-montserrat">
      <div
        className={classNames(
          'main-wrapper relative h-screen',
          data.primaryCTA || data.secondaryCTA ? 'max-h-[622px]' : 'max-h-[464px]',
        )}
      >
        <ResponsiveImage
          image={data.backgroundMedia}
          widths={{ xs: 327, md: 1024, lg: 1440, xl: 1900 }}
          className="overlay"
        />

        <div className="relative-box container relative z-10">
          <div className="title-wrapper absolute bottom-10 pr-6 md:w-[480px] lg:bottom-20 lg:w-[660px]">
            <div className="title-wrapper" role="contentinfo" aria-label="contentinfo">
              {preHeading && (
                <Text className="text-subheading my-4 font-montserrat text-base font-semibold uppercase text-white">
                  {preHeading}
                </Text>
              )}
              {headingTitle && (
                <HeadingTag
                  data={headingTitle}
                  className="title color-from-bg mt-7 font-montserrat text-heading2 leading-heading2 !text-white"
                />
              )}
              {subHeading && (
                <Text className="text-subheading my-4 font-montserrat text-base font-semibold text-white">
                  {subHeading}
                </Text>
              )}
            </div>

            {(description || primaryCTA || secondaryCTA) && (
              <div
                className={classNames(
                  'color-from-bg mt-5 text-paragraph',
                  primaryCTA || secondaryCTA ? 'mb-4' : 'mb-0',
                )}
              >
                <Text dangerouslySetInnerHTML={{ __html: description }} className="text-white" />

                {(primaryCTA || secondaryCTA) && (
                  <Box className="mt-10 flex justify-start gap-4 sm:mt-16 lg:mt-10">
                    <Link className="btn primary self-end" link={primaryCTA} />

                    <Link className="btn secondary self-end" link={secondaryCTA} />
                  </Box>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </ModuleBase>
  );
};

export default BannerSlimModule;
