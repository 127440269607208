import React, { useEffect, useState } from 'react';
import { ModuleBase } from '@/components';

const NoHeroModule = ({ data }) => {
  const [heightClass, setHeightClass] = useState('h-[88px] md:h-[89px]');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const hotelNav = document.querySelector('.hotel-nav');

      if (hotelNav) {
        setHeightClass('h-[88px] md:h-[89px] lg:h-[147px]');
      }
    }
  }, []);

  return <ModuleBase data={data} className={heightClass} />;
};

export default NoHeroModule;
