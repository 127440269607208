import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FlickingError, EVENTS, DIRECTION } from '@egjs/react-flicking';
import { useTranslation } from 'next-i18next';
import CarouselArrow from '@/assets/carouselArrow.svg';
import classNames from 'classnames';

const CarouselPagination = ({ carousel, type = 'Charcoal', className, ...props }) => {
  const [disabledNext, setDisableNext] = useState(false);
  const [disabledPrevious, setDisabledPrevious] = useState(true);
  const { t } = useTranslation('common');

  useEffect(() => {
    const _carousel = carousel.current;

    if (_carousel && !_carousel.circular) {
      const calcProgress = () => {
        const currentAnchor = _carousel.camera.anchorPoints.find(
          (anchorPoint) => anchorPoint.position === _carousel.camera.position,
        );
        const progress = Math.round(((currentAnchor.index + 1) * 100) / _carousel.camera.anchorPoints.length);
        if (progress === 100) {
          setDisableNext(true);
        }
      };

      _carousel.on(EVENTS.READY, calcProgress);

      _carousel.on(EVENTS.CHANGED, calcProgress);

      _carousel.on(EVENTS.WILL_CHANGE, () => {
        setDisableNext(false);
        setDisabledPrevious(false);
      });

      _carousel.on(EVENTS.REACH_EDGE, ({ direction }) => {
        if (direction === DIRECTION.NEXT) {
          setDisableNext(true);
          setDisabledPrevious(false);
        } else if (direction === DIRECTION.PREV) {
          setDisableNext(false);
          setDisabledPrevious(true);
        }
      });
    } else {
      setDisabledPrevious(false);
    }
    return () => {
      if (_carousel && !_carousel.circular) {
        _carousel.off(EVENTS.READY);
        _carousel.off(EVENTS.WILL_CHANGE);
        _carousel.off(EVENTS.CHANGED);
        _carousel.off(EVENTS.REACH_EDGE);
      }
    };
  }, [carousel]);

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };

  return (
    <div
      className={classNames(
        'pointer-events-none absolute top-0 z-10 hidden h-full w-full items-center justify-between gap-9 lg:flex',
        className,
      )}
      {...props}
    >
      <button
        className={classNames('btn pagination left', type.toLowerCase())}
        onClick={move}
        aria-label={t('general.previous')}
        disabled={disabledPrevious}
      >
        <CarouselArrow className="rtl:rotate-180" />
      </button>

      <button
        className={classNames('btn pagination right', type.toLowerCase())}
        onClick={() => move(1)}
        aria-label={t('general.next')}
        disabled={disabledNext}
      >
        <CarouselArrow className="rotate-180 rtl:rotate-0" />
      </button>
    </div>
  );
};

CarouselPagination.propTypes = {
  carousel: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['Charcoal', 'Silk', 'Coastal', 'Mountain', 'Urban']),
};

export default CarouselPagination;
