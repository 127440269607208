import React, { useState, useEffect } from 'react';
import { ModuleBase, HeadingTag, Stack, Text, Box, ResponsiveImage, Link } from '@/components';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import Facebook from '@/assets/facebook.svg';
import Linkedin from '@/assets/linkedin.svg';
import Twitter from '@/assets/twitter.svg';
import Mail from '@/assets/mail.svg';
import { format } from 'date-fns';
import { isColorDark } from '@/utils';

const HeroArticleModuleModel = ({ data }) => {
  const { title, author, date, label } = data;
  const [shareURL, setShareURL] = useState();
  const { t } = useTranslation('common');

  useEffect(() => {
    setShareURL(encodeURIComponent(window.location.href));
  }, []);

  return (
    <ModuleBase data={data} className="color-from-bg pb-10 pt-[128px]">
      <Stack className="color-from-bg container flex flex-col gap-y-06 md:flex-row md:justify-between md:gap-x-[68px]">
        <div className="flex flex-col justify-center md:w-[332px] lg:w-[727px]">
          {label && <Text className="label-01 color-from-bg mb-1 !text-opacity-50">{label}</Text>}
          {title && (
            <HeadingTag animate data={title} className="title-03 color-from-bg mb-[12px] font-[300] md:mb-[18px]" />
          )}
          {data.description && (
            <Text className="color-from-bg mb-6" dangerouslySetInnerHTML={{ __html: data.description }} />
          )}

          <div
            className={classnames('flex flex-col', isColorDark(data.backgroundColour) ? 'text-white' : 'text-[#333]')}
          >
            <div className="">
              <Box className="mb-[8px] flex md:mb-[18px]">
                <Text className="label-01 color-from-bg mr-6 flex items-center justify-center">
                  {format(date, 'dd.LL.yyyy')}
                </Text>
                <Text className="body-03 color-from-bg">{t('general.$published')}</Text>
                <Link className="body-03 color-from-bg ml-2" link={author.link}>
                  {author.label}
                </Link>
              </Box>

              <Box className="flex items-center">
                <a
                  href={`https://twitter.com/intent/tweet?url=${shareURL}`}
                  target="_blank"
                  className="my-3 mr-1.5"
                  title="Twitter"
                >
                  <Twitter
                    className={isColorDark(data.backgroundColour) ? '[&_path]:fill-silk' : '[&_path]:fill-charcoal'}
                  />
                </a>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${shareURL}`}
                  target="_blank"
                  className="mx-1.5 my-3"
                  title="Facebook"
                >
                  <Facebook
                    className={isColorDark(data.backgroundColour) ? '[&_path]:fill-silk' : '[&_path]:fill-charcoal'}
                  />
                </a>
                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareURL}`}
                  target="_blank"
                  className="mx-1.5 my-3"
                  title="Linkedin"
                >
                  <Linkedin
                    className={isColorDark(data.backgroundColour) ? '[&_path]:fill-silk' : '[&_path]:fill-charcoal'}
                  />
                </a>
                <a
                  href={`mailto:?subject=I wanted you to see this site&amp;body=Check out this site ${shareURL}.`}
                  target="_blank"
                  className="mx-1.5 my-3"
                  title="Mail"
                >
                  <Mail
                    className={isColorDark(data.backgroundColour) ? '[&_path]:stroke-silk' : '[&_path]:stroke-charcoal'}
                  />
                </a>
              </Box>
            </div>
          </div>
        </div>

        <div>
          <div className="aspect-[343/400] md:aspect-[320/400] md:h-[400px] md:w-[320px] lg:aspect-[577/721] lg:h-[721px] lg:w-[577px]">
            <ResponsiveImage
              image={data.image}
              widths={{ xs: 515, md: 480, lg: 866, xl: 866 }}
              heights={{ xs: 600, md: 600, lg: 1082, xl: 1082 }}
              className="h-full w-full object-cover"
            />
          </div>
        </div>
      </Stack>

      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </ModuleBase>
  );
};

export default HeroArticleModuleModel;
