import PropTypes from 'prop-types';
import { ModuleBase, Stack, Box, ResponsiveImage } from '@/components';
import { isColorDark } from '@/utils';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

const ArticleImageContainer = ({ data }) => {
  const isDark = isColorDark(data.backgroundColour);
  const calculatedImageWidth = useRef();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const hasSecondImage = data.images.length > 1;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;
    const secondImageWidth = windowWidth > 640 ? 244 : 122;
    const gap = 8;
    const secondImageWidthAndGap = hasSecondImage ? secondImageWidth + gap : 0;
    const padding = 50;
    calculatedImageWidth.current = windowWidth - padding - secondImageWidthAndGap;
    setIsLoaded(true);
  }, []);

  const isSafari = () => {
    const ua = navigator.userAgent;
    return ua.includes('Safari') && !ua.includes('Chrome') && !ua.includes('CriOS');
  };

  return (
    <ModuleBase data={data} className={classNames(isDark ? 'text-silk' : 'text-charcoal', '!pb-[40px] lg:px-28')}>
      {isLoaded && (
        <Stack
          className={classNames([
            'container flex aspect-[343/128] h-auto w-full max-w-[994px] flex-row gap-2 sm:aspect-[720/305] lg:aspect-[944/471]',
            isSafari ? 'h-[140px] sm:h-[305px] lg:h-[471px]' : 'h-[140px]',
            { 'akarmi !h-[192px] md:!h-[405px] lg:!h-[514px]': data.images.length === 1 },
          ])}
        >
          {data.images.map((image, imageIndex) => (
            <Box
              key={imageIndex}
              className={classNames([
                data.images.length > 1 && imageIndex === 0
                  ? 'w-[128%] sm:w-[154%] lg:w-[154%]'
                  : isSafari
                    ? 'min-w-[122px] sm:min-w-[244px] lg:min-w-[354px]'
                    : 'min-w-[122px] sm:min-w-[244px]',
                { 'w-full': data.images.length === 1 },
              ])}
            >
              {data.images.length > 1 ? (
                <>
                  <ResponsiveImage
                    image={image.image}
                    widths={{
                      xs: imageIndex === 0 ? calculatedImageWidth.current : 122,
                      sm: imageIndex === 0 ? calculatedImageWidth.current : 244,
                      md: imageIndex === 0 ? calculatedImageWidth.current : 244,
                      lg: imageIndex === 0 ? 582 : 354,
                      xl: imageIndex === 0 ? 582 : 354,
                      xxl: imageIndex === 0 ? 582 : 354,
                    }}
                    heights={{ xs: 140, sm: 305, md: 305, lg: 471, xl: 471, xxl: 471 }}
                    className={''}
                  />
                  {image.caption && <p className="label-01 mt-4 line-clamp-1">{image.caption}</p>}
                </>
              ) : (
                <div className="h-[192px] md:h-[405px] lg:h-[514px]">
                  <ResponsiveImage
                    image={image.image}
                    widths={{
                      xs: 430,
                      sm: 768,
                      md: 914,
                      lg: 914,
                      xl: 914,
                      xxl: 914,
                    }}
                    heights={{ xs: 192, sm: 405, md: 405, lg: 514, xl: 514, xxl: 514 }}
                    className={''}
                  />
                  {image.caption && <p className="label-01 mt-4 line-clamp-1">{image.caption}</p>}
                </div>
              )}
            </Box>
          ))}
        </Stack>
      )}
    </ModuleBase>
  );
};

ArticleImageContainer.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ArticleImageContainer;
