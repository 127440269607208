import { ModuleBase, Link, Text, HeadingTag, Box, Stack } from '@/components';
import { isColorDark } from '@/utils';
import classNames from 'classnames';

const HeadingSideModule = ({ data }) => {
  const isDark = isColorDark(data.backgroundColour);
  const { headingTitle, description, preHeading, primaryCTA, secondaryCTA } = data || {};
  return (
    <ModuleBase data={data}>
      <Stack className="container">
        <div className="text-wrapper flex flex-col lg:flex-row lg:justify-between">
          <div className="title-wrapper lg:w-[456px]" role="contentinfo" aria-label="contentinfo">
            {preHeading && <Text className="color-from-bg label-01 mb-[5px] !opacity-50 lg:mb-2">{preHeading}</Text>}
            {headingTitle && <HeadingTag animate data={headingTitle} className="title-04 color-from-bg mb-05" />}

            {description && (
              <Text dangerouslySetInnerHTML={{ __html: description }} className="color-from-bg body-03 !opacity-75" />
            )}
          </div>

          {(primaryCTA || secondaryCTA) && (
            <Box className="mt-05 flex flex-col gap-2 md:flex-row lg:mt-0 lg:items-end">
              <Link animate className={classNames('btn primary', isDark ? 'dark' : 'light')} link={data.primaryCTA} />
              <Link
                animate
                className={classNames('btn secondary', isDark ? 'dark' : 'light')}
                link={data.secondaryCTA}
              />
            </Box>
          )}
        </div>
      </Stack>
    </ModuleBase>
  );
};

export default HeadingSideModule;
