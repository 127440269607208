import React, { useEffect, useState } from 'react';
import { PopoverMenu } from '../index';
import classNames from 'classnames';

const Dropdown = ({
  name,
  options,
  value,
  placeholder,
  onChange,
  onBlur,
  displayEmpty,
  className,
  popoverProps,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [_value, set_value] = useState(value);
  const [title, setTitle] = useState();

  const _onChange = (option) => {
    set_value(option.value);
    const event = { target: { name, value: option.value } };
    if (onChange) {
      onChange(event);
    }
    setOpen(false);
    if (onBlur) {
      setTimeout(() => {
        onBlur(event);
      }, 0);
    }
  };

  useEffect(() => {
    if (_value && options) {
      const option = options.find((option) => option.value === _value);
      setTitle(option?.label);
    }
  }, [_value, options]);

  useEffect(() => {
    if (value !== null && value !== undefined) {
      set_value(value);
    } else {
      set_value(null);
      setTitle(null);
    }
  }, [value]);

  return (
    <PopoverMenu
      open={open}
      title={title || placeholder}
      titleClass={!title && placeholder && 'placeholder'}
      bgClass={'bg-transparent'}
      onOpen={() => setOpen(true)}
      className={classNames(title && 'selected', className)}
      {...props}
      {...popoverProps}
    >
      <ul className="text-gray-700 dark:text-gray-200 w-full py-2 text-sm">
        {displayEmpty && (
          <li onClick={() => _onChange({})}>
            <div className="option cursor-pointe block px-4 py-2">&nbsp;</div>
          </li>
        )}
        {options &&
          options.map((option) => (
            <li key={option.value} onClick={() => _onChange(option)}>
              <div
                className="option block cursor-pointer px-03 py-3 capitalize"
                dangerouslySetInnerHTML={{ __html: option.label?.replace(/<(p)>/g, '').replace(/<\/(p)>/g, '') }}
              />
            </li>
          ))}
      </ul>
    </PopoverMenu>
  );
};

export default Dropdown;
