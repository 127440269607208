import classNames from 'classnames';
import LoadingImage from '@/assets/loadingImage.svg';

const Skeleton = ({ image, className, ...props }) => {
  return (
    <div className={classNames('animate-pulse', className)}>
      <div className="flex h-full w-full items-center justify-center rounded bg-grey5" {...props}>
        {image && <LoadingImage className="w-1/6 fill-grey4" />}
      </div>
    </div>
  );
};

export default Skeleton;
