import { useRef } from 'react';
import { motion, useScroll, useTransform, useSpring, useReducedMotion } from 'framer-motion';

const ParallaxAnimation = ({ children, offset = 50 }) => {
  const prefersReducedMotion = useReducedMotion();
  const ref = useRef(null);

  const { scrollYProgress } = useScroll({ target: ref, offset: ['start end', 'end start'] });
  const yRange = useTransform(scrollYProgress, [0, 1], [-offset, offset]);

  const y = useSpring(yRange, { stiffness: 400, damping: 90 });

  if (prefersReducedMotion) {
    return <>{children}</>;
  }

  return (
    <motion.div ref={ref} style={{ y }}>
      <div className="scale-[1.75] sm:scale-125 [&_.vimeo]:scale-[1.75] sm:[&_.vimeo]:scale-125">{children}</div>
    </motion.div>
  );
};

export default ParallaxAnimation;
