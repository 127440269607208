import { ModuleBase, Link, Text, HeadingTag, Box } from '@/components';
import classNames from 'classnames';

const ListRichTextModule = ({ data }) => {
  const { headingTitle, description, preHeading, subHeading, primaryCTA, secondaryCTA } = data || {};
  return (
    <ModuleBase data={data}>
      <div
        className={classNames(
          'main-wrapper container flex font-montserrat text-black',
          data.statsAlignment === 'Left'
            ? 'flex-col-reverse gap-10 lg:flex-row-reverse'
            : 'flex-col gap-14 lg:flex-row',
          'md:flex-col md:gap-10 lg:items-center lg:justify-between lg:gap-32',
        )}
      >
        <div
          className={classNames(
            'text-wrapper flex w-full flex-col',
            data.statsAlignment === 'Left' ? 'md:items-end' : 'md:items-start',
            'lg:w-1/2 lg:items-start',
          )}
        >
          <div className="title-wrapper" role="contentinfo" aria-label="contentinfo">
            {preHeading && (
              <Text className="text-subheading my-4 font-montserrat text-base font-semibold uppercase text-black">
                {preHeading}
              </Text>
            )}
            {headingTitle && (
              <HeadingTag
                data={headingTitle}
                className="title color-from-bg mt-7 font-montserrat text-heading2 leading-heading2"
              />
            )}
            {subHeading && (
              <Text className="text-subheading my-4 font-montserrat text-base font-semibold text-black">
                {subHeading}
              </Text>
            )}
          </div>
          {(description || primaryCTA || secondaryCTA) && (
            <div className="color-from-bg mb-4 mt-5 text-paragraph">
              <Text dangerouslySetInnerHTML={{ __html: description }} />

              {(primaryCTA || secondaryCTA) && (
                <Box className="mt-10 flex justify-start gap-4 sm:mt-16 lg:mt-10">
                  <Link className="btn primary self-end" link={primaryCTA} />

                  <Link className="btn secondary self-end" link={secondaryCTA} />
                </Box>
              )}
            </div>
          )}
        </div>
        <div
          className={classNames(
            'stats-wrapper flex flex-col gap-10 lg:w-1/2',
            data.statsAlignment === 'Left' ? 'md:pl-[119px] lg:pl-20' : 'md:pl-[237px] lg:pl-0',
          )}
        >
          {data?.statElements?.map((el, index) => (
            <div className="stat-elemet flex flex-col gap-2" key={index}>
              <div className="font-normal lg:text-small-paragraph">{el.statisticsLabel}</div>
              <div className="text-h4">{el.statistics}</div>
            </div>
          ))}
        </div>
      </div>
    </ModuleBase>
  );
};

export default ListRichTextModule;
