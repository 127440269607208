import React, { useState } from 'react';
import { ModuleBase, HeadingTag, Link, Stack, Box, Text } from '@/components';
import ArrowForward from '@/assets/arrowForward.svg';
import { isColorDark } from '@/utils';
import classnames from 'classnames';

const AccordionModule = ({ data }) => {
  const [activeIndexList, setActiveIndexList] = useState([]);
  const isDark = isColorDark(data.backgroundColour);

  const toggle = (index) => {
    let newArray = [...activeIndexList];
    if (newArray.includes(index)) {
      newArray = newArray.filter((i) => i !== index);
    } else {
      if (data.allowOpeningMultiple) {
        newArray = [index, ...newArray];
      } else {
        newArray = [index];
      }
    }
    setActiveIndexList(newArray);
  };

  return (
    <ModuleBase data={data} className={'py-10 md:py-14 lg:py-28'}>
      <Stack className="container text-charcoal lg:max-w-[1188px]">
        <div className="title-wrapper text-center">
          {data.preHeading && <Text className="label-01 color-from-bg mb-2 !opacity-50">{data.preHeading}</Text>}
          {data.headingTitle && (
            <HeadingTag animate data={data.headingTitle} className="color-from-bg title-04 mb-8 font-[300] lg:mb-10" />
          )}
        </div>

        {data.accordionElements && (
          <div className="">
            {data.accordionElements.map((element, index) => (
              <Box
                key={index}
                className="border border-x-0 border-t-0 border-charcoal/25 first:border-t-[1px] first:border-t-charcoal/25"
              >
                <button
                  type="button"
                  className="flex w-full items-center justify-between pb-7 pt-8 text-left"
                  onClick={() => toggle(index)}
                >
                  <span className="body-02 color-from-bg font-[600]">{element.title}</span>
                  <div>
                    <ArrowForward
                      role="presentation"
                      className={classnames(
                        'transition-transform duration-200',
                        isDark ? 'fill-silk' : 'fill-charcoal',
                        activeIndexList.includes(index) ? '-rotate-180' : '',
                      )}
                    />
                  </div>
                </button>

                <div
                  className={classnames(
                    'grid transition-all duration-500',
                    activeIndexList.includes(index) ? 'grid-rows-[1fr] pb-[13px]' : 'grid-rows-[0fr]',
                  )}
                >
                  {element.text && (
                    <div
                      dangerouslySetInnerHTML={{ __html: element.text }}
                      className="richtext-content body-03 color-from-bg overflow-hidden font-normal ltr:pr-4 ltr:md:pr-5 ltr:lg:pr-28 rtl:pl-4 rtl:md:pl-5 rtl:lg:pl-28 [&_li::before]:mx-3 [&_li::before]:mt-2 [&_li::before]:block [&_li::before]:size-[2.5px] [&_li::before]:shrink-0 [&_li::before]:rounded-full [&_li::before]:bg-current [&_li::before]:content-[''] [&_li::before]:xxl:mt-3 [&_li]:flex [&_li]:items-start [&_p]:mt-0 [&_ul]:list-none [&_ul]:p-0"
                    />
                  )}
                </div>
              </Box>
            ))}
          </div>
        )}

        <div className="mt-8 flex flex-col items-center gap-2 md:flex-row md:justify-center lg:mt-10">
          <Link animate className={classnames('btn primary w-fit', isDark ? 'dark' : 'light')} link={data.primaryCTA} />
          <Link
            animate
            className={classnames('btn secondary w-fit', isDark ? 'dark' : 'light')}
            link={data.secondaryCTA}
          />
        </div>
      </Stack>
    </ModuleBase>
  );
};

export default AccordionModule;
