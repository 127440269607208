import { ModuleBase, Media } from '@/components';
import classNames from 'classnames';

const ListAndMediaModule = ({ data }) => {
  return (
    <ModuleBase data={data} className="relative lg:px-[108px]">
      <div
        className={classNames(
          'main-data container flex flex-col lg:justify-between',
          data.mediaAlignment === 'Left' ? 'lg:flex-row-reverse' : 'lg:flex-row',
        )}
      >
        <div
          className={classNames(
            'relative mb-14 aspect-3/4 w-full max-w-[270px] md:mb-10 md:max-w-[419px] lg:my-auto lg:max-w-[496px]',
            data.mediaAlignment === 'Left' ? 'self-start' : 'self-end',
          )}
        >
          <Media media={data.media} widths={{ xs: 213, sm: 213, md: 332, lg: 392, xl: 392, xxl: 392 }} />
        </div>

        <div
          className={classNames(
            'stats-wrapper color-from-bg flex flex-col lg:my-auto lg:w-[47%] lg:max-w-[496px]',
            data.mediaAlignment === 'Left' ? 'md:items-start md:pl-[104px]' : 'md:items-center',
          )}
        >
          {data.statElements.map((statItem, statIndex) => (
            <div key={statIndex} className="stats mb-10 last:mb-0 md:w-[30%] lg:w-full">
              <p className="font-montserrat text-large-paragraph font-normal leading-xl-paragraph lg:text-paragraph lg:leading-paragraph">
                {statItem.statisticsLabel}
              </p>

              <p className="text-h4 color-from-bg font-montserrat">{statItem.statistics}</p>
            </div>
          ))}
        </div>
      </div>
    </ModuleBase>
  );
};

export default ListAndMediaModule;
