import { ModuleBase, EntityCard, Carousel, Stack, Link, Text, HeadingTag } from '@/components';
import Arrow from '@/assets/carouselArrow.svg';
import { useDimensions } from '@/hooks';
import classNames from 'classnames';
import { fixColor, isColorDark } from '@/utils';
import React, { useEffect, useState } from 'react';
import { isIOS, isMacOs, isSafari } from 'react-device-detect';
import AnimationStarter from '@/animations/animationComponent/AnimationStarter';

const CardCarouselOutOfScreenModule = ({ data }) => {
  const { cardRow } = data || {};
  const { breakpoint } = useDimensions();
  const isDark = isColorDark(fixColor(data.backgroundColour));
  const [paginationClass, setPaginationClass] = useState();

  useEffect(() => {
    if (isMacOs || isIOS || isSafari) {
      setPaginationClass('!w-screen landscape:!w-screen');
    } else {
      setPaginationClass('!w-[calc(100vw-17px)]');
    }
  }, []);

  const getItems = (items) => {
    return items.map((item, index) => {
      return <EntityCard data={item} key={index} className="w-full [&_picture]:pointer-events-none" />;
    });
  };

  return (
    <ModuleBase data={data} className="">
      {(data.headingTitle.heading.length > 0 || data.description || data.primaryCta || data.secondaryCta) && (
        <Stack className="container mb-10 lg:flex lg:items-end lg:justify-between">
          {(data.headingTitle || data.description) && (
            <Stack>
              {data.headingTitle && (
                <HeadingTag data={data.headingTitle} className="color-from-bg title-04 font-kepler lg:max-w-[456px]" />
              )}
              {data.description && (
                <Text
                  dangerouslySetInnerHTML={{ __html: data.description }}
                  className="color-from-bg body-03 mb-05 mt-4 !text-opacity-75 lg:mb-0 lg:max-w-[456px]"
                />
              )}
            </Stack>
          )}
          {(data.primaryCta || data.secondaryCta) && (
            <Stack className="flex flex-col gap-02 md:flex-row">
              <Link animate className={classNames('btn primary', isDark ? 'dark' : 'light')} link={data.primaryCta} />
              <Link
                animate
                className={classNames('btn secondary', isDark ? 'dark' : 'light')}
                link={data.secondaryCta}
              />
            </Stack>
          )}
        </Stack>
      )}
      <AnimationStarter>
        {cardRow.map(({ props: row }, rIndex) => (
          <div key={rIndex} className="relative overflow-hidden px-4 md:px-6 lg:px-10">
            <Carousel
              items={getItems(row.cards)}
              gap={8}
              animationStyle="elegant"
              loop={['lg', 'xl', 'xxl'].includes(breakpoint)}
              width={{ base: 343, md: 348, lg: 472 }}
              controls={{
                show: true,
                directionComponent: <Arrow />,
                controlStyle: {
                  prev: 'pagination charcoal left pointer-events-auto rtl:[&_svg]:rotate-180',
                  next: 'pagination charcoal right pointer-events-auto [&_svg]:rotate-180 rtl:[&_svg]:rotate-0',
                },
                wrapperStyle: `pointer-events-none hidden  p-0 left-0 ${paginationClass} lg:flex justify-between absolute top-1/2 -translate-y-1/2`,
              }}
            />
          </div>
        ))}
      </AnimationStarter>
    </ModuleBase>
  );
};
export default CardCarouselOutOfScreenModule;
