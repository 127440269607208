import AccordionModule from './AccordionModule.jsx';
import AnchorBarModule from './AnchorBarModule.jsx';
import ArticleImageContainer from './ArticleImageContainer.jsx';
import AvailabilityBarModel from './AvailabilityBarModel.jsx';
import BannerAndMediaModule from './BannerAndMediaModule.jsx';
import BannerFullBleedModule from './BannerFullBleedModule.jsx';
import BannerSlimModule from './BannerSlimModule.jsx';
import BreadcrumbsModel from './BreadcrumbsModel.jsx';
import CardCarouselFocusModule from './CardCarouselFocusModule.jsx';
import CardCarouselGenericModule from './CardCarouselGenericModule.jsx';
import CardCarouselOutOfScreenModule from './CardCarouselOutOfScreenModule.jsx';
import CardListingGridModule from './CardListingGridModule.jsx';
import ListItemsModule from './ListItemsModule.jsx';
import ContactsModule from './ContactsModule.jsx';
import CTabreakModule from './CTabreakModule.jsx';
import CTabreakWithLogoModule from './CTabreakWithLogoModule.jsx';
import EmbedModule from './EmbedModule.jsx';
import HotelFooter from './HotelFooter.jsx';
import FooterVertical from './FooterVertical.jsx';
import FormModule from './FormModule.jsx';
import Header from './Header.jsx';
import HeadingSideModule from './HeadingSideModule.jsx';
import HeadingVerticalModule from './HeadingVerticalModule.jsx';
import HeroArticleModuleModel from './HeroArticleModuleModel.jsx';
import HeroArticle from './HeroArticle.jsx';
import HeroCarouselCentreAlignedModule from './HeroCarouselCentreAlignedModule.jsx';
import HeroCarouselLeftAlignedModule from './HeroCarouselLeftAlignedModule.jsx';
import HeroFullBleedModule from './HeroFullBleedModule.jsx';
import HeroLeftAlignedModule from './HeroLeftAlignedModule.jsx';
import HeroMediax1 from './HeroMediax1.jsx';
import HeroSlimCenterAlignedModule from './HeroSlimCenterAlignedModule.jsx';
import HeroSlimLeftAlignedModule from './HeroSlimLeftAlignedModule.jsx';
import HeroWithTwoMediaModule from './HeroWithTwoMediaModule.jsx';
import IconListModule from './IconListModule.jsx';
import IconListWithMediaModule from './IconListWithMediaModule.jsx';
import IconListWithRichTextModule from './IconListWithRichTextModule.jsx';
import ListAndMediaModule from './ListAndMediaModule.jsx';
import ListColumnsModule from './ListColumnsModule.jsx';
import ListRichTextModule from './ListRichTextModule.jsx';
import ListTwoMediaModule from './ListTwoMediaModule.jsx';
import LogoListModule from './LogoListModule.jsx';
import MapFullBleedModule from './MapFullBleedModule.jsx';
import MapSimpleModule from './MapSimpleModule.jsx';
import MediaCarouselFullBleedModule from './MediaCarouselFullBleedModule.jsx';
import MediaCarouselModule from './MediaCarouselModule.jsx';
import MediaColumnsLandscapeModule from './MediaColumnsLandscapeModule.jsx';
import MediaColumnsPortraitModule from './MediaColumnsPortraitModule.jsx';
import MediaAssetsLibraryModule from './MediaAssetsLibraryModule/MediaAssetsLibraryModule.jsx';
import MediaMasonryGridModule from './MediaMasonryGridModule.jsx';
import NewsletterModule from './NewsletterModule.jsx';
import NotificationBar from './NotificationBar.jsx';
import NoHero from './NoHero.jsx';
import OverlayGenericModule from './OverlayGenericModule.jsx';
import QuoteCarouselModule from './QuoteCarouselModule.jsx';
import QuoteModule from './QuoteModule.jsx';
import QuoteWithMediaModule from './QuoteWithMediaModule.jsx';
import RichTextAndMediaOnFullBleedModule from './RichTextAndMediaOnFullBleedModule.jsx';
import RichTextAndOneMediaModule from './RichTextAndOneMediaModule.jsx';
import RichTextAndTwoPortraitStackedModule from './RichTextAndTwoPortraitStackedModule.jsx';
import RichTextColumnsModule from './RichTextColumnsModule.jsx';
import RichTextModule from './RichTextModule.jsx';
import RichTextWithMediaModule from './RichTextWithMediaModule.jsx';
import StatsLeftAlignedModule from './StatsLeftAlignedModule.jsx';
import StatsCenterAlignedModule from './StatsCenterAlignedModule.jsx';

import TabsModule from './TabsModule.jsx';
import TwoPortraitMediaAndRte from './TwoPortraitMediaAndRte.jsx';
import TwoLandscapeMediaAndRte from './TwoLandscapeMediaAndRte.jsx';

const Modules = {
  AccordionModule,
  AnchorBarModule,
  ArticleImageContainer,
  AvailabilityBarModel,
  BannerAndMediaModule,
  BannerFullBleedModule,
  BannerSlimModule,
  MediaAssetsLibraryModule,
  BreadcrumbsModel,
  CardCarouselFocusModule,
  CardCarouselGenericModule,
  CardCarouselOutOfScreenModule,
  CardListingGridModule,
  ListItemsModule,
  ContactsModule,
  CTabreakModule,
  CTabreakWithLogoModule,
  EmbedModule,
  HotelFooter,
  FooterVertical,
  FormModule,
  Header,
  HeadingSideModule,
  HeadingVerticalModule,
  HeroArticleModuleModel,
  HeroArticle,
  HeroCarouselCentreAlignedModule,
  HeroCarouselLeftAlignedModule,
  HeroFullBleedModule,
  HeroLeftAlignedModule,
  HeroSlimCenterAlignedModule,
  HeroSlimLeftAlignedModule,
  HeroWithTwoMediaModule,
  HeroMediax1,
  IconListModule,
  IconListWithMediaModule,
  IconListWithRichTextModule,
  ListAndMediaModule,
  ListColumnsModule,
  ListRichTextModule,
  ListTwoMediaModule,
  LogoListModule,
  MapFullBleedModule,
  MapSimpleModule,
  MediaCarouselFullBleedModule,
  MediaCarouselModule,
  MediaColumnsLandscapeModule,
  MediaColumnsPortraitModule,
  MediaMasonryGridModule,
  NewsletterModule,
  NotificationBar,
  NoHero,
  OverlayGenericModule,
  QuoteCarouselModule,
  QuoteModule,
  QuoteWithMediaModule,
  RichTextAndMediaOnFullBleedModule,
  RichTextAndOneMediaModule,
  RichTextAndTwoPortraitStackedModule,
  RichTextColumnsModule,
  RichTextModule,
  RichTextWithMediaModule,
  StatsLeftAlignedModule,
  StatsCenterAlignedModule,
  TabsModule,
  TwoPortraitMediaAndRte,
  TwoLandscapeMediaAndRte,
};

export default Modules;
