import React, { useRef, useEffect } from 'react';
import { ModuleBase, ResponsiveImage, Media, Stack, Text, Link, HeadingTag } from '@/components';
import Flicking, { FlickingError, ViewportSlot } from '@egjs/react-flicking';
import { Pagination, AutoPlay } from '@egjs/flicking-plugins';
import '@egjs/flicking-plugins/dist/pagination.css';
import '@egjs/react-flicking/dist/flicking.css';
import Arrow from '@/assets/arrow.svg';

const HeroCarouselCentreAlignedModule = ({ data }) => {
  const carousel = useRef();

  useEffect(() => {
    carousel.current?.on('ready', (e) => {
      e.currentTarget.addPlugins(
        new Pagination({
          type: 'bullet',
          renderBullet: (className) => `<span class="${className}" role="button" aria-label="Go to slide"></span>`,
        }),
      );
      if (data?.autoPlay) {
        e.currentTarget.addPlugins(new AutoPlay({ duration: 2000, direction: 'NEXT', stopOnHover: false }));
      }
    });
  }, [data?.autoPlay]);

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };

  return (
    <ModuleBase data={data} className="relative h-screen">
      {data.video.length === 1 ? (
        <div className="absolute left-0 right-0 top-0 h-full w-full">
          <Media
            media={data.video[0]}
            widths={{ xs: 425, sm: 640, md: 768, lg: 1024, xl: 1280, xxl: 2560 }}
            className="overlay"
            cover
            dataNotLazy
          />
        </div>
      ) : (
        <>
          <Flicking ref={carousel} align="center" useResizeObserver={true} horizontal={true} circular={true}>
            {data.mediaItems?.map((slide, index) => (
              <div key={index} className="h-screen w-full">
                <ResponsiveImage
                  image={slide}
                  widths={{ xs: 1024, sm: 1024, md: 1024, lg: 1024, xl: 1280, xxl: 1536 }}
                  className="overlay relative"
                />
              </div>
            ))}
            <ViewportSlot>
              <div className="flicking-pagination relative !z-40 pb-14 md:pb-9 lg:pb-12 [&_.flicking-pagination-bullet.flicking-pagination-bullet-active]:bg-white [&_.flicking-pagination-bullet]:bg-grey4"></div>
            </ViewportSlot>
          </Flicking>

          <div className="absolute top-0 z-30 flex h-full w-full items-center justify-center">
            <div className="container relative flex h-full flex-col items-center justify-center text-center md:w-4/5 lg:w-3/5">
              <Stack>
                <div className="title-wrapper" role="contentinfo" aria-label="contentinfo">
                  {data.preHeading && (
                    <Text className="text-subheading my-4 font-montserrat text-base font-semibold uppercase text-white">
                      {data.preHeading}
                    </Text>
                  )}
                  {data.headingTitle && (
                    <HeadingTag
                      animate
                      data={data.headingTitle}
                      className="color-from-bg mt-7 justify-center font-montserrat text-5xl font-semibold not-italic leading-heading1m tracking-heading1m !text-white lg:text-7xl lg:leading-heading1 lg:tracking-heading1"
                    />
                  )}
                  {data.subHeading && (
                    <Text className="text-subheading my-4 font-montserrat text-base font-semibold text-white">
                      {data.subHeading}
                    </Text>
                  )}
                </div>

                {(data.description || data.primaryCTA || data.secondaryCTA) && (
                  <div className="color-from-bg mb-4 mt-5 text-heading6 font-light not-italic leading-8 tracking-heading6">
                    <Text dangerouslySetInnerHTML={{ __html: data.description }} className="text-white" />

                    {(data.primaryCTA || data.secondaryCTA) && (
                      <div className="mt-15 flex justify-center gap-4 sm:mt-16 lg:mt-20">
                        <Link animate className="btn primary" link={data.primaryCTA} />

                        <Link animate className="btn secondary" link={data.secondaryCTA} />
                      </div>
                    )}
                  </div>
                )}
              </Stack>
            </div>
          </div>
          <div
            className={`absolute bottom-0 left-0 right-0 !z-40 m-auto flex w-[85%] items-end justify-between pb-[53px] sm:w-[calc(70%+9rem)] sm:items-center sm:pb-0 lg:bottom-[unset] lg:top-1/2 lg:w-[calc(75%+9rem)] lg:-translate-y-1/2 xxl:w-[calc(80%+6rem)]`}
          >
            <button
              className="btn text circle z-30 h-10 w-10 border p-0 sm:h-12 sm:w-12"
              onClick={move}
              aria-label="Prevues"
            >
              <Arrow className="rotate-180" />
            </button>

            <button
              className="btn text circle z-30 h-10 w-10 border p-0 sm:h-12 sm:w-12"
              onClick={() => move(1)}
              aria-label="Next"
            >
              <Arrow />
            </button>
          </div>
        </>
      )}
    </ModuleBase>
  );
};
export default HeroCarouselCentreAlignedModule;
