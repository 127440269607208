import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Text from '../animation/Text';

const HeadingTag = forwardRef(({ data, animate = false, motion, children, ...props }, ref) => {
  const { htag, heading } = data || {};
  let polishedHeading = heading?.replace(/<(p|h[0-6])[^>]*>/g, '').replace(/<\/(p|h[0-6])>/g, '');
  const content = heading ? { dangerouslySetInnerHTML: { __html: polishedHeading } } : children;

  let accessibilityLevel = '7';
  if (htag && parseInt(htag[1]) > 0) {
    accessibilityLevel = htag[1];
  }

  return (
    <Text
      animate={animate || !!motion}
      motion={motion}
      tag={htag?.trim().toLowerCase() || 'div'}
      role="heading"
      aria-level={accessibilityLevel}
      {...props}
      {...content}
      ref={ref}
    />
  );
});

HeadingTag.displayName = 'HeadingTag';

HeadingTag.propTypes = {
  data: PropTypes.exact({
    htag: PropTypes.string,
    heading: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
};

export default HeadingTag;
