import classNames from 'classnames';
import NavItem from './NavItem';
import { Link, LanguageSelect } from '@/components';

const NavColumn = ({
  navItems,
  level,
  activeMenu,
  setActiveMenu,
  close,
  cta,
  ctaClass,
  parentMenuName,
  showLanguageSwitcher,
  metadata,
  setMenuData,
}) => {
  const lKey = `l${level}`;
  const _setActiveMenu = (index, subMenu) => {
    let newValue = {};
    newValue[`m${index}`] = subMenu;
    setActiveMenu(newValue);
  };

  const navItemSpecialClassesLevel0 = (index) => {
    if (index === 1 && level === 0) {
      return 'mb-[46px]';
    } else if (index === 2 && level === 0) {
      return 'mb-8';
    } else if (index === 3 && level === 0) {
      return 'mb-8';
    } else if (index === 4 && level === 0) {
      return 'pb-[35px] border-b border-charcoal/25';
    } else if (index === 5 && level === 0) {
      return 'mt-5 mb-3';
    } else if (index > 5 && level === 0) {
      return 'mb-3';
    }
  };

  return (
    <div
      className={classNames(
        'pointer-events-none absolute top-0 h-full w-full origin-right overflow-hidden transition-all duration-700 ease-out',
        !(activeMenu && activeMenu[lKey])
          ? 'translate-x-full opacity-0 lg:-translate-x-full rtl:-translate-x-full rtl:lg:translate-x-full'
          : 'opacity-100',
        level ? 'ltr:left-0 ltr:lg:left-1/4 rtl:right-0 rtl:lg:right-1/4' : 'ltr:left-0 rtl:right-0',
        {
          'z-0': level === 0,
          'z-10 lg:-z-10': level === 1,
        },
      )}
    >
      <div className="pointer-events-none relative h-full w-full">
        <div
          className={classNames(
            'pointer-events-auto h-full bg-silk pb-6 pt-10 lg:w-1/4 lg:pb-0 lg:pt-[52px] ltr:pl-10 rtl:pr-10',
          )}
        >
          <div
            className={classNames(
              'flex h-full max-h-full flex-col overflow-y-auto',
              !parentMenuName && '',
              level !== 0 && 'pb-20 lg:pb-0',
            )}
          >
            {navItems?.map((nav, index) => (
              <NavItem
                navItem={nav}
                key={index}
                level={level}
                activeMenu={activeMenu ? (activeMenu[lKey] ? activeMenu[lKey][`m${index}`] : {}) : {}}
                setActiveMenu={(subMenu) => _setActiveMenu(index, subMenu)}
                close={close}
                parentMenuName={parentMenuName}
                setMenuData={setMenuData}
                className={classNames(
                  navItemSpecialClassesLevel0(index + 1),
                  index + 1,
                  level === 0 ? 'w-[244px]' : 'w-[unset]',
                  'mb-3 text-charcoal',
                )}
              />
            ))}

            {level === 0 && showLanguageSwitcher && (
              <div className="w-max lg:hidden">
                <LanguageSelect
                  metadata={metadata}
                  className="[&_.main-button]:px-0 [&_.main-button_svg]:fill-black"
                  onChange={close}
                />
              </div>
            )}

            {cta && (
              <Link
                className={classNames(ctaClass, 'interactive-01 mt-3 lg:text-charcoal')}
                link={cta}
                onClick={close}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavColumn;
