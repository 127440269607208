import React, { useState } from 'react';
import { FormControl, Link } from '@/components';
import { isColorDark } from '@/utils';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { AnimatePresence, motion } from 'framer-motion';

const MediaAssetsLibraryLoggedInModule = ({ data }) => {
  const isDark = isColorDark(data.backgroundColour);
  const { t } = useTranslation('common');
  const [selectedHotel, setSelectedHotel] = useState('');
  const propertyAssets = data.assets.find((item) => item.title !== 'Brand Assets');

  const filteredAssets =
    selectedHotel && selectedHotel !== t('assetLibrary.$allProperty')
      ? propertyAssets?.assets.filter((asset) => asset.name === selectedHotel)
      : propertyAssets?.assets;

  const handleHotelChange = (event) => {
    setSelectedHotel(event.target.value);
  };

  return (
    <>
      {data.assets.map((assets, assetsIndex) => (
        <motion.div layout key={assetsIndex}>
          {assets.title === 'Brand Assets' ? (
            <div className="asset-group mb-[60px] text-left last:mb-0 md:mb-16">
              <p className="title-04 mx-4 mb-4 md:mx-6 md:mb-7 lg:mx-10 lg:mb-12">{assets.title}</p>

              <div className="mb-5 hidden justify-between px-4 md:flex md:px-6 lg:px-10">
                <div className="color-from-bg body-03 font-semibold">{t('assetLibrary.$name')}</div>
                <div className="color-from-bg body-03 font-semibold md:mr-[39px] lg:mr-[136px] lg:w-[145px]">
                  {t('assetLibrary.$size')}
                </div>
              </div>

              {assets.assets.map((assetsItems, assetsItemIndex) => (
                <div
                  key={assetsItemIndex}
                  className={classNames(
                    'flex flex-col justify-between border-y px-4 py-4 md:flex-row md:items-center md:px-6 md:py-5 lg:px-10 lg:py-6',
                    isDark ? 'border-y-silk/25' : 'border-y-charcoal/25',
                    assetsItemIndex === 0 ? 'border-b-0' : 'border-b',
                  )}
                >
                  <div className="asset-name flex justify-between">
                    <div className="name body-02 mb-3 font-semibold md:mb-0">{assetsItems?.asset?.name}</div>
                    <div className="size body-02 md:hidden">{assetsItems?.asset?.size}</div>
                  </div>

                  <div className="detail-wrapper flex flex-col md:flex-row md:items-center md:gap-x-10">
                    <div className="size body-02 hidden md:flex lg:min-w-[145px]">{assetsItems?.asset?.size}</div>
                    {assetsItems?.asset?.url && (
                      <div className="view md:flex">
                        <Link
                          link={{ href: assetsItems?.asset?.url, text: t('general.$view') }}
                          className={classNames(isDark ? 'dark' : 'light', 'btn secondary')}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="asset-group mt-[60px] text-left">
              <p className="title-04 mx-4 mb-4 md:mx-6 md:mb-8 lg:mx-10 lg:mb-[52px]">{assets.title}</p>

              <div className="w-[308px] px-4 md:pl-6 lg:pl-10">
                <FormControl
                  aria-label={t('assetLibrary.$allProperty')}
                  type="select"
                  required
                  name="assets"
                  value={selectedHotel || t('assetLibrary.$allProperty')}
                  options={[
                    { label: t('assetLibrary.$allProperty'), value: t('assetLibrary.$allProperty') },
                    ...propertyAssets.assets.map((item) => ({
                      label: item?.name,
                      value: item?.name,
                    })),
                  ]}
                  onChange={handleHotelChange}
                  className={classNames(
                    '[&_.content]:text-charcoal [&_.main-button]:!bg-sandstone/0 [&_.pop-over]:z-10 [&_.popover-title]:!font-medium [&_li]:font-medium',
                    isDark
                      ? '[&_.main-button]:!border-silk/50 [&_.popover-title]:!text-silk'
                      : '[&_.main-button]:!border-charcoal/50 [&_.popover-title]:!text-charcoal',
                  )}
                />
              </div>

              <div className="body-03 color-from-bg mx-4 mb-5 mt-9 font-semibold opacity-75 md:mx-6 md:mb-8 lg:mx-10">
                {t('general.$showingResults', {
                  showing: filteredAssets?.length,
                  results: propertyAssets?.assets.length,
                })}
              </div>

              <div className="mb-5 hidden justify-between px-4 md:flex md:px-6 lg:px-10">
                <div className="color-from-bg body-03 font-semibold">{t('assetLibrary.$name')}</div>
                <div className="color-from-bg body-03 font-semibold">{t('assetLibrary.$propertyName')}</div>
                <div className="color-from-bg body-03 font-semibold md:mr-[39px] lg:mr-[136px] lg:w-[145px]">
                  {t('assetLibrary.$size')}
                </div>
              </div>

              <AnimatePresence>
                {filteredAssets?.map((assetsItems, assetsItemIndex) => (
                  <motion.div
                    key={assetsItemIndex}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ type: 'tween', duration: 0.8 }}
                    className={classNames(
                      'flex flex-col justify-between border-y px-4 py-4 md:flex-row md:items-center md:px-6 md:py-5 lg:px-10 lg:py-6',
                      isDark ? 'border-y-silk/25' : 'border-y-charcoal/25',
                      assetsItemIndex === 0 ? 'border-b' : 'border-t-0',
                    )}
                  >
                    <div className="asset-name flex justify-between">
                      <div className="name body-02 mb-3 font-semibold md:mb-0">{assetsItems?.asset?.name}</div>
                      <div className="size body-02 md:hidden">{assetsItems?.asset?.size}</div>
                    </div>

                    <p className="body-02 mb-3 hidden md:mx-auto md:mb-0 md:flex">{assetsItems?.name}</p>

                    <div className="detail-wrapper flex flex-col md:flex-row md:items-center md:gap-x-10">
                      <p className="body-02 mb-3 md:mx-auto md:mb-0 md:hidden">{assetsItems?.name}</p>
                      <div className="size body-02 hidden md:flex lg:min-w-[145px]">{assetsItems?.asset?.size}</div>

                      {assetsItems?.asset?.url && (
                        <div className="view md:flex">
                          <Link
                            link={{ href: assetsItems?.asset?.url, text: t('general.$view') }}
                            className={classNames(isDark ? 'dark' : 'light', 'btn secondary')}
                          />
                        </div>
                      )}
                    </div>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>
          )}
        </motion.div>
      ))}
    </>
  );
};

export default MediaAssetsLibraryLoggedInModule;
