import { Link, MediaLightboxCarousel, ModuleBase, ResponsiveImage, Stack, Box } from '@/components';
import { isColorDark } from '@/utils';
import classNames from 'classnames';
import { Fragment, useState } from 'react';
import { animControllerInView } from '@/animations';

const MediaMasonryGridModule = ({ data }) => {
  const { primaryCta, secondaryCta, mediaSection } = data || {};
  const [activeImage, setActiveImage] = useState(null);
  const images = [];
  mediaSection.map((row) => row.images.map((image) => images.push(image)));

  return (
    <>
      {activeImage !== null && (
        <MediaLightboxCarousel
          moduleId={data.moduleId}
          slides={images}
          activeImage={activeImage}
          onClose={() => setActiveImage(null)}
        />
      )}

      <ModuleBase
        data={data}
        className={classNames(
          'relative',
          { '!pt-0': data.paddingTop === 'Small' },
          { '!pb-2': data.paddingBottom === 'Small' },
        )}
      >
        {mediaSection && (
          <Stack motion={animControllerInView({ amount: 0.3 })}>
            <div className="container grid grid-cols-4 gap-01 md:gap-02 lg:grid-cols-12">
              {mediaSection.map(({ images: rowImages }, index) => {
                return (
                  <Fragment key={`row-${index}`}>
                    <Box
                      className={classNames(
                        'media-row-item col-span-4 aspect-3/2 w-full cursor-pointer md:col-span-2 lg:col-span-6 lg:size-auto',
                      )}
                      onClick={() => setActiveImage(images.indexOf(rowImages[0]))}
                    >
                      <ResponsiveImage
                        image={rowImages[0]}
                        widths={{ xs: 343, md: 690, lg: 701 }}
                        heights={{ xs: 237, md: 488, lg: 472 }}
                        className="size-full object-cover"
                      />
                    </Box>

                    <Box
                      className={classNames(
                        'media-row-item col-span-2 flex aspect-8/10 h-full cursor-pointer md:col-span-1 md:col-end-4 md:aspect-auto lg:col-span-3 lg:size-full',
                      )}
                      onClick={() => setActiveImage(images.indexOf(rowImages[1]))}
                    >
                      <ResponsiveImage
                        image={rowImages[1]}
                        widths={{ xs: 169, md: 358, lg: 357 }}
                        heights={{ xs: 200, md: 488, lg: 472 }}
                        className="size-full object-cover"
                      />
                    </Box>

                    <Box
                      className={classNames(
                        'media-row-item col-span-2 aspect-8/10 h-full cursor-pointer md:col-span-1 md:col-start-4 md:aspect-auto lg:col-span-3 lg:size-full',
                      )}
                      onClick={() => setActiveImage(images.indexOf(rowImages[2]))}
                    >
                      <ResponsiveImage
                        image={rowImages[2]}
                        widths={{ xs: 169, md: 358, lg: 357 }}
                        heights={{ xs: 200, md: 488, lg: 472 }}
                        className="size-full object-cover"
                      />
                    </Box>

                    {rowImages[4] && (
                      <Box
                        className={classNames(
                          'media-row-item col-span-4 aspect-3/2 w-full cursor-pointer md:col-span-2 md:col-start-2 lg:col-span-6 lg:size-auto',
                        )}
                        onClick={() => setActiveImage(images.indexOf(rowImages[4]))}
                      >
                        <ResponsiveImage
                          image={rowImages[4]}
                          widths={{ xs: 343, md: 690, lg: 701 }}
                          heights={{ xs: 237, md: 488, lg: 472 }}
                          className="size-full object-cover"
                        />
                      </Box>
                    )}
                    {rowImages[3] && (
                      <Box
                        className={classNames(
                          'media-row-item col-span-2 aspect-8/10 h-full cursor-pointer md:col-span-1 md:col-start-1 md:row-start-2 md:aspect-auto lg:col-span-3 lg:size-full',
                        )}
                        onClick={() => setActiveImage(images.indexOf(rowImages[3]))}
                      >
                        <ResponsiveImage
                          image={rowImages[3]}
                          widths={{ xs: 169, md: 358, lg: 357 }}
                          heights={{ xs: 200, md: 488, lg: 472 }}
                          className="size-full object-cover"
                        />
                      </Box>
                    )}
                    {rowImages[5] && (
                      <Box
                        className={classNames(
                          'media-row-item col-span-2 aspect-8/10 h-full cursor-pointer md:col-span-1 md:col-start-4 md:aspect-auto lg:col-span-3 lg:size-full',
                        )}
                        onClick={() => setActiveImage(images.indexOf(rowImages[5]))}
                      >
                        <ResponsiveImage
                          image={rowImages[5]}
                          widths={{ xs: 169, md: 358, lg: 357 }}
                          heights={{ xs: 200, md: 488, lg: 472 }}
                          className="size-full object-cover"
                        />
                      </Box>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </Stack>
        )}

        <Stack>
          {(primaryCta || secondaryCta) && (
            <div className="mx-auto mt-10 flex w-fit gap-02">
              {primaryCta && (
                <Link
                  animate
                  link={primaryCta}
                  className={classNames('btn primary', isColorDark(data.backgroundColour) ? 'dark' : '')}
                />
              )}
              {secondaryCta && (
                <Link
                  animate
                  link={secondaryCta}
                  className={classNames('btn secondary', isColorDark(data.backgroundColour) ? 'dark' : '')}
                />
              )}
            </div>
          )}
        </Stack>
      </ModuleBase>
    </>
  );
};
export default MediaMasonryGridModule;
