import React from 'react';
import { ModuleBase, ResponsiveImage, Media, Stack, Text, Link, HeadingTag } from '@/components';

const HeroWithTwoMediaModule = ({ data }) => {
  return (
    <ModuleBase data={data}>
      <Stack className="container mx-auto flex min-h-screen max-w-[1224px] flex-col items-center justify-center pt-6 md:pt-0 lg:flex-row-reverse lg:gap-[10.46%]">
        <div className="image-container py-18 mx-auto mb-10 w-[327px] md:w-[333px] lg:w-[49.02%]">
          <div className="relative grid h-full w-full grid-cols-10 grid-rows-12">
            <div
              className="image-bottom aspect-3/4 w-[218px] justify-self-end lg:w-full xl:w-[392px]"
              style={{ gridArea: '1 / 4 / 9 / -1' }}
            >
              <ResponsiveImage
                animate
                image={data.backgroundMedia}
                widths={{ xs: 392, sm: 392, md: 392, lg: 392, xl: 392, xxl: 392 }}
                heights={{ xs: 523, sm: 523, md: 523, lg: 523, xl: 523, xxl: 523 }}
                className="h-full w-full object-cover"
                dataNotLazy
              />
            </div>
            <div
              className="image-top z-[1] aspect-3/4 w-[157px] justify-self-start md:w-[159px] lg:w-full xl:w-[288px]"
              style={{ gridArea: '5 / 1 / 12 / 5' }}
            >
              <Media
                animate
                media={data.foregroundMedia}
                widths={{ xs: 157, sm: 157, md: 157, lg: 288, xl: 288, xxl: 288 }}
                heights={{ xs: 210, sm: 210, md: 210, lg: 384, xl: 384, xxl: 384 }}
                className="h-full w-full object-cover"
                dataNotLazy
                cover
              />
            </div>
          </div>
        </div>
        <div className="text-container mx-auto w-[326px] md:w-[450px] lg:w-[40.52%]">
          <div className="title-wrapper" role="contentinfo" aria-label="contentinfo">
            {data.preHeading && (
              <Text className="text-subheading my-4 font-montserrat text-base font-semibold uppercase text-black">
                {data.preHeading}
              </Text>
            )}
            {data.headingTitle && (
              <HeadingTag
                animate
                data={data.headingTitle}
                className="color-from-bg mt-7 font-montserrat text-7xl font-semibold not-italic leading-heading1 tracking-heading1"
              />
            )}
            {data.subHeading && (
              <Text className="text-subheading my-4 font-montserrat text-base font-semibold text-black">
                {data.subHeading}
              </Text>
            )}
          </div>

          {(data.description || data.primaryCTA || data.secondaryCTA) && (
            <div className="color-from-bg mb-4 mt-5 text-heading6 font-light not-italic leading-8 tracking-heading6">
              <Text dangerouslySetInnerHTML={{ __html: data.description }} />

              {(data.primaryCTA || data.secondaryCTA) && (
                <div className="mt-15 flex gap-4 sm:mt-16 lg:mt-20">
                  <Link animate className="btn primary" link={data.primaryCTA} />

                  <Link animate className="btn secondary" link={data.secondaryCTA} />
                </div>
              )}
            </div>
          )}
        </div>
      </Stack>
    </ModuleBase>
  );
};

export default HeroWithTwoMediaModule;
