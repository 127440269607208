/* eslint-disable no-unused-vars */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import DropdownArrow from '@/assets/dropdown_arrow.svg';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingFocusManager,
  useId,
} from '@floating-ui/react';

// https://ariakit.org/examples/menu-framer-motion

const PopoverMenu = forwardRef(
  (
    {
      title,
      open,
      onOpen,
      onClose,
      children,
      className,
      titleClass,
      hideArrow = false,
      bgClass,
      placement = 'bottom-center',
      flipMainAxis,
      setMaxHeight,
      ...props
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState();
    const [show, setShow] = useState();

    const onOpenChange = (_open) => {
      if (_open) {
        onOpen && onOpen();
        setShow(_open);
      } else {
        onClose && onClose();
        setTimeout(() => {
          setShow(_open);
        }, 500);
      }
      setIsOpen(_open);
    };

    const {
      refs,
      floatingStyles,
      context,
      placement: computedPlacement,
    } = useFloating({
      open: isOpen,
      onOpenChange: onOpenChange,
      placement: placement,
      middleware: [
        offset({
          mainAxis: 10,
        }),
        flip({ mainAxis: flipMainAxis !== undefined ? flipMainAxis : true }),
        shift(),
      ],
      whileElementsMounted: autoUpdate,
    });

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

    useEffect(() => {
      onOpenChange(open);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useImperativeHandle(ref, () => {
      return {
        open() {
          onOpenChange(true);
        },
      };
    });

    const translate = {
      top: { translateY: 5 },
      bottom: { translateY: -5 },
    }[computedPlacement.includes('-') ? computedPlacement.split('-')[0] : computedPlacement];

    return (
      <div className={classNames('relative', isOpen && 'open', className, bgClass)} {...props}>
        <button
          type="button"
          className={classNames('main-button flex w-full items-center justify-between gap-4 px-4 py-3', bgClass)}
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          <span
            className={classNames('popover-title', titleClass)}
            dangerouslySetInnerHTML={{
              __html: title?.replace ? title.replace(/<(p)>/g, '').replace(/<\/(p)>/g, '') : title,
            }}
          />
          {hideArrow === false && (
            <span className="arrow">
              <DropdownArrow
                role="presentation"
                className={classNames(
                  'fill-charcoal/75 transition',
                  isOpen
                    ? computedPlacement === 'top'
                      ? 'rotate-180'
                      : '-rotate-180'
                    : computedPlacement === 'top'
                      ? 'rotate-0'
                      : 'rotate-0',
                )}
              />
            </span>
          )}
        </button>
        <FloatingFocusManager context={context} modal={false}>
          <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()} className="pop-over z-10 w-full">
            {show && (
              <motion.div
                className={classNames(
                  'content left-0 w-full origin-top transform rounded bg-silk shadow-input focus:outline-none',
                  setMaxHeight &&
                    'scrollbar-thumb-rounded-full max-h-[365px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-charcoal/25',
                )}
                tabIndex="-1"
                variants={{
                  closed: {
                    opacity: 0,
                    ...translate,
                  },
                  open: {
                    opacity: 1,
                    translateX: 0,
                    translateY: 0,
                    transition: {
                      duration: 0.4,
                    },
                  },
                }}
                initial="closed"
                exit="closed"
                animate={isOpen ? 'open' : 'closed'}
              >
                {children}
              </motion.div>
            )}
          </div>
        </FloatingFocusManager>
      </div>
    );
  },
);

PopoverMenu.displayName = 'Box';

PopoverMenu.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
};

export default PopoverMenu;
