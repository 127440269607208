import { ModuleBase, Text, Media, HeadingTag, Link, Stack } from '@/components';
import { splitWords } from '@/animations';

const HeroLeftAlignedModule = ({ data }) => {
  return (
    <ModuleBase data={data} className="relative h-screen overflow-hidden">
      {data.backgroundMedia && (
        <div className="absolute left-0 right-0 top-0 h-full w-full">
          <Media
            media={data.backgroundMedia}
            widths={{ xs: 425, sm: 640, md: 768, lg: 1024, xl: 1280, xxl: 2560 }}
            cover
            dataNotLazy
            className="overlay"
          />
        </div>
      )}

      <div className="container relative flex h-full px-6 py-6 md:px-3.5 lg:py-20">
        <Stack className="flex h-full flex-col justify-end lg:w-3/5">
          <div className="title-wrapper" role="contentinfo" aria-label="contentinfo">
            {data.preHeading && (
              <Text className="text-subheading my-4 font-montserrat text-base font-semibold uppercase text-white">
                {data.preHeading}
              </Text>
            )}
            {data.headingTitle && (
              <HeadingTag
                motion={splitWords}
                data={data.headingTitle}
                className="color-from-bg mt-7 font-montserrat text-5xl font-semibold not-italic leading-heading1m tracking-heading1m !text-white lg:text-7xl lg:leading-heading1 lg:tracking-heading1"
              />
            )}
            {data.subHeading && (
              <Text className="text-subheading my-4 font-montserrat text-base font-semibold text-white">
                {data.subHeading}
              </Text>
            )}
          </div>

          {(data.description || data.primaryCTA || data.secondaryCTA) && (
            <div className="color-from-bg mb-4 mt-5 text-heading6 font-light not-italic leading-8 tracking-heading6">
              <Text dangerouslySetInnerHTML={{ __html: data.description }} className="text-white" />

              {(data.primaryCTA || data.secondaryCTA) && (
                <div className="mt-15 flex justify-start gap-4 sm:mt-16 lg:mt-20">
                  <Link animate className="btn primary" link={data.primaryCTA} />

                  <Link animate className="btn secondary" link={data.secondaryCTA} />
                </div>
              )}
            </div>
          )}
        </Stack>
      </div>
    </ModuleBase>
  );
};

export default HeroLeftAlignedModule;
