import Lenis from 'lenis';
import { useEffect, useRef, useState } from 'react';
import { useLayoutEffect, getScrollbarWidth, setScrollbarPlaceholder } from '@/utils';
import tailwindConfig from '../../tailwind.config.js';

export const SmoothScroller = ({ onLoaded = () => {}, main = true, ...props }) => {
  const lenisRef = useRef(undefined);
  const rafHandleRef = useRef(null);
  const [isDesktop, setIsDesktop] = useState(false);
  const [disableScroll, setDisableScroll] = useState(false);
  const scrollbarWidth = useRef();

  useLayoutEffect(() => {
    const _setIsDesktop = () => {
      setIsDesktop(window.innerWidth >= parseInt(tailwindConfig.theme.screens.lg, 10));
    };

    _setIsDesktop();
    watchDisableScroll();

    window.addEventListener('resize', _setIsDesktop);
    return () => window.removeEventListener('resize', _setIsDesktop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (disableScroll) {
      if (lenisRef.current) {
        lenisRef.current?.stop();
      } else {
        document.documentElement.classList.add('lenis');
        document.documentElement.classList.add('lenis-stopped');
      }

      if (scrollbarWidth.current === undefined) {
        scrollbarWidth.current = getScrollbarWidth();
      }
      if (scrollbarWidth.current > 0) {
        setScrollbarPlaceholder(scrollbarWidth.current);
      }
    } else {
      if (lenisRef.current) {
        lenisRef.current?.start();
      } else {
        document.documentElement.classList.remove('lenis');
        document.documentElement.classList.remove('lenis-stopped');
      }

      if (scrollbarWidth.current > 0) {
        setScrollbarPlaceholder(null);
      }
      scrollbarWidth.current = undefined;
    }
  }, [disableScroll]);

  useEffect(() => {
    if (!isDesktop) return;

    // Initialize Lenis on the first render
    if (!lenisRef.current) {
      lenisRef.current = new Lenis(props);
      onLoaded(lenisRef.current);

      if (typeof window !== 'undefined' && main) {
        window.lenis = lenisRef.current;
      }

      const raf = (time) => {
        lenisRef.current?.raf(time);
        rafHandleRef.current = requestAnimationFrame(raf);
      };
      rafHandleRef.current = requestAnimationFrame(raf);
    }

    // Clean up on component unmount
    return () => {
      if (lenisRef.current) {
        lenisRef.current.destroy();
        lenisRef.current = undefined;
      }
      if (rafHandleRef.current) {
        cancelAnimationFrame(rafHandleRef.current);
        rafHandleRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  const watchDisableScroll = () => {
    setDisableScroll(document.getElementsByClassName('disable-scroll').length > 0);

    setTimeout(() => {
      watchDisableScroll();
    }, 200);
  };

  return null;
};

export default SmoothScroller;
