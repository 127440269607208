import { ModuleBase, ResponsiveImage, Link, Text, HeadingTag, Box } from '@/components';
import classNames from 'classnames';

const BannerAndMediaModule = ({ data }) => {
  const { headingTitle, description, preHeading, subHeading, primaryCTA, secondaryCTA } = data || {};
  return (
    <ModuleBase data={data}>
      <div className="main-data relative flex h-[895px] w-full md:h-[960px] lg:h-[650px]">
        <div
          className={classNames(
            data.mobileMediaAlignment === 'Top' ? 'mt-auto' : 'mt-0',
            'relative aspect-3/4 h-[627px] w-full md:h-[580px] lg:mt-0 lg:h-[480px]',
          )}
        >
          <ResponsiveImage
            image={data.backgroundMedia}
            widths={{ xs: 375, md: 768, lg: 1440, xl: 1900 }}
            heights={{ xs: 627, md: 580, lg: 480, xl: 480 }}
            className="overlay"
          />
        </div>

        <div className="second-data-wrapper container absolute left-1/2 top-0 z-10 h-full w-full -translate-x-1/2">
          <div
            className={classNames(
              'absolute right-[24px] aspect-3/4 md:right-[159px]',
              data.mobileMediaAlignment === 'Top' ? 'top-[48px] md:top-[109px]' : 'top-[480px] md:top-[411px]',
              data.desktopMediaAlignment === 'Left' ? 'lg:left-[138px]' : 'lg:left-auto lg:right-[138px]',
              'h-full max-h-[357px] w-full max-w-[270px] md:max-h-[440px] md:max-w-[332px] lg:top-[88px] lg:max-h-[519px] lg:max-w-[392px]',
            )}
          >
            <ResponsiveImage
              image={data.smallMedia}
              widths={{ xs: 270, md: 332, lg: 392 }}
              heights={{ xs: 357, md: 440, lg: 519 }}
            />
          </div>
          <div
            className={classNames(
              'text-wrapper absolute h-fit font-montserrat lg:w-[45%]',
              data.mobileMediaAlignment === 'Top' ? 'bottom-[39px] md:bottom-[40px]' : 'top-[36px] md:top-[43px]',
              data.desktopMediaAlignment === 'Left'
                ? 'lg:left-[630px] lg:right-[108px]'
                : 'lg:left-[108px] lg:right-[630px]',
              'lg:top-[10%]',
            )}
          >
            <div className="text-wrapper">
              <div className="title-wrapper" role="contentinfo" aria-label="contentinfo">
                {preHeading && (
                  <Text className="text-subheading my-4 font-montserrat text-base font-semibold uppercase text-white">
                    {preHeading}
                  </Text>
                )}
                {headingTitle && (
                  <HeadingTag
                    data={headingTitle}
                    className="title color-from-bg mt-7 font-montserrat text-heading2 leading-heading2 !text-white"
                  />
                )}
                {subHeading && (
                  <Text className="text-subheading my-4 font-montserrat text-base font-semibold text-white">
                    {subHeading}
                  </Text>
                )}
              </div>

              {(description || primaryCTA || secondaryCTA) && (
                <div className="color-from-bg mb-4 mt-5 text-paragraph">
                  <Text dangerouslySetInnerHTML={{ __html: description }} className="text-white" />

                  {(primaryCTA || secondaryCTA) && (
                    <Box className="mt-10 flex justify-start gap-4 sm:mt-16 lg:mt-10">
                      <Link className="btn primary" link={primaryCTA} />

                      <Link className="btn secondary" link={secondaryCTA} />
                    </Box>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModuleBase>
  );
};

export default BannerAndMediaModule;
