import ArrowUp from '@/assets/arrowUp.svg';
import { Link, ModalPortal, PopoverMenu } from '@/components';
import { selectFooterProgress, selectNavIsOpen, selectNavVisible } from '@/store';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDimensions } from '@/hooks';

const AnchorBarModule = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [activeModule, setActiveModule] = useState();
  const [popoverTitle, setPopoverTitle] = useState(null);
  const [footerNear, setFooterNear] = useState(false);
  const [footerHeight, setFooterHeight] = useState(0);
  const { t } = useTranslation('common');
  const navVisible = useSelector(selectNavVisible);
  const navIsOpen = useSelector(selectNavIsOpen);
  const footerProgress = useSelector(selectFooterProgress);
  const { breakpoint } = useDimensions();
  const isDesktop = ['lg', 'xl', 'xxl'].includes(breakpoint);

  useEffect(() => {
    const ids = Array.from(document.querySelectorAll('.page-container > .module[id]:not([id=""])'))
      .map((el) => el.id)
      .filter((id) => id);
    const firstId = ids[0];

    const footer = document.querySelector('footer.module');
    setFooterHeight(footer.clientHeight);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const fromTop = entry.intersectionRect.top;
          const fromBottom = entry.rootBounds.height - entry.intersectionRect.bottom;

          if (fromTop > fromBottom) {
            if (entry.target.id === firstId) {
              setActiveModule(null);
            }
          }

          if (fromBottom > fromTop) {
            const activeElements = ['CardListingGridModule', 'ListColumnsModule'];
            if (!activeElements.some((element) => entry.target.classList.contains(element))) {
              setActiveModule(null);
            }
          }

          if (entry.isIntersecting && entry.target.id) {
            setActiveModule(entry.target.id ? '#' + entry.target.id : null);
          }
        });
      },
      {
        rootMargin: '-20% 0px -20% 0px',
        threshold: 0.5,
      },
    );

    const footerObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setFooterNear(true);
          } else {
            setFooterNear(false);
          }
        });
      },
      {
        rootMargin: '0px 0px -35px 0px',
      },
    );

    footerObserver.observe(footer);
    document.querySelectorAll('.page-container > .module').forEach((el) => observer.observe(el));
  }, []);

  const links =
    data.anchorLinkItems &&
    data.anchorLinkItems.map(
      (element, index) =>
        element.text && (
          <div
            key={index}
            className="after:interactive-02 grid items-center after:pointer-events-none after:col-[1] after:row-[1] after:size-full after:font-medium after:opacity-0 after:content-[attr(data-label)]"
            data-label={element.text}
          >
            <Link
              className={classNames(
                'interactive-02 col-[1] row-[1] whitespace-nowrap font-normal capitalize leading-3 text-charcoal duration-200 lg:text-charcoal/50',
                {
                  '!font-medium !text-charcoal': activeModule === element.href,
                },
              )}
              link={{ href: element.href }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const elementByHash = document.querySelector(element.href);
                if (elementByHash) {
                  const currentScroll = window.scrollY;
                  const offset = currentScroll > elementByHash.offsetTop ? 110 : 0;

                  window.scrollTo({
                    top: elementByHash.offsetTop - offset,
                    behavior: 'smooth',
                  });
                  window.history.replaceState(null, null, element.href);
                }
                setOpen(false);
                setPopoverTitle(element?.text);
              }}
            >
              {element.text}
            </Link>
          </div>
        ),
    );

  return (
    <ModalPortal>
      <div
        className={classNames(
          'fixed z-20 flex h-fit w-full justify-center transition-all lg:bottom-10 lg:top-auto',
          navVisible ? 'top-[92px] md:top-24' : 'top-1 md:top-4',
          navIsOpen || (!isDesktop && footerNear) ? 'pointer-events-none opacity-0' : 'pointer-events-auto opacity-100',
          isDesktop && footerNear ? 'delay-0 duration-0' : '',
          navVisible
            ? isDesktop
              ? 'delay-0 duration-0'
              : 'delay-100 duration-700'
            : isDesktop
              ? 'delay-0 duration-0'
              : 'delay-100 duration-700',
          navIsOpen || (!isDesktop && footerNear)
            ? isDesktop
              ? 'delay-0 duration-0'
              : 'duration-300'
            : isDesktop
              ? 'delay-0 duration-0'
              : 'duration-300',
        )}
        style={{
          bottom: isDesktop && footerNear ? footerHeight * footerProgress : 40,
        }}
      >
        <div className="mx-1 h-fit w-full rounded bg-silk p-0.5 shadow-[0_11px_24px_0px_rgba(0,0,0,0.1)] sm:w-[318px] md:mx-0 lg:w-fit">
          <div className="rounded border border-charcoal/50 py-3.5 lg:px-[30px]">
            <div className="flex h-3.5 items-center lg:h-[27px]">
              <div className="hidden h-full grow gap-8 overflow-auto text-center text-grey4 lg:flex [&_.anchor-link.active]:text-black">
                {links}
              </div>
              <div className="flex grow lg:hidden">
                <PopoverMenu
                  open={open}
                  onOpen={() => setOpen(true)}
                  title={popoverTitle || t('anchor.$tableOfContents')}
                  titleClass={'text-charcoal [&_+span_svg]:fill-charcoal'}
                  className="w-full text-grey1 [&_.anchor-link.active]:font-bold [&_.anchor-link]:p-4 [&_.anchor-link]:text-left [&_.content>div]:px-3.5 [&_.content>div]:py-6 [&_.content]:py-3 [&_.main-button]:border-grey5 [&_.main-button]:sm:py-2.5"
                >
                  {links}
                  {/*
                      <div className="flex justify-between border-t border-grey5 p-4 lg:hidden">
                        <Link className="btn primary" link={data.cta} />
                        <Link className="btn text" link={{ href: '#__next' }}>
                          <ArrowUp role="presentation" className="stroke-black" />
                          <span className="normal-case">{t('anchor.$backToTop')}</span>
                        </Link>
                      </div>
                      */}
                </PopoverMenu>
              </div>
              <div className="hidden lg:flex ltr:ml-6 rtl:mr-6">
                <Link className="btn primary" link={data.cta} />
                <Link
                  className="btn p-[6.4px]"
                  link={{ href: '#' }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                    history.replaceState(null, null, ' ');
                  }}
                >
                  <ArrowUp role="presentation" className="stroke-charcoal" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};

export default AnchorBarModule;
