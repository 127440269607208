import React, { useState } from 'react';

const TextArea = ({ name, type, onChange, settings, ...props }) => {
  const [text, setText] = useState('');
  const characterCounter = (e) => {
    if (e.target.value.length <= maxChars) {
      setText(e.target.value);
    }
  };
  const maxChars = settings?.maximumLength;
  return (
    <div>
      <textarea
        id={name}
        type={type}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
          characterCounter(e);
        }}
        className="input"
        name={name}
        rows={settings?.numberOfRows || 3}
        {...props}
      />
      <div className="body-04 mt-1 flex justify-end opacity-25">
        {text.length}/{maxChars}
      </div>
    </div>
  );
};

export default TextArea;
