import { useEffect, useState } from 'react';
import { fadeIn, specificFadeIn } from '@/animations';
import { ModuleBase, Media, Link, Text, HeadingTag, Box, Stack } from '@/components';

const BannerFullBleedModule = ({ data }) => {
  const { headingTitle, description, preHeading, primaryCTA, secondaryCTA } = data || {};
  const [unShowCoverImage, setShowCoverImage] = useState(data.backgroundMedia.autoPlay);

  useEffect(() => {
    if (data.backgroundMedia.autoPlay) {
      setShowCoverImage(true);
    } else {
      setShowCoverImage(false);
    }
  }, []);

  return (
    <ModuleBase data={data}>
      <Stack className="relative h-screen overflow-hidden p-0 md:h-[400px] lg:h-[560px] xxl:h-[75vh]">
        <Media
          media={data.backgroundMedia}
          widths={{ xs: 675, md: 1536, lg: 2160, xl: 2850, xxl: 3750 }}
          heights={{ xs: 1275, md: 600, lg: 840, xl: 1604, xxl: 2109 }}
          cover
          motion={specificFadeIn}
          unShowCoverImage={unShowCoverImage}
          className="overlay"
        />

        <div className="container absolute inset-0 z-10 size-full px-2 md:px-4 lg:px-10">
          <div className="relative size-full">
            <div className="absolute bottom-2 left-0 flex max-h-[700px] w-full flex-col justify-between bg-silk px-4 pb-4 pt-6 md:bottom-4 md:h-[368px] md:w-[330px] lg:bottom-6 lg:h-[512px] xxl:bottom-1/2 xxl:h-[calc(100%-60px)] xxl:translate-y-1/2 rtl:right-0">
              <div role="contentinfo" aria-label="contentinfo">
                {preHeading && (
                  <Text motion={fadeIn} className="label-01 mb-2 font-montserrat !opacity-50 lg:mb-1">
                    {preHeading}
                  </Text>
                )}
                {headingTitle && (
                  <HeadingTag motion={fadeIn} data={headingTitle} className="title-05 mb-6 text-charcoal" />
                )}
              </div>
              {(description || primaryCTA || secondaryCTA) && (
                <div className="">
                  <Text
                    dangerouslySetInnerHTML={{ __html: description }}
                    className={'body-03 text-charcoal'}
                    motion={fadeIn}
                  />
                  {(primaryCTA || secondaryCTA) && (
                    <Box motion={fadeIn} className="mt-8 flex flex-col justify-start gap-2">
                      <Link className="btn primary" link={primaryCTA} />
                      <Link className="btn secondary" link={secondaryCTA} />
                    </Box>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Stack>
    </ModuleBase>
  );
};

export default BannerFullBleedModule;
