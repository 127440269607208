import { HeadingTag, Link, ModuleBase } from '@/components';
import classNames from 'classnames';

const RichTextColumnsModule = ({ data }) => {
  return (
    <ModuleBase data={data}>
      <div
        className={classNames(
          'main-wrapper flex flex-col py-10 md:px-10 lg:px-[108px] lg:py-20',
          data.columns.length === 2 ? 'md:flex-row' : 'md:flex-col',
          'container gap-6 md:gap-20 lg:w-full lg:flex-row lg:gap-6',
        )}
      >
        {data.columns.map((col, index) => (
          <div
            className={classNames(
              'column-wrapper flex h-full flex-col font-montserrat text-black',
              data.columns.length === 2 ? 'md:w-1/2 lg:w-1/2' : 'md:w-full lg:w-1/3',
            )}
            key={index}
          >
            <div className="heading flex flex-col gap-4">
              {col.preHeading && <p className="font-semibold text-grey3">{col.preHeading}</p>}
              {col.headingTitle && (
                <HeadingTag
                  data={col.headingTitle}
                  className={col.headingTitle.htag !== '' ? `text-${col.headingTitle.htag}` : 'text-h2'}
                />
              )}
            </div>
            <div className="content my-10 md:mb-[60px] md:mt-10">
              {col.content && (
                <div
                  dangerouslySetInnerHTML={{ __html: col.content }}
                  className="richtext-content flex flex-col gap-4 text-small-paragraph md:text-paragraph [&_ol]:m-0 [&_ol]:pl-6 [&_p]:m-0 [&_table]:mb-0 [&_table]:mt-6 [&_table]:inline-table md:[&_table]:mt-11 first:[&_tr]:font-semibold [&_ul]:m-0 [&_ul]:pl-6"
                />
              )}
            </div>
            {(col.primaryCta || col.secondaryCta) && (
              <div className="cta-wrapper flex w-fit flex-col gap-4">
                <Link className="btn primary" link={col.primaryCta} />
                <Link className="btn secondary" link={col.secondaryCta} />
              </div>
            )}
          </div>
        ))}
      </div>
    </ModuleBase>
  );
};

export default RichTextColumnsModule;
